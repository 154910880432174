import { FormErrors } from '@mantine/form';

export const scrollToError = (errors: FormErrors) => {
  const errorsArray = Object.keys(errors);

  if (errorsArray.length) {
    const firstErrorElement: HTMLElement | null = document.getElementById(errorsArray[0]);

    if (firstErrorElement) {
      firstErrorElement.scrollIntoView({ behavior: `smooth`, block: 'start' });
    }
  }
};
