import { FC, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '@/store';
import {
  fetchDelMailsTemplateAction,
  selectCurrentMailsTemplate,
  selectDelMailsTemplateModal,
  selectDelMailsTemplatesModal,
  setOpenDelMailsTemplateModal,
  setOpenDelMailsTemplatesModal,
} from '@/store/slices/mailsTemplatesPlugin/mailsTemplates';

import ModalConfirm from '@/ui/molecules/ModalConfirm/ModalConfirm';

import { MailsTemplatesContext } from '../../utils/MailsTemplatesContext';

const MailsTemplatesModals: FC = () => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();
  const delDelMailsTemplateModal = useSelector(selectDelMailsTemplateModal);
  const delDelMailsTemplatesModal = useSelector(selectDelMailsTemplatesModal);
  const currentMailsTemplate = useSelector(selectCurrentMailsTemplate);
  const reloadCallback = useContext(MailsTemplatesContext);

  const delConfirmHandler = async (id: null | string) => {
    if (id !== null) {
      setIsDeleting(true);
      await dispatch(fetchDelMailsTemplateAction(id));
      reloadCallback();
      setIsDeleting(false);
    }
  };

  const confirmHandler = () => {
    dispatch(setOpenDelMailsTemplatesModal(false));
    dispatch(setOpenDelMailsTemplateModal(false));

    if (currentMailsTemplate) delConfirmHandler(currentMailsTemplate?.id);
  };

  return (
    <ModalConfirm
      title="Вы уверены?"
      text="Восстановить данные после удаления не получится"
      opened={delDelMailsTemplateModal || delDelMailsTemplatesModal}
      onClose={() =>
        delDelMailsTemplatesModal
          ? dispatch(setOpenDelMailsTemplatesModal(false))
          : dispatch(setOpenDelMailsTemplateModal(false))
      }
      confirmHandler={confirmHandler}
      blocked={isDeleting}
    />
  );
};

export default MailsTemplatesModals;
