import isEqual from 'lodash/isEqual';

import { TFormField } from '@/types/modelsPlugin/modelsPlugin_v2';

import { getModelFiltersValues } from './getModelFiltersValues';

export const filterIsChange = (
  initialValues: TFormField[],
  filterValues: TFormField[]
): boolean => {
  const initial = getModelFiltersValues(initialValues);
  const current = getModelFiltersValues(filterValues);

  return !isEqual(initial, current);
};
