import { FC } from 'react';
import { Title } from '@mantine/core';

interface IPageHeaderTitle {
  title: string;
}

const PageHeaderTitle: FC<IPageHeaderTitle> = ({ title }) => {
  return (
    <Title fz={30} order={1} lineClamp={1}>
      {title}
    </Title>
  );
};

export default PageHeaderTitle;
