import { FC, useEffect, useState } from 'react';
import { ComboboxItem, Loader, OptionsFilter, Select, SelectProps } from '@mantine/core';
import debounce from 'lodash/debounce';

import { TFormField, TSelectData } from '@/types/modelsPlugin/modelsPlugin_v2';

import { notificationText } from '@/constants/common/notificationText';

import { fetching } from '../SearchableSelect/utils/fetching';
import { getSelectData } from '../SearchableSelect/utils/getSelectData';
import { getSelectValue } from '../SearchableSelect/utils/getSelectValue';

interface SearchableSelectV2Props extends SelectProps {
  field: TFormField;
  getSelectedData?: (data: TSelectData[]) => void;
}

const SearchableSelectV2: FC<SearchableSelectV2Props> = ({ field, getSelectedData, ...props }) => {
  const { extra } = field;
  const [searchFetching, setSearchFetching] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchData, setSearchData] = useState<TSelectData[]>([]);

  // TODO требуется для совместимости, потом нужно исправить на беке
  const transformSelectData = (data: TSelectData[]) => {
    return data.map((el) => String(el.value));
  };

  const handleSearch = debounce((value: string) => {
    if (value.length >= 3 && extra?.request) {
      setSearchFetching(true);

      fetching(extra.request, value, {}, (res) => {
        const data = getSelectData(res.items, {
          valueID: extra.identifier,
          labelID: extra.display && extra.display[0],
          codeID: extra.display && extra.display[1],
        });

        setSearchData(data);
      }).finally(() => setSearchFetching(false));
    }
  }, 500);

  const handleChange = (value: string | null, option: ComboboxItem) => {
    let selectedData = null;

    selectedData = searchData.filter((el) => el.value === value);

    if (getSelectedData && selectedData) getSelectedData(selectedData);

    if (props.onChange && value) props.onChange(value, option);
  };

  const handleClear = () => {
    setSearchValue('');
    setSearchData([]);

    if (props.onChange) props.onChange(null, { label: '', value: '' });
  };

  const getInitData = (f: TFormField, value: string) => {
    getSelectValue(f, value).then((res) => {
      let selectedData: TSelectData[] = [];

      selectedData = res.filter((el) => el.value === value);

      if (getSelectedData && selectedData) getSelectedData(selectedData);

      setSearchData(res);
    });
  };

  const optionsFilter: OptionsFilter = ({ options }) => {
    // const trimedSearch = search.toLowerCase().trim();
    // TODO убрал фильтрацию, так как возникала проблема
    // с фильтром по полю код
    return options;
    // return (options as TSelectData[]).filter(
    //   (option) =>
    //     option.label.toLowerCase().includes(trimedSearch) ||
    //     String(option.value).toLowerCase().includes(trimedSearch) ||
    //     (String(option.code) ? String(option.code).toLowerCase().indexOf(trimedSearch) : false)
    // );
  };

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue]);

  // Установка изначальных значений если они есть
  useEffect(() => {
    if (props.value && props.value.length !== 0) {
      getInitData(field, props.value);
    }
  }, []);

  return (
    <Select
      nothingFoundMessage={notificationText.SEARCH_NOTHING}
      searchable
      clearable
      allowDeselect={false}
      withCheckIcon={false}
      rightSection={searchFetching && <Loader size="sm" />}
      searchValue={searchValue}
      filter={optionsFilter}
      onSearchChange={setSearchValue}
      onClear={handleClear}
      onDropdownOpen={handleClear}
      data={[...(extra?.selectData ? transformSelectData(extra.selectData) : []), ...searchData]}
      {...props}
      onChange={handleChange}
      // TODO требуется для совместимости, потом нужно исправить на беке
      value={
        field.code === 'position' || field.code === 'taxSystemCode'
          ? String(props.value)
          : props.value
      }
    />
  );
};

export default SearchableSelectV2;
