import { FC, useState } from 'react';
import { ActionIcon, Flex, FlexProps } from '@mantine/core';
import { IconPlayerPlay } from '@tabler/icons-react';

import BaseModal from '@/ui/molecules/BaseModal/BaseModal';

interface FileInputVideoPreviewProps extends FlexProps {
  src: string;
  name?: string;
}

const FileInputVideoPreview: FC<FileInputVideoPreviewProps> = ({ src, name, ...flexProps }) => {
  const [isPlayerOpen, setIsPlayerOpen] = useState(false);

  const openPlayer = () => setIsPlayerOpen(true);
  const closePlayer = () => setIsPlayerOpen(false);

  return (
    <>
      <Flex justify="center" align="center" onClick={openPlayer} {...flexProps}>
        <ActionIcon variant="light" color="gray" size="xl" radius="xl" aria-label="Play">
          <IconPlayerPlay />
        </ActionIcon>
      </Flex>

      <BaseModal title={name} opened={isPlayerOpen} onClose={closePlayer} size="xl">
        <video playsInline width="100%" src={src} controls autoPlay />
      </BaseModal>
    </>
  );
};

export default FileInputVideoPreview;
