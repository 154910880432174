import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Flex } from '@mantine/core';

import { IFileType, IFolderType, PluginCode } from '@/types';

import { MEDIA_LIMIT, MEDIA_OFFSET } from '@/constants/common/common';
import { PLUGIN_PATHS } from '@/constants/common/plugins';
import { STATIC_PARAMS } from '@/constants/mediaPlugin/mediaPlugin';

import { isFileType } from '@/utils/isFileType/isFileType';

import { AppDispatch } from '@/store';
import {
  fetchGetFolders,
  fetchGetMedia,
  fetchGetMediaMore,
  selectBreadcrumbs,
  selectFetchingGetMedia,
  selectFolders,
  selectMedia,
  selectMediaTotal,
  selectSelectedId,
  setSelectedId,
} from '@/store/slices/mediaPlugin/media';
import { selectFetchingAddFile, setCurrentFile } from '@/store/slices/mediaPlugin/mediaFiles';
import {
  fetchGetRootFolderInfo,
  setCurrentFolder,
  setRootFolder,
} from '@/store/slices/mediaPlugin/mediaFolders';

import PageBody from '@/ui/templates/Page/components/PageBody/PageBody';
import PageHeader from '@/ui/templates/Page/components/PageHeader/PageHeader';
import PageLoader from '@/ui/templates/Page/components/PageLoader/PageLoader';
import Page from '@/ui/templates/Page/Page';

import { MediaContext } from './utils/mediaContext';

import MediaButtons from './components/MediaButtons/MediaButtons';
import MediaGroup from './components/MediaGroup/MediaGroup';
import MediaSummary from './components/MediaSummary/MediaSummary';

const MediaContainer: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { folderId } = useParams();
  const viewport = useRef<HTMLDivElement>(null);
  const [scrollPosition, onScrollPositionChange] = useState({ x: 0, y: 0 });
  const mediaFetching = useSelector(selectFetchingGetMedia);
  const filesAddFetching = useSelector(selectFetchingAddFile);
  const mediaItems = useSelector(selectMedia);
  const mediaTotal = useSelector(selectMediaTotal);
  const breadcrumbs = useSelector(selectBreadcrumbs);
  const foldersTree = useSelector(selectFolders);
  const selectedItemId = useSelector(selectSelectedId);
  const [offset, setOffset] = useState(MEDIA_OFFSET);
  const [displayFolders, setDisplayFolders] = useState<IFolderType[]>([]);
  const [displayFiles, setDisplayFiles] = useState<IFileType[]>([]);

  const rootFolder = useMemo(
    () => foldersTree?.find((folder) => folder.folderFields.id === folderId),
    [folderId, foldersTree]
  );

  const displayItems = useMemo(
    () => [...displayFolders, ...displayFiles],
    [displayFiles, displayFolders, mediaItems]
  );

  useMemo(() => {
    const newDisplayFolders =
      foldersTree?.filter(({ folderFields: { parentFolderId } }) =>
        folderId ? parentFolderId === folderId : parentFolderId === null
      ) || [];
    setDisplayFolders(newDisplayFolders);

    const newDisplayFiles = mediaItems?.filter(isFileType) || [];
    setDisplayFiles(newDisplayFiles);
  }, [foldersTree, mediaItems, folderId]);

  const getMoreMedia = () => {
    dispatch(
      fetchGetMediaMore({
        params: {
          limit: MEDIA_LIMIT,
          offset: offset + MEDIA_LIMIT,
          parentFolderId: folderId,
          ...STATIC_PARAMS,
        },
      })
    ).then(() => {
      setOffset((currentOffset) => currentOffset + MEDIA_LIMIT);
    });
  };

  const handleReload = () => {
    if (viewport.current) viewport.current.scrollTop = 0;

    dispatch(setCurrentFile(null));
    dispatch(setCurrentFolder(null));
    dispatch(setSelectedId(null));

    setDisplayFolders([]);
    setDisplayFiles([]);

    if (folderId) {
      dispatch(fetchGetRootFolderInfo({ id: folderId }));
    } else {
      dispatch(setRootFolder(null));
    }

    dispatch(fetchGetFolders());
    dispatch(
      fetchGetMedia({
        params: {
          limit: MEDIA_LIMIT,
          offset: MEDIA_OFFSET,
          parentFolderId: folderId,
          ...STATIC_PARAMS,
        },
      })
    );

    setOffset(0);
  };

  const handlerScroll = () => {
    const viewportScrollHeight = viewport?.current?.scrollHeight ?? 0;
    const viewportClientHeight = viewport?.current?.clientHeight ?? 0;
    const viewportHeight = viewportScrollHeight - viewportClientHeight;
    const scrollPositionHeight = scrollPosition.y;

    // 1. Проверяем что скролл внизу
    // 2. Проверяем что общее кол-во медиа в сторе меньше общего кол-ва
    // 3. Проверяем что запрос не идет
    if (
      viewportHeight - scrollPositionHeight < 100 &&
      mediaItems &&
      mediaItems.length < mediaTotal &&
      !mediaFetching
    ) {
      getMoreMedia();
    }
  };

  useEffect(() => {
    handlerScroll();
  }, [viewport, scrollPosition]);

  useEffect(handleReload, [folderId]);

  return (
    <Page>
      <MediaContext.Provider value={{ onReload: handleReload }}>
        <PageHeader
          title={rootFolder?.folderFields.name || 'Медиа библиотека'}
          backLink={
            Boolean(rootFolder)
              ? `${PLUGIN_PATHS[PluginCode.MEDIA]}/${rootFolder?.folderFields.parentFolderId || ''}`
              : undefined
          }
          breadcrumbs={breadcrumbs}
        />

        <PageBody>
          <Flex
            gap="sm"
            sx={{ height: 'calc(100% + 16px)', maxHeight: 'calc(100% + 16px)', overflow: 'hidden' }}
          >
            <Box miw={420} sx={{ flexGrow: 1 }}>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  border: `1px solid ${theme.colors.gray[1]}`,
                  borderRadius: 6,
                })}
              >
                <MediaButtons />

                <Box h={0} sx={{ flexGrow: 1 }}>
                  <Box h="100%" pos="relative">
                    {displayItems.length > 0 ? (
                      <MediaGroup
                        viewportRef={viewport}
                        items={displayItems}
                        onScrollPositionChange={onScrollPositionChange}
                      />
                    ) : (
                      <PageLoader
                        loading={mediaFetching || filesAddFetching}
                        text={'Файлов не найдено'}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              miw={240}
              h={'100%'}
              sx={{ whiteSpace: 'break-spaces', flexBasis: '25%', flexShrink: 0 }}
            >
              <Box
                sx={() => ({
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                })}
              >
                <Box h={0} sx={{ flexGrow: 1 }}>
                  {selectedItemId && <MediaSummary />}
                </Box>
              </Box>
            </Box>
          </Flex>
        </PageBody>
      </MediaContext.Provider>
    </Page>
  );
};

export default MediaContainer;
