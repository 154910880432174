import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Group } from '@mantine/core';

import { AppDispatch } from '@/store';
import { setAddDomainModalOpen, setModalNewMenusOpened } from '@/store/slices/menuPlugin/menus';

import PageHeader from '@/ui/templates/Page/components/PageHeader/PageHeader';

const MenusContainerHeader: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  return (
    <PageHeader
      title="Меню"
      rightButton={
        <Group>
          <Button onClick={() => dispatch(setAddDomainModalOpen(true))}>Добавить домен</Button>
          <Button onClick={() => dispatch(setModalNewMenusOpened(true))}>Новый тип</Button>
        </Group>
      }
    />
  );
};

export default MenusContainerHeader;
