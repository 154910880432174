import { FC, useEffect, useState } from 'react';
import { TextInput, TextInputProps } from '@mantine/core';

const FloatInput: FC<TextInputProps> = ({ ...props }) => {
  const patternRegExp = /^(?:[0-9]+|[+-][0-9]+)(?:|[.]|[.][0-9]+)$/gm;
  const [value, setValue] = useState('');

  const handleInput = (el: React.FormEvent<HTMLInputElement>) => {
    const currentValue = el.currentTarget.value;

    if (currentValue.match(patternRegExp) || currentValue === '') {
      setValue(currentValue);
    }
  };

  const handleBlur = (el: React.FocusEvent<HTMLInputElement, Element>) => {
    const currentValue = el.target.value;

    if (currentValue.slice(-1) === '.') {
      setValue(currentValue.slice(0, currentValue.length - 1));
    }
  };

  // Установка первоначальных значений
  useEffect(() => {
    if (props.value) {
      setValue(String(props.value));
    }
  }, []);

  return <TextInput {...props} onInput={handleInput} onBlur={handleBlur} value={value} />;
};

export default FloatInput;
