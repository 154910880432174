import { TAnyOf, TObject } from '@/types/modelsPlugin/modelsPlugin_v2';
import { IModelElementReq } from '@/types/modelsPlugin/modelsPluginApi_v2';

import api from '../instance';
import { URLS } from '../urls';

// Получение элемента модели
export const apiGetModelElement = ({ modelCode, modelElementId }: IModelElementReq<undefined>) =>
  api.get<TObject>(URLS.models.modelElement.getModelElement(modelCode, modelElementId));

// Изменение элемента модели
export const apiPutModelElement = ({ modelCode, modelElementId, data }: IModelElementReq<TAnyOf>) =>
  api.put(URLS.models.modelElement.putModelElement(modelCode, modelElementId), data);

// Удаление элемента модели
export const apiDelModelElement = ({ modelCode, modelElementId }: IModelElementReq<undefined>) =>
  api.delete(URLS.models.modelElement.delModelElement(modelCode, modelElementId));
