import { FC, useMemo, useState } from 'react';
import { AppShell, ScrollArea } from '@mantine/core';

import { PLUGIN_ICONS, PLUGIN_NAMES, PLUGIN_PATHS } from '@/constants/common/plugins';

import { usePluginNavigation } from '@/hooks/usePluginNavigation';
import { useServices } from '@/hooks/useServices';

import { renderPlugin } from './utils/renderPlugin';

import LayoutBurger from './components/LayoutBurger/LayoutBurger';
import LayoutButton from './components/LayoutButton/LayoutButton';
import LayoutFooter from './components/LayoutFooter/LayoutFooter';
import LayoutLogo from './components/LayoutLogo/LayoutLogo';
import LayoutVersion from './components/LayoutVersion/LayoutVersion';

import { withServices } from '@/hocs/withServices';

const Layout: FC = () => {
  const [collapse, setCollapse] = useState<boolean>(true);
  const { currentPlugin } = usePluginNavigation();
  const { availablePlugins } = useServices();

  const isPluginAvailable = useMemo(
    () => currentPlugin && availablePlugins.includes(currentPlugin),
    [availablePlugins, currentPlugin]
  );

  return (
    <AppShell
      navbar={{
        width: collapse ? 230 : 72,
        breakpoint: 'sm',
      }}
      padding={0}
      styles={{ main: { transition: 'padding-left 0.25s ease' } }}
    >
      <AppShell.Navbar style={{ transition: 'width 0.25s ease' }}>
        <AppShell.Section pb={24}>
          <LayoutLogo collapse={collapse} />
          <LayoutBurger collapse={collapse} onClick={() => setCollapse(!collapse)} />
        </AppShell.Section>

        <AppShell.Section grow component={ScrollArea}>
          {availablePlugins?.map((plugin) => (
            <LayoutButton
              key={plugin}
              collapse={collapse}
              path={PLUGIN_PATHS[plugin]}
              label={PLUGIN_NAMES[plugin]}
              leftSection={PLUGIN_ICONS[plugin]}
            />
          ))}
        </AppShell.Section>

        <AppShell.Section pb={10}>
          <LayoutFooter collapse={collapse} />
          <LayoutVersion />
        </AppShell.Section>
      </AppShell.Navbar>

      <AppShell.Main display={'flex'} mah={'100dvh'}>
        {currentPlugin && isPluginAvailable && renderPlugin(currentPlugin)}
      </AppShell.Main>
    </AppShell>
  );
};

export default withServices(Layout);
