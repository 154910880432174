import { KeyboardEvent } from 'react';
import { ClipboardEvent } from 'react';

import { EXTERNAL_ID } from '@/constants/common/validationSchemas';

// для запрета ввода и вставки ['e', '.', ',', '+', '-', 'E'] в числовые инпуты
export const handleKeyDown = (e: KeyboardEvent) => {
  if (EXTERNAL_ID.includes(e.key)) {
    e.preventDefault();
  }
};

export const handlePaste = (e: ClipboardEvent) => {
  e.preventDefault();
  const pastedData = e.clipboardData.getData('text');
  const filteredData = pastedData
    .split('')
    .filter((char) => !EXTERNAL_ID.includes(char))
    .join('');
  document.execCommand('insertText', false, filteredData);
};
