import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Button, Checkbox, Group, Paper, ScrollArea } from '@mantine/core';
import { DatePickerInput, DatesRangeValue } from '@mantine/dates';
import { useForm, yupResolver } from '@mantine/form';
import { IconCalendar } from '@tabler/icons-react';

import { ReviewsAnalysisRequest } from '@/types';

import { REVIEW_ANALYSIS_FORM } from '@/constants/common/validationSchemas';

import { scrollToError } from '@/utils/scrollToError';

import { AppDispatch } from '@/store';
import {
  fetchReviewsAnalysisAction,
  selectFetchingReviewsAnalysis,
} from '@/store/slices/reviewsPlugin/reviews';

const ReviewsAnalysisForm: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const fetchingReviewsAnalysis = useSelector(selectFetchingReviewsAnalysis);

  const form = useForm({
    initialValues: {
      fields: [
        'id',
        'product_external_id',
        'status',
        'rating',
        'text',
        'has_files',
        'usefulness',
        'user_name',
        'user_id',
        'response_date',
        'response',
        'moderator_id',
        'inactivation_reason',
        'editing_date',
        'created_date',
        'activation_date',
      ],
      createdDate: [null, null] as DatesRangeValue,
    },
    validate: yupResolver(REVIEW_ANALYSIS_FORM),
  });

  const submitHandler = async (values: { fields: string[]; createdDate: DatesRangeValue }) => {
    const data: ReviewsAnalysisRequest = {
      ...values,
      createdDate: {
        from: values.createdDate[0],
        to: values.createdDate[1],
      },
    };

    await dispatch(fetchReviewsAnalysisAction(data));
  };

  return (
    <form onSubmit={form.onSubmit(submitHandler, scrollToError)} noValidate>
      <Checkbox.Group
        id="fields"
        mb={24}
        label="Выберите столбцы для отчета и настройте сортировку"
        required
        {...form.getInputProps('fields')}
      >
        <Paper
          sx={(theme) => ({
            border: `1px solid ${theme.colors.gray[4]}`,
            borderColor: form.getInputProps('fields').error
              ? theme.colors.red[6]
              : theme.colors.gray[4],
          })}
        >
          <ScrollArea.Autosize p="md" h={310} offsetScrollbars>
            <Checkbox mb={15} value="id" label="ID отзыва" />
            <Checkbox mb={15} value="product_external_id" label="Артикул товара" />
            <Checkbox mb={15} value="status" label="Статус отзыва" />
            <Checkbox mb={15} value="rating" label="Оценка" />
            <Checkbox mb={15} value="text" label="Текст отзыва" />
            <Checkbox mb={15} value="has_files" label="Есть файлы" />
            <Checkbox mb={15} value="usefulness" label="Полезность" />
            <Checkbox mb={15} value="user_name" label="Имя покупателя" />
            <Checkbox mb={15} value="user_id" label="ID покупателя" />
            <Checkbox mb={15} value="response_date" label="Дата ответа" />
            <Checkbox mb={15} value="response" label="Ответ" />
            <Checkbox mb={15} value="moderator_id" label="ID модератора" />
            <Checkbox mb={15} value="inactivation_reason" label="Причина отклонения" />
            <Checkbox mb={15} value="editing_date" label="Дата изменения" />
            <Checkbox mb={15} value="created_date" label="Дата создания" />
            <Checkbox value="activation_date" label="Дата активации" />
          </ScrollArea.Autosize>
        </Paper>
      </Checkbox.Group>

      <DatePickerInput
        id="createdDate"
        mb={24}
        locale="ru"
        clearable
        type="range"
        leftSection={<IconCalendar size="1.1rem" />}
        label="Выберите период"
        placeholder="ДД.ММ.ГГ - ДД.ММ.ГГ"
        valueFormat="DD.MM.YY"
        {...form.getInputProps('createdDate')}
      />

      <Group justify="right">
        <Button type={'submit'} loading={fetchingReviewsAnalysis}>
          Скачать отчет
        </Button>
      </Group>
    </form>
  );
};

export default ReviewsAnalysisForm;
