import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Box, Flex } from '@mantine/core';
import { isEmpty } from 'lodash';
import qs from 'qs';

import { FilterViewTypeEnum } from '@/types';

import {
  REVIEW_STATUS_ITEMS,
  REVIEWS_SEARCH_PLACEHOLDER,
} from '@/constants/reviewsPlugin/reviewsPlugin';
import {
  TABLE_REVIEWS,
  TABLE_REVIEWS_SORTABLE_COLUMNS,
} from '@/constants/reviewsPlugin/tableHeaders';

import { useFilters } from '@/hooks/useFilters';
import { useItemsIsEmptyMemo } from '@/hooks/useItemsIsEmptyMemo';
import { useQueryParams } from '@/hooks/useQueryParams';
import { useURLPagination } from '@/hooks/useUrlPagination';

import { AppDispatch } from '@/store';
import {
  fetchReviewsAction,
  selectFetchingImportReviewsFromArchive,
  selectFetchingReviewsAction,
  selectReviews,
  selectReviewsTotal,
} from '@/store/slices/reviewsPlugin/reviews';

import PageBody from '@/ui/templates/Page/components/PageBody/PageBody';
import PageContainer from '@/ui/templates/Page/components/PageContainer/PageContainer';
import PageFooter from '@/ui/templates/Page/components/PageFooter/PageFooter';
import PageHeader from '@/ui/templates/Page/components/PageHeader/PageHeader';
import PagePagination from '@/ui/templates/Page/components/PagePagination/PagePagination';
import Page from '@/ui/templates/Page/Page';

import FiltersBuilder from '@/ui/organisms/FiltersBuilder/FiltersBuilder';
import { TFiltersConfig } from '@/ui/organisms/FiltersBuilder/types';
import SearchInput from '@/ui/organisms/SearchInput/SearchInput';
import ShowElements from '@/ui/organisms/ShowElements/ShowElements';
import TableExt from '@/ui/organisms/TableExt/TableExt';

import { LoaderOverlay } from '@/ui/molecules/LoaderOverlay/LoaderOverlay';

import { getDisplayReviews } from './utils/getDisplayReviews';
import { transformFiltersToURL } from './utils/transformFiltersToURL';
import { transformURLFilters } from './utils/transformURLFilters';

import ReviewsAnalysisModal from './components/ReviewsAnalysisModal/ReviewsAnalysisModal';
import ReviewsImportArchiveModal from './components/ReviewsImportArchiveModal/ReviewsImportArchiveModal';
import ReviewsImportModal from './components/ReviewsImportModal/ReviewsImportModal';
import ReviewsModeratorsModal from './components/ReviewsModeratorsModal/ReviewsModeratorsModal';
import ReviewsRightButtons from './components/ReviewsRightButtons/ReviewsRightButtons';
import ReviewsStopListModal from './components/ReviewsStopListModal/ReviewsStopListModal';
import ReviewsTableButtons from './components/ReviewsTableButtons/ReviewsTableButtons';
import ReviewsTableModals from './components/ReviewsTableModals/ReviewsTableModals';

const filtersConfig: TFiltersConfig = [
  {
    code: 'status',
    name: 'Статус',
    viewType: FilterViewTypeEnum.MULTISELECT,
    data: REVIEW_STATUS_ITEMS,
    showOptionAll: true,
    inputProps: {
      searchable: true,
    },
  },
  {
    code: 'rating',
    name: 'Оценка',
    viewType: FilterViewTypeEnum.MULTISELECT,
    data: ['1', '2', '3', '4', '5'],
    showOptionAll: true,
  },
  {
    code: 'editingDate',
    name: 'Дата изменения',
    viewType: FilterViewTypeEnum.DATE_RANGE,
    inputProps: { maxDate: new Date() },
  },
];

const ReviewsContainer: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { filters, onFiltersChange } = useFilters({
    rating: (value: string[]) => value.map((item) => Number(item)),
    editingDate: (value) => ({
      from: value[0] || undefined,
      to: value[1] || undefined,
    }),
  });

  const fetchingReviews = useSelector(selectFetchingReviewsAction);
  const fetchingImportReviewsFromArchive = useSelector(selectFetchingImportReviewsFromArchive);
  const reviews = useSelector(selectReviews);
  const reviewsTotal = useSelector(selectReviewsTotal);
  const { currentLimit, currentOffset, currentPage, pagesCount, setLimit, setPage } =
    useURLPagination(reviewsTotal);
  const { currentQuery, currentOrder, currentSort, currentFilter } = useQueryParams();

  const reviewsIsEmpty = useItemsIsEmptyMemo(reviews);

  const displayReviews = useMemo(() => getDisplayReviews(reviews), [reviews]);

  const params = {
    limit: currentLimit,
    offset: currentOffset,
    query: currentQuery,
    order: currentOrder,
    sort: currentSort,
  };

  const reloadHandler = () => {
    dispatch(
      fetchReviewsAction({
        params,
        data: { filter: filters },
      })
    );
  };

  const searchAction = (query: string) => {
    const newParams = {
      ...params,
      ...currentFilter,
      offset: 0,
      query,
    };
    const URLParams = qs.stringify(newParams, { indices: false });

    setSearchParams(URLParams);
  };

  useEffect(() => {
    reloadHandler();
  }, [searchParams]);

  useEffect(() => {
    if (!isEmpty(currentFilter)) {
      onFiltersChange(transformURLFilters(currentFilter));
    }
  }, []);

  useEffect(() => {
    const newFilters = transformFiltersToURL(filters);

    const newParams = {
      ...params,
      ...newFilters,
    };
    const URLParams = qs.stringify(newParams, { indices: false });

    setSearchParams(URLParams);
  }, [filters]);

  return (
    <Page>
      <PageHeader title="Отзывы" rightButton={<ReviewsRightButtons />} />

      <PageBody>
        <Box w="50%" mb={10}>
          <SearchInput
            searchAction={searchAction}
            placeholder={REVIEWS_SEARCH_PLACEHOLDER}
            defaultValue={currentQuery}
          />
        </Box>

        <Flex align="flex-start" justify="space-between" mb={24} gap={15}>
          <Flex align="stretch" w={'100%'}>
            <FiltersBuilder
              loading={false}
              filtersConfig={filtersConfig}
              onFiltersChange={onFiltersChange}
              initialValues={transformURLFilters(currentFilter)}
            />
          </Flex>
          <ShowElements pt={23} defaultValue={currentLimit} changeCallback={setLimit} />
        </Flex>

        <PageContainer
          isEmpty={reviewsIsEmpty}
          loading={fetchingReviews}
          loaderText={'У вас пока нет отзывов'}
        >
          <TableExt
            config={TABLE_REVIEWS}
            rows={displayReviews}
            sortableKeys={TABLE_REVIEWS_SORTABLE_COLUMNS}
            buttons={ReviewsTableButtons}
          />
        </PageContainer>

        <PageFooter>
          <PagePagination total={pagesCount} value={currentPage} onChange={setPage} />
        </PageFooter>

        <LoaderOverlay
          visible={fetchingImportReviewsFromArchive}
          zIndex={100}
          loadingText={'Это может занять несколько минут. Не покидайте эту страницу'}
          overlayProps={{ backgroundOpacity: 1 }}
        />
      </PageBody>

      <ReviewsTableModals />
      <ReviewsAnalysisModal />
      <ReviewsModeratorsModal />
      <ReviewsImportModal />
      <ReviewsStopListModal />
      <ReviewsImportArchiveModal />
    </Page>
  );
};

export default ReviewsContainer;
