import { FC } from 'react';
import { Group, Pagination, PaginationProps } from '@mantine/core';

const PagePagination: FC<PaginationProps> = ({ ...props }) => {
  return props.total > 1 ? (
    <Group justify="flex-end" gap="xs">
      <Pagination {...props} />
    </Group>
  ) : null;
};

export default PagePagination;
