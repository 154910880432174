import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import { TMailsTemplate } from '@/types';

import { createAxiosThunk } from '@/utils/asyncRequest';
import notify from '@/utils/notify';

import {
  apiDelMailsTemplate,
  apiDelMailsTemplates,
  apiGetMailTemplates,
  tryAddMailTemplate,
  tryGetMailTemplate,
  tryPutMailTemplate,
} from '@/api/mailsTemplatesPlugin/mailsTemplates';

interface IMailsTemplatesState {
  status: {
    fetchingMailsTemplates: boolean;
    fetchingAddMailsTemplates: boolean;
  };
  modals: {
    delMailsTempalateModal: boolean;
    delMailsTempalatesModal: boolean;
  };
  items: TMailsTemplate[] | null;
  total: number | null;
  currentMailsTemplate: TMailsTemplate | null;
  mailsTemplatesSelected: Record<string, string>[] | null;
}

const initialState: IMailsTemplatesState = {
  status: {
    fetchingMailsTemplates: false,
    fetchingAddMailsTemplates: false,
  },
  modals: {
    delMailsTempalateModal: false,
    delMailsTempalatesModal: false,
  },
  items: null,
  total: null,
  currentMailsTemplate: null,
  mailsTemplatesSelected: null,
};

export const fetchMailsTemplatesAction = createAxiosThunk('/mailTemplates', apiGetMailTemplates);
export const fetchDelMailsTemplateAction = createAxiosThunk(
  '/delMailsTemplate',
  apiDelMailsTemplate
);
export const fetchMultiDelMailsTemplateAction = createAxiosThunk(
  '/multiDelMailsTemplate',
  apiDelMailsTemplates
);

export const fetchMailTemplateAction = async (
  id: string
): Promise<Omit<TMailsTemplate, 'id'> | null> => {
  const result = await tryGetMailTemplate(id);
  if (result?.status === 200) {
    return omit(result.data, 'id');
  } else {
    return null;
  }
};

export const putMailTemplateAction = async (
  id: string,
  data: Partial<TMailsTemplate>
): Promise<boolean | null> => {
  const result = await tryPutMailTemplate(id, data);
  if (result?.status === 204) {
    notify({ message: 'Шаблон изменен', type: 'success' });
    return true;
  } else {
    return null;
  }
};

export const addMailTemplateAction = async (
  data: Partial<TMailsTemplate>
): Promise<boolean | null> => {
  const result = await tryAddMailTemplate(data);
  if (result?.status === 201) {
    notify({ message: 'Шаблон добавлен', type: 'success' });
    return true;
  } else {
    return null;
  }
};

export const mailsTemplatesSlice = createSlice({
  name: 'mailTemplates',
  initialState,
  reducers: {
    setOpenDelMailsTemplateModal: (state, action: PayloadAction<boolean>) => {
      state.modals.delMailsTempalateModal = action.payload;
    },
    setOpenDelMailsTemplatesModal: (state, action: PayloadAction<boolean>) => {
      state.modals.delMailsTempalatesModal = action.payload;
    },
    setMailsTemplatesSelected: (state, action: PayloadAction<Record<string, string>[] | null>) => {
      state.mailsTemplatesSelected = action.payload;
    },
    setCurrentMailsTemplate: (state, action: PayloadAction<TMailsTemplate | null>) => {
      state.currentMailsTemplate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMailsTemplatesAction.pending, (state) => {
        state.status.fetchingMailsTemplates = true;
      })
      .addCase(fetchMailsTemplatesAction.fulfilled, (state, action) => {
        state.status.fetchingMailsTemplates = false;
        state.items = action.payload.items;
        state.total = action.payload.total;
      })
      .addCase(fetchMailsTemplatesAction.rejected, (state) => {
        state.status.fetchingMailsTemplates = false;
      });
    builder
      .addCase(fetchDelMailsTemplateAction.pending, (state) => {
        state.status.fetchingMailsTemplates = true;
      })
      .addCase(fetchDelMailsTemplateAction.fulfilled, (state) => {
        state.status.fetchingMailsTemplates = false;
        state.modals.delMailsTempalateModal = false;
        state.currentMailsTemplate = null;

        notify({ message: 'Почтовый шаблон удален', type: 'success' });
      })
      .addCase(fetchDelMailsTemplateAction.rejected, (state) => {
        state.status.fetchingMailsTemplates = false;
        state.modals.delMailsTempalateModal = false;
      });
    builder
      .addCase(fetchMultiDelMailsTemplateAction.pending, (state) => {
        state.status.fetchingMailsTemplates = true;
      })
      .addCase(fetchMultiDelMailsTemplateAction.fulfilled, (state) => {
        state.status.fetchingMailsTemplates = false;
        state.modals.delMailsTempalatesModal = false;
        state.mailsTemplatesSelected = null;

        notify({ message: 'Почтовые шаблоны удалены', type: 'success' });
      })
      .addCase(fetchMultiDelMailsTemplateAction.rejected, (state) => {
        state.status.fetchingMailsTemplates = false;
        state.modals.delMailsTempalatesModal = false;
      });
  },
});

// Selectors

type TSelectorState = { mailsTemplates: IMailsTemplatesState };

// Modals
export const selectDelMailsTemplateModal = (state: TSelectorState) =>
  state.mailsTemplates.modals.delMailsTempalateModal;
export const selectDelMailsTemplatesModal = (state: TSelectorState) =>
  state.mailsTemplates.modals.delMailsTempalatesModal;
// Statuses
export const selectFetchingMailsTemplatesStatus = (state: TSelectorState) =>
  state.mailsTemplates.status.fetchingMailsTemplates;

export const selectMailsTemplatesItems = (state: TSelectorState) => state.mailsTemplates.items;
export const selectMailsTemplatesTotal = (state: TSelectorState) => state.mailsTemplates.total;
export const selectMailsTempaltesSelected = (state: TSelectorState) =>
  state.mailsTemplates.mailsTemplatesSelected;
export const selectCurrentMailsTemplate = (state: TSelectorState) =>
  state.mailsTemplates.currentMailsTemplate;

// Reducers and actions

export const {
  setOpenDelMailsTemplateModal,
  setOpenDelMailsTemplatesModal,
  setMailsTemplatesSelected,
  setCurrentMailsTemplate,
} = mailsTemplatesSlice.actions;

export default mailsTemplatesSlice.reducer;
