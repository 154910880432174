import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionIcon, Group } from '@mantine/core';
import { IconPencil } from '@tabler/icons-react';

import { AppDispatch } from '@/store';
import {
  fetchReviewsItemAction,
  selectFetchingReview,
  setCurrentReviewId,
  setOpenEditReviewsElementModal,
} from '@/store/slices/reviewsPlugin/reviews';

const ReviewsTableButtons: FC = ({ ...props }) => {
  const dispatch: AppDispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const fetching = useSelector(selectFetchingReview);

  const changeHandler = async () => {
    setLoading(true);
    const { id: elementId } = props as Record<string, string>;

    await dispatch(fetchReviewsItemAction({ id: elementId })).finally(() => setLoading(false));
    dispatch(setOpenEditReviewsElementModal(true));
    dispatch(setCurrentReviewId(elementId));
  };

  return (
    <Group
      justify={'right'}
      sx={{
        flexWrap: 'nowrap',
      }}
    >
      <ActionIcon
        variant="subtle"
        color="gray"
        loading={loading}
        disabled={fetching}
        name="Изменить"
        title="Изменить"
        onClick={changeHandler}
      >
        <IconPencil size={20} color="gray" />
      </ActionIcon>
    </Group>
  );
};

export default ReviewsTableButtons;
