import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Group, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { TConfiguration } from '@/types';

import {
  EDIT_CONFIGURATION_FORM,
  NEW_CONFIGURATION_FORM,
} from '@/constants/common/validationSchemas';

import { useQueryParams } from '@/hooks/useQueryParams';
import { useTranslitForm } from '@/hooks/useTranslitForm';
import { useURLPagination } from '@/hooks/useUrlPagination';

import { AppDispatch } from '@/store';
import {
  fetchPutConfigurationAction,
  selectFetchingPutConfiguration,
} from '@/store/slices/configurationPlugin/configuration';
import {
  fetchAddConfigurationsAction,
  fetchConfigurationsAction,
  selectConfigurationsTotal,
  selectFetchingAddConfiguration,
} from '@/store/slices/configurationPlugin/configurations';
import { selectCurrentService } from '@/store/slices/service/service';

interface ConfigurationsFormProps {
  type: 'new' | 'edit';
  element?: TConfiguration | null;
  onReload: () => void;
}

const ConfigurationsForm: FC<ConfigurationsFormProps> = ({ type, element, onReload }) => {
  const dispatch: AppDispatch = useDispatch();
  const { currentOrder, currentSort } = useQueryParams();
  const confId = element?.id;
  const configurationsTotal = useSelector(selectConfigurationsTotal);
  const { currentLimit, currentOffset } = useURLPagination(configurationsTotal || 0);
  const service = useSelector(selectCurrentService);
  const isCreateMode = type === 'new';
  const isEditMode = type === 'edit';
  const fetchingAdd = useSelector(selectFetchingAddConfiguration);
  const fetchingPut = useSelector(selectFetchingPutConfiguration);

  const form = useForm({
    validate: isEditMode
      ? yupResolver(EDIT_CONFIGURATION_FORM)
      : yupResolver(NEW_CONFIGURATION_FORM),
    initialValues: {
      name: element?.name || '',
      code: element?.code || '',
    },
  });

  const { setOriginalHandler } = useTranslitForm({
    inputNames: {
      original: 'name',
      translit: 'code',
    },
    opts: { onlyLower: true },
    form,
    active: isCreateMode,
  });

  const submitHandler = async (data: { code: string; name: string }) => {
    if (type === 'new' && service) {
      await dispatch(fetchAddConfigurationsAction({ data }));
    }

    if (confId && type === 'edit' && service) {
      await dispatch(fetchPutConfigurationAction({ id: confId, data }));
    }

    if (service) {
      await dispatch(
        fetchConfigurationsAction({
          params: {
            offset: currentOffset,
            limit: currentLimit,
            sort: currentSort,
            order: currentOrder,
          },
        })
      );
    }

    onReload();
  };

  return (
    <form onSubmit={form.onSubmit((values) => submitHandler(values))}>
      <TextInput
        placeholder="Введите название"
        label="Название"
        mb={24}
        onInput={setOriginalHandler}
        withAsterisk
        {...form.getInputProps('name')}
      />
      <TextInput
        placeholder="Введите код"
        label="Символьный код"
        mb={24}
        disabled={isEditMode}
        withAsterisk
        {...form.getInputProps('code')}
      />

      <Group justify="right">
        <Button loading={fetchingAdd || fetchingPut} type={'submit'}>
          Сохранить
        </Button>
      </Group>
    </form>
  );
};

export default ConfigurationsForm;
