import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Group } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { REVIEW_IMPORT_FORM } from '@/constants/common/validationSchemas';
import { REVIEWS_STOP_LIST_FOLDER_ID } from '@/constants/reviewsPlugin/reviewsPlugin';

import { scrollToError } from '@/utils/scrollToError';

import { AppDispatch } from '@/store';
import {
  fetchImportReviewsStopListFromFileAction,
  selectFetchingImportReviewsStopListFromFile,
} from '@/store/slices/reviewsPlugin/reviews';

import FileInput from '@/ui/organisms/FileInput/FileInput';

const ReviewsStopListForm: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const fetchingImportReviewsStopListFromFile = useSelector(
    selectFetchingImportReviewsStopListFromFile
  );

  const form = useForm({
    initialValues: {
      mediaId: '',
    },
    validate: yupResolver(REVIEW_IMPORT_FORM),
  });

  const submitHandler = async (values: { mediaId: string }) => {
    await dispatch(fetchImportReviewsStopListFromFileAction(values.mediaId));
  };

  return (
    <form onSubmit={form.onSubmit(submitHandler, scrollToError)} noValidate>
      <FileInput
        id={'reviwsStopListFormMedia'}
        mb={24}
        multiple={false}
        folderId={REVIEWS_STOP_LIST_FOLDER_ID}
        {...form.getInputProps('mediaId')}
      />

      <Group justify="right">
        <Button type={'submit'} loading={fetchingImportReviewsStopListFromFile}>
          Загрузить стоп-слова
        </Button>
      </Group>
    </form>
  );
};

export default ReviewsStopListForm;
