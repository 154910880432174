import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Menu } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import { AppDispatch } from '@/store';
import {
  setOpenReviewsAnalysisModal,
  setOpenReviewsModeratorsModal,
} from '@/store/slices/reviewsPlugin/reviews';

const ReviewsReportMenu: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const [opened, setOpened] = useState(false);

  const reviewsAnalysisHandler = () => {
    dispatch(setOpenReviewsAnalysisModal(true));
  };

  const reviewsModeratorsHandler = () => {
    dispatch(setOpenReviewsModeratorsModal(true));
  };

  const getRightIcon = () => {
    return opened ? <IconChevronUp size="1.2rem" /> : <IconChevronDown size="1.2rem" />;
  };

  return (
    <Menu opened={opened} onChange={setOpened} withinPortal={true} position="bottom-end">
      <Menu.Target>
        <Button rightSection={getRightIcon()}>Скачать отчет</Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item ta={'right'} onClick={reviewsAnalysisHandler}>
          Анализ отзывов
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item ta={'right'} onClick={reviewsModeratorsHandler}>
          Отчет по модератору
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default ReviewsReportMenu;
