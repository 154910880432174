import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Text } from '@mantine/core';

import { AppDispatch } from '@/store';
import {
  selectReviewsImportArchiveModal,
  setOpenReviewsImportArchiveModal,
} from '@/store/slices/reviewsPlugin/reviews';

import ReviewsImportArchiveForm from '../ReviewsImportArchiveForm/ReviewsImportArchiveForm';

const ReviewsImportArchiveModal: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const reviewsImportArchiveModal = useSelector(selectReviewsImportArchiveModal);

  return (
    <Modal
      centered
      size={'lg'}
      opened={reviewsImportArchiveModal}
      onClose={() => dispatch(setOpenReviewsImportArchiveModal(false))}
      title={
        <Text fw={700} fz="xl">
          Загрузка архива отзывов
        </Text>
      }
    >
      <ReviewsImportArchiveForm />
    </Modal>
  );
};

export default ReviewsImportArchiveModal;
