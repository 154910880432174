import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { UnstyledButton, UnstyledButtonProps } from '@mantine/core';

interface ReviewsModalLinkProps extends UnstyledButtonProps {
  type?: 'button' | 'link';
  to?: string;
  onClick?: () => void;
  children?: ReactNode;
}

const ReviewsModalLink: FC<ReviewsModalLinkProps> = ({ children, type = 'button', ...props }) => {
  return (
    <>
      {type === 'button' && (
        <UnstyledButton
          {...props}
          fz={14}
          sx={(theme) => ({ color: theme.colors['science-blue'][9] })}
        >
          {children}
        </UnstyledButton>
      )}

      {type === 'link' && (
        <UnstyledButton
          {...props}
          component={Link}
          to={props.to || ''}
          fz={14}
          sx={(theme) => ({ color: theme.colors['science-blue'][9] })}
        >
          {children}
        </UnstyledButton>
      )}
    </>
  );
};

export default ReviewsModalLink;
