import { FC, useEffect, useMemo, useState } from 'react';
import {
  AspectRatio,
  Box,
  Flex,
  LoadingOverlay,
  Paper,
  PaperProps,
  Text,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import isEmpty from 'lodash/isEmpty';

import { Media, MultiMedia, ResourceType, ServiceCode } from '@/types';

import { getFileInfo, getFolderInfo } from '../../utils/mediaRequests';
import FileInputFilePreview from '../FileInputFilePreview/FileInputFilePreview';
import FileInputFolderPreview from '../FileInputFolderPreview/FileInputFolderPreview';
import FileInputImagePreview from '../FileInputImagePreview/FileInputImagePreview';
import FileInputVideoPreview from '../FileInputVideoPreview/FileInputVideoPreview';

const VIDEO_EXTENSIONS = ['mov', 'webm', 'mp4', 'm4v'];

interface FileInputMediaElementProps extends PaperProps {
  resourceType: ResourceType;
  id: Media<boolean>;
  service: ServiceCode;
  name?: string;
  onPreselect?: (id: Media<boolean>) => void;
  onSelect?: (id: Media<boolean>, type: ResourceType) => void;
  previewUrl?: string;
  selected?: boolean;
  showInfo?: boolean;
  selectable?: boolean;
  aspect?: number;
  multimedia?: boolean;
}

const FileInputMediaElement: FC<FileInputMediaElementProps> = ({
  resourceType,
  id,
  name,
  previewUrl,
  service,
  onSelect,
  onPreselect,
  selected = false,
  showInfo = true,
  selectable = false,
  aspect = 16 / 9,
  multimedia = false,
  ...props
}) => {
  const [preview, setPreview] = useState(previewUrl);
  const [loading, setLoading] = useState(false);
  const [fileName, setName] = useState(name);
  const [size, setSize] = useState<string | null>(null);
  const [fileType, setFileType] = useState('');
  const [ext, setExt] = useState('');
  const { colors } = useMantineTheme();

  const fetchFileInfo = async () => {
    setLoading(true);

    const file = await getFileInfo(service, id as Media<false>);

    if (isEmpty(file)) return;

    setPreview(file.url);
    setName(file.name);
    setExt(file.ext);
    setFileType(file.contentType);
    setSize(file.size);

    setLoading(false);
  };

  const fetchFolderInfo = async () => {
    setLoading(true);

    const folder = await getFolderInfo(service, id as Media<false>);

    if (isEmpty(folder) || !folder.size) return;

    setSize(folder.size);

    setLoading(false);
  };

  const handlePreselect = () => {
    if (multimedia) {
      const result: MultiMedia = {
        id: id as Media<false>,
        serviceCode: service,
      };

      if (onPreselect) onPreselect(result);
    } else {
      if (onPreselect) onPreselect(id);
    }
  };

  const handleSelect = () => {
    if (multimedia) {
      const result: MultiMedia = {
        id: id as Media<false>,
        serviceCode: service,
      };

      if (onSelect) onSelect(result, resourceType);
    } else {
      if (onSelect) onSelect(id, resourceType);
    }
  };

  useEffect(() => {
    if (resourceType === ResourceType.FILE && (!previewUrl || !name)) fetchFileInfo();
    if (resourceType === ResourceType.FOLDER && !size) fetchFolderInfo();
  }, [id]);

  const previewComponent = useMemo(() => {
    if (resourceType === ResourceType.FOLDER) return <FileInputFolderPreview />;

    if (!preview) return null;

    if (VIDEO_EXTENSIONS.includes(ext.toLocaleLowerCase())) {
      return <FileInputVideoPreview src={preview} name={fileName} />;
    }

    if (fileType.split('/')[0] === 'image') {
      return <FileInputImagePreview src={preview} name={fileName} />;
    }

    return <FileInputFilePreview />;
  }, [ext, fileType]);

  const wrapperStyles = useMemo(() => {
    if (!selectable) return { overflow: 'hidden' };

    return {
      border: selected ? `2px solid ${colors['science-blue'][9]}` : '2px solid transparent',
      borderRadius: 4,
      overflow: 'hidden',
    };
  }, [selected, selectable]);

  return (
    <Box onClick={handlePreselect} onDoubleClick={handleSelect}>
      <UnstyledButton
        display="block"
        w="100%"
        maw="20rem"
        bg={colors.gray[1]}
        sx={wrapperStyles}
        style={{
          background: 'transparent',
        }}
      >
        <Paper {...props} sx={{ overflow: 'hidden' }}>
          <AspectRatio ratio={aspect} sx={{ position: 'relative' }}>
            <Flex
              justify="center"
              align="center"
              top={0}
              left={0}
              w="100%"
              h="100%"
              sx={{ overflow: 'hidden' }}
            >
              {previewComponent}
            </Flex>
            <LoadingOverlay
              overlayProps={{ color: colors.gray[0], blur: 2, opacity: 0.4 }}
              visible={loading}
            />
          </AspectRatio>

          {showInfo ? (
            <Box px="sm" py={4}>
              <Text
                size="sm"
                fw={500}
                truncate
                ta="center"
                mt="0.25rem"
                title={`${fileName}.${ext}`}
              >
                {fileName}
              </Text>
              <Text size="sm" fw={400} c="dimmed" truncate ta="center">
                &nbsp;{size}
              </Text>
            </Box>
          ) : null}
        </Paper>
      </UnstyledButton>
    </Box>
  );
};

export default FileInputMediaElement;
