import { TQueryParams } from '@/types';
import { IListRes, TModel, TModelItem } from '@/types/modelsPlugin/modelsPlugin_v2';

import api from '../instance';
import { URLS } from '../urls';

// Получение списка моделей
export const apiGetModels = (params: TQueryParams) =>
  api.get<IListRes<TModelItem>>(URLS.models.models.getModels, { params });

// Получение описания модели
export const apiGetModel = (modelCode: string) =>
  api.get<TModel>(URLS.models.models.getModel(modelCode));
