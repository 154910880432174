import { LocaleObject } from 'yup';

export const yupCustomLocale: LocaleObject = {
  mixed: {
    default: 'Ошибка',
    notType: 'Неверный тип значения у поля',
    required: 'Поле обязательно для заполнения',
  },
  string: {
    min: ({ min }) => `Значение поля: не должно быть менее ${min} символов`,
    max: ({ max }) => `Значение поля: не должно превышать ${max} символов`,
    email: 'Значение поля: невалидный Email',
  },
  array: {
    min: ({ min }) => `Значение поля: не должно быть менее ${min} элементов`,
    max: ({ max }) => `Значение поля: не должно превышать ${max} элементов`,
  },
  number: {
    min: ({ min }) => `Значение поля: не должно быть менее ${min}`,
    max: ({ max }) => `Значение поля: не должно превышать ${max}`,
  },
};
