import { FC, useState } from 'react';
import { Image, Skeleton } from '@mantine/core';

import { IFile } from '@/types';

interface MediaImageProps {
  item: IFile;
}

const MediaImage: FC<MediaImageProps> = ({ item }) => {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && <Skeleton w="100%" h="100%" />}
      <Image
        w={96}
        h={64}
        alt={item.alt || item.name}
        src={item.url}
        fit="contain"
        fallbackSrc={'/images/icons/photo.svg'}
        display={loading ? 'none' : 'block'}
        onLoad={() => setLoading(false)}
        onError={() => setLoading(false)}
      />
    </>
  );
};

export default MediaImage;
