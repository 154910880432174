import Image from '@editorjs/image';

import notify from '@/utils/notify';

import { fetching } from '../../SearchableSelect/utils/fetching';
import ArticleWithImage from '../components/ArticleWithImage';
import BannerEJS from '../components/BannerEJS';
import ImageWithLink from '../components/ImageWithLink';
import ProductEJS from '../components/ProductEJS';

import { EDITOR_JS_TOOLS } from './tools';
import uploaderFile from './uploaderFile';

export const getTools = (formField: any) => {
  let tools = {};
  const { extra } = formField;

  if (!extra) return tools;

  const { mediaUpload, productsHints, categoriesHints, snippetsConfig } = extra;

  const image = {
    class: Image,
    config: {
      captionPlaceholder: 'Вставьте подпись',
      uploader: {
        uploadByFile(file: File) {
          return uploaderFile(file, extra.mediaUpload.byFile)
            .then((res) => {
              return res;
            })
            .catch((error) => {
              notify({ message: error.response.data.message, type: 'error' });
            });
        },
      },
    },
  };

  const imageWithLink = {
    class: ImageWithLink,
    toolbox: {
      title: 'Изображение с ссылкой',
    },
    config: {
      uploader: async (file: File) => uploaderFile(file, mediaUpload.byFile),
    },
  };

  const articleImageLeft = {
    class: ArticleWithImage,
    inlineToolbar: true,
    toolbox: {
      title: 'Изображение и текст слева',
    },
    config: {
      position: 'left',
      uploader: async (file: File) => uploaderFile(file, mediaUpload.byFile),
    },
  };

  const articleImageRight = {
    class: ArticleWithImage,
    inlineToolbar: true,
    toolbox: {
      title: 'Изображение и текст справа',
    },
    config: {
      position: 'right',
      uploader: async (file: File) => uploaderFile(file, mediaUpload.byFile),
    },
  };

  const product = {
    class: ProductEJS,
    config: {
      withSwitcher: true,
      request: async (value: string) => fetching(productsHints.request, value),
    },
  };

  const productShelf = {
    class: ProductEJS,
    toolbox: {
      title: 'Полка с товарами',
    },
    config: {
      multiple: true,
      automatic: snippetsConfig === 'brands',
      request: async (value: string) => fetching(productsHints.request, value),
    },
  };

  const banner = {
    class: BannerEJS,
    toolbox: {
      title: 'Баннер',
    },
    config: {
      uploader: async (file: File) => uploaderFile(file, mediaUpload.byFile),
    },
  };

  const banners = {
    class: BannerEJS,
    toolbox: {
      title: 'Баннеры с категориями',
    },
    config: {
      multiple: true,
      uploader: async (file: File) => uploaderFile(file, mediaUpload.byFile),
      request: async (value: string) => fetching(categoriesHints.request, value),
    },
  };

  switch (snippetsConfig) {
    case 'brands':
      tools = {
        product,
        productShelf,
        banner,
        banners,
      };
      return tools;
    default:
      tools = {
        ...EDITOR_JS_TOOLS,
        image,
        imageWithLink,
        articleImageLeft,
        articleImageRight,
        product,
        productShelf,
        banner,
        banners,
      };
      return tools;
  }
};
