import { FC, useEffect, useState } from 'react';
import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Divider,
  Group,
  Loader,
  Popover,
  ScrollArea,
  Text,
  TextInput,
  TextInputProps,
} from '@mantine/core';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { IconX } from '@tabler/icons-react';
import isArray from 'lodash/isArray';

import { TAny, TFormField, TSelectData } from '@/types/modelsPlugin/modelsPlugin_v2';

import { fetching } from '../SearchableSelect/utils/fetching';
import { getSelectData } from '../SearchableSelect/utils/getSelectData';

interface SearchableFilterProps extends TextInputProps {
  field: TFormField;
  cb?: (TSelectData: string[]) => void;
}

const transformValue = (value: TAny | undefined): string[] => {
  if (value === undefined) return [];

  const result: string[] = [];

  if (isArray(value)) {
    value.forEach((el) => {
      result.push(String(el));
    });
  } else {
    result.push(String(value));
  }

  return result;
};

const SearchableFilter: FC<SearchableFilterProps> = ({ field, cb, ...props }) => {
  const { extra } = field;
  const [opened, handlers] = useDisclosure(false);
  const [dropdown, setDropdown] = useState<HTMLDivElement | null>(null);
  const [control, setControl] = useState<HTMLDivElement | null>(null);
  const [searchFetching, setSearchFetching] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchData, setSearchData] = useState<TSelectData[]>([]);
  const [selectedTempData, setSelectedTempData] = useState<string[]>(transformValue(field.value));
  const [selectedData, setSelectedData] = useState<string[]>(transformValue(field.value));

  useClickOutside(() => handlers.close(), null, [control, dropdown]);

  const handleSearch = async (value: string) => {
    // Поиск в фильтрах
    if (value.length >= 3 && extra?.request) {
      setSearchFetching(true);

      await fetching(extra.request, value, {}, (res) =>
        setSearchData(
          getSelectData(res.items, {
            filters: true,
          })
        )
      ).finally(() => setSearchFetching(false));
    } else if (value.length < 3) {
      setSearchData([]);
    }
  };

  const handleClick = () => {
    if (!opened) handlers.open();
  };

  const handleApply = () => {
    if (cb) cb(selectedTempData);

    handlers.close();
  };

  const handleClear = () => {
    setSelectedData([]);
    setSelectedTempData([]);
    if (!(extra && extra.selectData)) setSearchData([]);
    setSearchValue('');

    if (cb) cb([]);
  };

  const initialFetch = (value?: string) => {
    if (field.value && extra && extra.request) {
      setSearchFetching(true);
      fetching(
        extra.request,
        value
          ? value
          : isArray(field.value)
            ? String(field.value[0]).slice(1, 3)
            : String(field.value).slice(1, 3)
      )
        .then((res) => {
          setSearchData(
            getSelectData(res.data.items, {
              filters: true,
            })
          );
        })
        .finally(() => setSearchFetching(false));
    } else if (extra && extra.selectData) {
      setSearchData(extra.selectData);
    }
  };

  useEffect(() => {
    initialFetch();
  }, []);

  useEffect(() => {
    if (field.value) {
      initialFetch(searchValue);
    } else {
      setSearchData(extra?.selectData ? extra.selectData : []);
      setSearchValue('');
    }

    setSelectedData(transformValue(field.value));
    setSelectedTempData(transformValue(field.value));
  }, [field.value]);

  useEffect(() => {
    if (searchValue) handleSearch(searchValue);
  }, [searchValue]);

  return (
    <Popover opened={opened} width="target" position="bottom" shadow="md">
      <Popover.Target>
        <TextInput
          ref={setControl}
          onInput={(ev) => setSearchValue(ev.currentTarget.value)}
          {...props}
          value={searchValue}
          onChange={undefined}
          onClick={handleClick}
          leftSection={
            searchFetching ? (
              <Loader size="sm" />
            ) : selectedData.length > 0 ? (
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.colors.blue[5],
                  color: theme.colors.gray[1],
                  width: 22,
                  height: 22,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                })}
              >
                <Box fz={11} fw={700} lh={'22px'}>
                  {selectedData.length}
                </Box>
              </Box>
            ) : (
              props.leftSection
            )
          }
          rightSection={
            (searchValue.length > 0 || selectedData.length > 0) && (
              <ActionIcon color="gray.9" variant="transparent" onClick={handleClear}>
                <IconX size={17} />
              </ActionIcon>
            )
          }
        />
      </Popover.Target>

      <Popover.Dropdown pr={0}>
        <Box ref={setDropdown}>
          {searchData && searchData.length === 0 && (
            <Text c="gray" size={'sm'} ta="center" pr={16}>
              Ничего не найдено
            </Text>
          )}

          {searchData && searchData.length > 0 && (
            <>
              <ScrollArea
                offsetScrollbars
                // Если элементов больше 5 ограничиваем выпадающий список до 250px
                h={searchData.length > 5 ? 250 : 'min-content'}
                mx="auto"
                type="auto"
              >
                <Checkbox.Group w={'100%'} value={selectedTempData} onChange={setSelectedTempData}>
                  {searchData.map((item, index) => (
                    <div key={item.value}>
                      <Checkbox w={'100%'} value={String(item.value)} label={item.label} />
                      {searchData.length - index !== 1 && (
                        <Divider
                          sx={(theme) => ({
                            margin: '12px 0',
                            borderTopColor: theme.colors.gray[2],
                          })}
                        />
                      )}
                    </div>
                  ))}
                </Checkbox.Group>
              </ScrollArea>

              <Divider
                sx={(theme) => ({
                  margin: '0px 0 12px -16px',
                  borderTopColor: theme.colors.gray[2],
                })}
              />
              <Group grow pr={16}>
                <Button size="xs" color="gray" variant="light" onClick={() => handlers.close()}>
                  Отменить
                </Button>
                <Button size="xs" onClick={handleApply}>
                  Применить
                </Button>
              </Group>
            </>
          )}
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
};

export default SearchableFilter;
