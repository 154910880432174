import { FC, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Group, NumberInput, Select, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { PluginCode, SortOrderEnum, TConfigurationOption, TypeEnum } from '@/types';

import { OPTION_CONFIGURATION_FORM } from '@/constants/common/validationSchemas';

import { useQueryParams } from '@/hooks/useQueryParams';
import { useServices } from '@/hooks/useServices';
import { useTranslitForm } from '@/hooks/useTranslitForm';

import { AppDispatch } from '@/store';
import {
  fetchPutConfigurationOptionAction,
  selectFetchingPutConfigurationOption,
} from '@/store/slices/configurationPlugin/configurationOption';
import {
  fetchAddConfigurationOptionsAction,
  fetchGetConfigurationOptionsAction,
  selectFetchingAddConfigurationOptions,
} from '@/store/slices/configurationPlugin/configurationOptions';

interface ConfigurationFormProps {
  type: 'new' | 'edit';
  element?: Record<string, ReactNode> | null;
}

const ConfigurationForm: FC<ConfigurationFormProps> = ({ type, element }) => {
  const dispatch: AppDispatch = useDispatch();
  const { currentOrder, currentSort, currentLimit, currentOffset } = useQueryParams();
  const params = useParams();
  const confId = params.confId;
  const optId = element?.id as string;
  const { currentService, availablePlugins } = useServices();
  const isEditMode = type === 'edit';
  const isCreateMode = type === 'new';

  const fetchingAdd = useSelector(selectFetchingAddConfigurationOptions);
  const fetchingPut = useSelector(selectFetchingPutConfigurationOption);

  const getSelectTypes = () => {
    const result = [
      { value: TypeEnum.STRING, label: 'Строка' },
      { value: TypeEnum.TEXT, label: 'Текст' },
      { value: TypeEnum.CHECKBOX, label: 'Чекбокс' },
      { value: TypeEnum.DATE, label: 'Дата' },
      { value: TypeEnum.DATETIME, label: 'Дата и время' },
      { value: TypeEnum.INTEGER, label: 'Число' },
    ];

    if (availablePlugins.includes(PluginCode.MEDIA)) {
      result.push({ value: TypeEnum.FILE, label: 'Файл' });
      result.push({ value: TypeEnum.IMAGE, label: 'Изображение' });
    }

    return result;
  };

  const form = useForm({
    initialValues: {
      name: (element?.name as string) || '',
      code: (element?.code as string) || '',
      type: (element?.type as TypeEnum) || TypeEnum.STRING,
      sort: (element?.sort as number) || 500,
    },
    validate: yupResolver(OPTION_CONFIGURATION_FORM),
  });

  const { setOriginalHandler } = useTranslitForm({
    inputNames: {
      original: 'name',
      translit: 'code',
    },
    form,
    opts: { onlyLower: true },
    active: isCreateMode,
  });

  const reloadPage = async () => {
    if (confId && currentService) {
      await dispatch(
        fetchGetConfigurationOptionsAction({
          id: confId,
          params: {
            offset: currentOffset,
            limit: 100 || currentLimit,
            order: currentOrder || SortOrderEnum.ASC,
            sort: currentSort || 'sort',
          },
        })
      );
    }
  };

  const submitHandler = async (values: TConfigurationOption) => {
    if (confId && isCreateMode && currentService) {
      await dispatch(fetchAddConfigurationOptionsAction({ id: confId, option: values })).then(() =>
        reloadPage()
      );
    }

    if (confId && optId && isEditMode && currentService) {
      await dispatch(
        fetchPutConfigurationOptionAction({ params: { confId, optId }, data: values })
      ).then(() => reloadPage());
    }
  };

  return (
    <form onSubmit={form.onSubmit((values) => submitHandler(values))}>
      <TextInput
        mb={24}
        label="Название"
        placeholder="Введите название"
        onInput={setOriginalHandler}
        withAsterisk
        {...form.getInputProps('name')}
      />
      <TextInput
        mb={24}
        label="Символьный код"
        placeholder="Введите код"
        withAsterisk
        disabled={type === 'edit'}
        {...form.getInputProps('code')}
      />
      <Select
        mb={24}
        label="Тип"
        placeholder="Выберите вариант"
        data={getSelectTypes()}
        withAsterisk
        allowDeselect={false}
        withCheckIcon={false}
        disabled={type === 'edit'}
        {...form.getInputProps('type')}
      />
      <NumberInput
        mb={24}
        label="Сортировка"
        placeholder="Сортировка"
        withAsterisk
        {...form.getInputProps('sort')}
      />

      <Group justify="right">
        <Button loading={fetchingAdd || fetchingPut} type={'submit'}>
          Сохранить
        </Button>
      </Group>
    </form>
  );
};

export default ConfigurationForm;
