import React, { FC, memo } from 'react';
import { DatesRangeValue, DateValue } from '@mantine/dates';

import { DateRangeInput } from '../../../molecules/DateRangeInput/DateRangeInput';
import { FilterTypes, TFilterProps } from '../types';
import { useActiveFilters } from '../utils/useActiveFilters';

export const DateRangeFilter: FC<TFilterProps<FilterTypes.IFilterDateRange>> = memo(
  ({
    code,
    name,
    initialValue = [null, null] as DatesRangeValue,
    value = initialValue,
    placeholder = 'Укажите период',
    showTime = false,
    inputProps,
  }) => {
    const { onChange } = useActiveFilters(code, initialValue);

    const changeHandler = (newValue: DatesRangeValue | DateValue | Date[]) => {
      if (newValue) onChange(newValue as DatesRangeValue);
    };

    const dateFormat = inputProps?.dateFormat || `DD.MM.YYYY${showTime ? ' HH:mm:ss' : ''}`;

    return (
      <DateRangeInput
        label={name}
        placeholder={placeholder}
        value={value}
        onChange={changeHandler}
        valueFormat={dateFormat}
        w={238}
      />
    );
  }
);
