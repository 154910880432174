import { TTableConfig } from '@/types';

export const TABLE_MODELS: TTableConfig[] = [
  {
    code: 'code',
    name: 'Код',
  },
  {
    code: 'name',
    name: 'Название',
    minWidth: 226,
  },
];
