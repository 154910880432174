import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { IToken, ServiceCode } from '@/types';

import notify from '@/utils/notify';

import { useServices } from '@/hooks/useServices';

import { AppDispatch } from '@/store';
import {
  setFetchingDownloadModeratorsStat,
  setFetchingImportReviewsFromFile,
  setFetchingImportReviewsStopListFromFile,
  setFetchingReviewsAnalysis,
  setOpenReviewsAnalysisModal,
  setOpenReviewsImportModal,
  setOpenReviewsModeratorsModal,
  setOpenReviewsStopListModal,
} from '@/store/slices/reviewsPlugin/reviews';

import ReviewsModalLink from '../components/ReviewsModalLink/ReviewsModalLink';

export const useWSSConnection = () => {
  const dispatch: AppDispatch = useDispatch();
  const socketRef = useRef<WebSocket | null>();
  const { currentService } = useServices();
  const storageToken = localStorage.getItem('token');
  let accessToken: IToken | null = null;

  if (storageToken) {
    accessToken = JSON.parse(storageToken).access_token;
  }

  useEffect(() => {
    const socket = socketRef.current;

    if (
      !accessToken ||
      !currentService ||
      (currentService && currentService.code !== ServiceCode.REVIEWS)
    ) {
      if (socket) socket.close();

      return;
    }

    const newSocket = new WebSocket(
      `${process.env.WEB_SOCKET_URL}?token=${accessToken}&serviceCode=${currentService?.code}`
    );

    newSocket.onopen = () => {
      // console.log('open');
    };

    newSocket.onmessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data as string);

      if (data && data.value) {
        const { msg, succeed, file } = data.value;

        const setMessage = (success: boolean, message: string, link: string) => {
          return notify({
            title: message,
            message: link ? (
              <ReviewsModalLink type="link" to={link}>
                Загрузить файл {!succeed && 'с ошибками'}
              </ReviewsModalLink>
            ) : (
              ''
            ),
            autoClose: false,
            type: success ? 'success' : 'error',
          });
        };

        switch (data.code) {
          case 'reviews_import':
            setMessage(succeed, msg, file);
            dispatch(setFetchingImportReviewsFromFile(false));
            dispatch(setOpenReviewsImportModal(false));
            break;
          case 'stop_words_import':
            setMessage(succeed, msg, file);
            dispatch(setFetchingImportReviewsStopListFromFile(false));
            dispatch(setOpenReviewsStopListModal(false));
            break;
          case 'reviews_export':
            setMessage(succeed, msg, file);
            dispatch(setFetchingReviewsAnalysis(false));
            dispatch(setOpenReviewsAnalysisModal(false));
            break;
          case 'moderators_statistics_export':
            setMessage(succeed, msg, file);
            dispatch(setFetchingDownloadModeratorsStat(false));
            dispatch(setOpenReviewsModeratorsModal(false));
            break;
          default:
            break;
        }
      }
    };

    newSocket.onerror = () => {
      dispatch(setFetchingImportReviewsFromFile(false));
      dispatch(setFetchingImportReviewsStopListFromFile(false));
      dispatch(setFetchingDownloadModeratorsStat(false));

      notify({
        message: 'Произошла ошибка соединения',
        type: 'error',
      });
    };

    socketRef.current = newSocket;

    return () => {
      if (socket && socket.readyState === 1 && accessToken) {
        socketRef.current = null;
        socket.close();
      }
    };
  }, [accessToken, currentService]);
};
