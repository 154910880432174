import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Group, Menu } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconCloudDown } from '@tabler/icons-react';

import { AppDispatch } from '@/store';
import {
  selectFetchingImportReviewsFromArchive,
  setOpenAddReviewsElementModal,
  setOpenReviewsImportArchiveModal,
  setOpenReviewsImportModal,
  setOpenReviewsStopListModal,
} from '@/store/slices/reviewsPlugin/reviews';

import ReviewsReportMenu from '../ReviewsReportMenu/ReviewsReportMenu';

const ReviewsRightButtons: FC = () => {
  const isMobile = useMediaQuery('(max-width: 1400px)');
  const dispatch: AppDispatch = useDispatch();
  const fetchingImportReviewsFromArchive = useSelector(selectFetchingImportReviewsFromArchive);

  const handleNewReview = () => {
    dispatch(setOpenAddReviewsElementModal(true));
  };

  const handleFetchReview = () => {
    dispatch(setOpenReviewsImportModal(true));
  };

  const handleFetchArchive = () => {
    dispatch(setOpenReviewsImportArchiveModal(true));
  };

  const handleRefreshList = () => {
    dispatch(setOpenReviewsStopListModal(true));
  };

  const buttons = [
    { title: 'Новый отзыв', onClick: handleNewReview },
    { title: 'Загрузить отзывы', onClick: handleFetchReview },
    { title: 'Загрузить архив', onClick: handleFetchArchive },
    { title: 'Обновить список стоп-слов', onClick: handleRefreshList },
  ];

  return (
    <Group justify="right">
      {isMobile ? (
        <Menu>
          <Menu.Target>
            <Button>Действия</Button>
          </Menu.Target>
          <Menu.Dropdown>
            {buttons.map((item) => (
              <Menu.Item miw={isMobile ? 100 : 144} key={item.title} {...item}>
                {item.title}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      ) : (
        <>
          <Button
            onClick={() => {
              dispatch(setOpenAddReviewsElementModal(true));
            }}
          >
            Новый отзыв
          </Button>
          <Button
            leftSection={<IconCloudDown size="1.2rem" />}
            onClick={() => {
              dispatch(setOpenReviewsImportModal(true));
            }}
          >
            Загрузить отзывы
          </Button>
          <Button
            onClick={() => {
              dispatch(setOpenReviewsImportArchiveModal(true));
            }}
            leftSection={<IconCloudDown size="1.2rem" />}
            loading={fetchingImportReviewsFromArchive}
          >
            Загрузить архив
          </Button>
          <Button
            onClick={() => {
              dispatch(setOpenReviewsStopListModal(true));
            }}
          >
            Обновить список стоп-слов
          </Button>
        </>
      )}
      <ReviewsReportMenu />
    </Group>
  );
};

export default ReviewsRightButtons;
