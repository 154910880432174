import React, { FC, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Group, ModalProps, Text } from '@mantine/core';

import { AppDispatch } from '@/store';
import { selectFolders, selectMoveInFolder } from '@/store/slices/mediaPlugin/media';
import { fetchMoveFile, selectCurrentFile } from '@/store/slices/mediaPlugin/mediaFiles';
import { fetchMoveFolder } from '@/store/slices/mediaPlugin/mediaFolders';
import { selectCurrentFolder } from '@/store/slices/mediaPlugin/mediaFolders';
import { selectCurrentService } from '@/store/slices/service/service';

import AccordionTree from '@/ui/organisms/AccordionTree/AccordionTree';

import BaseModal from '@/ui/molecules/BaseModal/BaseModal';

import { MediaContext } from '../../utils/mediaContext';

const ModalMove: FC<ModalProps> = (props) => {
  const [isMoving, setIsMoving] = useState<boolean>(false);
  const { onReload } = useContext(MediaContext);
  const dispatch: AppDispatch = useDispatch();
  const currentFolder = useSelector(selectCurrentFolder);
  const currentFile = useSelector(selectCurrentFile);
  const folders = useSelector(selectFolders);
  const moveFolder = useSelector(selectMoveInFolder);
  const service = useSelector(selectCurrentService);

  const confirmHandler = async () => {
    if (
      currentFile?.id &&
      typeof moveFolder !== 'string' &&
      moveFolder?.folderFields.id &&
      service
    ) {
      setIsMoving(true);
      await dispatch(fetchMoveFile({ id: currentFile.id, folderId: moveFolder.folderFields.id }));
      setIsMoving(false);
    } else if (currentFile?.id && service) {
      setIsMoving(true);
      await dispatch(fetchMoveFile({ id: currentFile.id, folderId: null }));
      setIsMoving(false);
    }

    if (
      currentFolder?.id &&
      typeof moveFolder !== 'string' &&
      moveFolder?.folderFields.id &&
      service
    ) {
      setIsMoving(true);
      await dispatch(
        fetchMoveFolder({ id: currentFolder.id, parentFolderId: moveFolder.folderFields.id })
      );
      setIsMoving(false);
    } else if (currentFolder?.id && service) {
      setIsMoving(true);
      await dispatch(fetchMoveFolder({ id: currentFolder.id, parentFolderId: null }));
      setIsMoving(false);
    }

    onReload();
    props.onClose();
  };

  return (
    <BaseModal
      {...props}
      footer={
        <Group w="100%" grow>
          <Button variant="outline" color={'red'} onClick={props.onClose} disabled={isMoving}>
            Отмена
          </Button>
          <Button
            onClick={confirmHandler}
            disabled={!moveFolder && typeof moveFolder !== 'string'}
            loading={isMoving}
          >
            Переместить
          </Button>
        </Group>
      }
      title={
        <Text fz={22} fw={700}>
          {'Переместить'}
          {moveFolder && typeof moveFolder !== 'string' && (
            <>{` в ${moveFolder.folderFields.name}`}</>
          )}
          {typeof moveFolder === 'string' && <>{' в Корневую папку'}</>}
        </Text>
      }
    >
      <AccordionTree folders={folders} />
    </BaseModal>
  );
};

export default React.memo(ModalMove);
