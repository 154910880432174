import { FC, PropsWithChildren } from 'react';
import { Paper, PaperProps } from '@mantine/core';

const PageBody: FC<PropsWithChildren & PaperProps> = ({ children, ...props }) => {
  return (
    <Paper
      shadow="xs"
      p={24}
      mx={15}
      radius={8}
      display={'flex'}
      h={'100%'}
      pos={'relative'}
      sx={{
        flexDirection: 'column',
        overflow: 'hidden',
      }}
      {...props}
    >
      {children}
    </Paper>
  );
};

export default PageBody;
