import { FC, ReactNode } from 'react';
import { BoxProps, Container, Flex, Text } from '@mantine/core';

import { BreadcrumbsItem } from '@/types';

import BreadcrumbsExt from '@/ui/organisms/BreadcrumbsExt/BreadcrumbsExt';

import PageHeaderBackLink from './components/PageHeaderBackLink/PageHeaderBackLink';
import PageHeaderTitle from './components/PageHeaderTitle/PageHeaderTitle';

interface IPageHeader extends BoxProps {
  title: string;
  subTitle?: string;
  backLink?: string;
  rightButton?: ReactNode;
  breadcrumbs?: BreadcrumbsItem[];
}

const PageHeader: FC<IPageHeader> = ({ title, subTitle, backLink, rightButton, breadcrumbs }) => {
  return (
    <Container fluid w="100%" mb={5}>
      <Flex align="flex-start" w="100%" direction="column" gap="0">
        <BreadcrumbsExt items={breadcrumbs || []} />

        <Flex mih={22} w="100%" align="center" justify="space-between">
          <Flex align="baseline" gap="xs">
            {backLink && <PageHeaderBackLink url={backLink} />}

            <PageHeaderTitle title={title} />
          </Flex>
          {rightButton}
        </Flex>

        <Text
          sx={{
            whiteSpace: 'nowrap',
          }}
          display={'block'}
          fz={'xs'}
          fw="bold"
          pl={38}
          mih={19}
          c={'gray.6'}
        >
          {subTitle}
        </Text>
      </Flex>
    </Container>
  );
};

export default PageHeader;
