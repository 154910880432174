import { buildYup } from 'schema-to-yup';

import { MultiMedia } from '@/types';
import {
  FormFieldTypeEnum,
  TAny,
  TFormField,
  TValidationField,
} from '@/types/modelsPlugin/modelsPlugin_v2';

import { uniqSchema } from '@/constants/common/validationSchemas';

import { getErrMessages } from './getErrMessages';
import { getSchema } from './getSchema';

export const formValidate = (
  value: TAny | undefined,
  array: {
    fields: TFormField[];
  },
  path: string,
  validation?: Record<string, TValidationField>
): string | null => {
  const field = array.fields[parseInt(path.split('.')[1])];
  const fieldValidation =
    validation && validation[field.code]
      ? {
          [field.code]: validation[field.code],
        }
      : null;

  if (fieldValidation) {
    const schema = getSchema(fieldValidation);
    const builderConfig = {
      mode: { notRequired: true },
      errMessages: getErrMessages(validation),
    };
    const yupSchema = buildYup(schema, builderConfig);

    // Проверка и преобразование числового поля
    // TODO кусок с try нужно выносить так как он много повторяется
    switch (field.type) {
      case FormFieldTypeEnum.INTINPUT:
      case FormFieldTypeEnum.FLOATINPUT:
      case FormFieldTypeEnum.UINTINPUT:
        if (value === '') {
          try {
            yupSchema.validateSync({ [field.code]: null });
          } catch (err) {
            return `${err}`.slice(17);
          }
        } else {
          try {
            yupSchema.validateSync({ [field.code]: value });
          } catch (err) {
            return `${err}`.slice(17);
          }
        }
        break;
      case FormFieldTypeEnum.MULTIMEDIA:
        if (value === null) {
          try {
            yupSchema.validateSync({ [field.code]: null });
          } catch (err) {
            return `Заполните поле: ${field.name}`;
          }
        } else {
          try {
            if (fieldValidation[field.code].unique) {
              const valueArr = (field.value as MultiMedia[]).map((el) => el.id);
              uniqSchema(field.name).validateSync(valueArr);
            }

            yupSchema.validateSync({ [field.code]: value });
          } catch (err) {
            return `${err}`.slice(17);
          }
        }
        break;
      case FormFieldTypeEnum.COLORSINPUT:
        if (value === '') {
          try {
            yupSchema.validateSync({ [field.code]: undefined });
          } catch (err) {
            return `${err}`.slice(17);
          }
        } else {
          try {
            if (fieldValidation[field.code].unique) {
              uniqSchema(field.name).validateSync(field.value);
            }
            yupSchema.validateSync({ [field.code]: value });
          } catch (err) {
            return `${err}`.slice(17);
          }
        }

        break;
      default:
        if (value === '') {
          try {
            yupSchema.validateSync({ [field.code]: undefined });
          } catch (err) {
            return `${err}`.slice(17);
          }
        } else {
          try {
            yupSchema.validateSync({ [field.code]: value });
          } catch (err) {
            return `${err}`.slice(17);
          }
        }
        break;
    }
  }

  return null;
};
