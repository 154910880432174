import { FC, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  ActionIcon,
  Button,
  Group,
  LoadingOverlay,
  Select,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { IconMinus, IconPlus } from '@tabler/icons-react';
import cloneDeep from 'lodash/cloneDeep';

import { AdditionalField, MenuItem, MenuItemForm } from '@/types';

import { NEW_ITEM_MENU_FORM } from '@/constants/common/validationSchemas';

import notify from '@/utils/notify';

import { tryAddMenuItems } from '@/api/menuPlugin/menuItem';

import { useBlockingDispatch } from '@/hooks/useBlockingDispatch';

import {
  fetchAddMenuItemsAction,
  fetchGetMenuItemAction,
  fetchGetMenuItemsAction,
  fetchGetMenuItemsChildrenAction,
  fetchMoveMenuItemsAction,
  fetchPutMenuItemsAction,
  selectCurrentPath,
  selectFullMenuItem,
  selectParentId,
  selectTreeData,
  setAddMenuItemsModalOpened,
} from '@/store/slices/menuPlugin/menuItems';
import { fetchGetDomainsAction, selectDomains } from '@/store/slices/menuPlugin/menus';

import { transformData } from '@/ui/pages/MenuContainer/components/FormNewItems/utils/transformData';

const DEFAULT_VALUES: MenuItemForm = {
  name: '',
  url: '',
  additionalFields: [],
  domainId: null,
};

interface IFormNewOption {
  type: 'new' | 'edit';
  element?: Record<string, ReactNode> | null;
}

const FormNewItems: FC<IFormNewOption> = ({ type, element }) => {
  const fullMenuItem = useSelector(selectFullMenuItem);
  const params = useParams();
  const menuId = params.menuId;
  const parentId = useSelector(selectParentId);
  const currentPath = useSelector(selectCurrentPath);
  const treeData = useSelector(selectTreeData);
  const { dispatch, blocked } = useBlockingDispatch();
  const domainData = useSelector(selectDomains);

  const form = useForm({
    validate: yupResolver(NEW_ITEM_MENU_FORM),
    initialValues:
      type === 'new'
        ? DEFAULT_VALUES
        : {
            name: (fullMenuItem?.name as any) || '',
            domainId: (fullMenuItem?.domainId as any) || null,
            url: (fullMenuItem?.url as any) || '',
            additionalFields: (fullMenuItem?.additionalFields as AdditionalField[]) || [],
          },
  });

  useEffect(() => {
    dispatch(fetchGetDomainsAction({ offset: 0, limit: 100 }));

    // TODO Это просто адский костыль, по хорошему нужно переписывать всю логику меню
    if (type === 'edit') {
      const menuItemId = element?.id as string;

      if (menuId && menuItemId) {
        dispatch(fetchGetMenuItemAction({ menuId, menuItemId }));
      }
    }
  }, []);

  useEffect(() => {
    if (fullMenuItem && type === 'edit') {
      form.setFieldValue('name', fullMenuItem?.name);
      form.setFieldValue('url', fullMenuItem?.url?.replace(/^\//, ''));
      form.setFieldValue('domainId', fullMenuItem?.domainId);

      if (fullMenuItem.additionalFields && fullMenuItem.additionalFields.length) {
        const fields = cloneDeep(fullMenuItem.additionalFields);

        form.setFieldValue('additionalFields', [...fields]);
      }
    }
  }, [fullMenuItem]);

  const submitHandler = async (values: MenuItem) => {
    const filteredAddValues = values?.additionalFields?.filter(
      (item: any) => item.value && item.code
    );
    values.additionalFields = filteredAddValues;

    if (menuId && type === 'new') {
      if (parentId) {
        const addedId = await tryAddMenuItems({ id: menuId, data: { ...values } });

        await dispatch(
          fetchMoveMenuItemsAction({
            params: { menuId: menuId, menuItemId: addedId.id },
            data: { position: 1, parentMenuItemId: parentId },
          })
        );

        await dispatch(
          fetchGetMenuItemsChildrenAction({
            id: menuId,
            oldTreeData: treeData,
            path: currentPath,
            params: { parentMenuItemId: parentId },
            itemId: parentId,
          })
        );

        if (addedId) notify({ message: 'Пункт меню добавлен', type: 'success' });

        dispatch(setAddMenuItemsModalOpened(false));
      } else {
        await dispatch(fetchAddMenuItemsAction({ id: menuId, data: values }));
        await dispatch(fetchGetMenuItemsAction({ id: menuId }));
      }
    }

    if (menuId && element?.id && type === 'edit') {
      await dispatch(
        fetchPutMenuItemsAction({
          params: { menuId: menuId, menuItemId: element?.id.toString() },
          data: values,
        })
      );

      if (parentId) {
        await dispatch(
          fetchGetMenuItemsChildrenAction({
            id: menuId,
            oldTreeData: treeData,
            path: currentPath,
            params: { parentMenuItemId: parentId },
            itemId: parentId,
          })
        );
      } else {
        await dispatch(fetchGetMenuItemsAction({ id: menuId }));
        await dispatch(
          fetchGetMenuItemsChildrenAction({
            id: menuId,
            oldTreeData: treeData,
            path: currentPath,
            params: { parentMenuItemId: element.id as string },
            itemId: element.id as string,
          })
        );
      }
    }
  };

  const additionalFieldError = (index: number, field: keyof AdditionalField) => {
    const { value, error } = form.getInputProps(`additionalFields.${index}.${field}`);

    const requiredError = form.getInputProps(`additionalFields.${index}`).error;

    return requiredError && !value ? requiredError : error;
  };

  return (
    <form
      onSubmit={form.onSubmit((values: MenuItem) => submitHandler(values))}
      style={{ position: 'relative' }}
    >
      <TextInput
        placeholder="Введите название"
        label="Название"
        withAsterisk
        mb={24}
        {...form.getInputProps('name')}
      />

      <Select
        clearable
        allowDeselect={false}
        withCheckIcon={false}
        label="Домен"
        placeholder="Выберите вариант"
        data={transformData(domainData)}
        mb={24}
        {...form.getInputProps('domainId')}
      />

      <TextInput placeholder="Введите url" label="URL" mb={24} {...form.getInputProps('url')} />

      <Title order={5} mb={24}>
        Дополнительные опции
      </Title>

      {form?.values?.additionalFields?.map((item: any, index: number) => {
        const showAsterisk =
          !!form.values.additionalFields[index].value || !!form.values.additionalFields[index].code;

        return (
          <Group
            w="100%"
            sx={{ justifyContent: 'space-between' }}
            key={`${item.name}${index}`}
            align="start"
            mb={24}
          >
            <TextInput
              w="43%"
              placeholder="Введите название"
              label="Название параметра"
              {...form.getInputProps(`additionalFields.${index}.code`)}
              error={showAsterisk && additionalFieldError(index, 'code')}
              withAsterisk={showAsterisk}
            />

            <TextInput
              w="43%"
              placeholder="Введите значение"
              label="Значение"
              withAsterisk={showAsterisk}
              {...form.getInputProps(`additionalFields.${index}.value`)}
              error={showAsterisk && additionalFieldError(index, 'value')}
            />

            <ActionIcon
              variant="outline"
              color="gray.5"
              mt={24}
              size="lg"
              onClick={() => form.removeListItem('additionalFields', index)}
            >
              <IconMinus />
            </ActionIcon>
          </Group>
        );
      })}

      <Group justify="right" mb={24} align="center" gap={10}>
        <Text fz={'sm'}>Ещё параметр</Text>

        <ActionIcon
          variant="outline"
          color="gray.5"
          size="lg"
          onClick={() => form.insertListItem('additionalFields', { value: '', code: '' })}
        >
          <IconPlus />
        </ActionIcon>
      </Group>

      <Group justify="right">
        <Button type="submit" disabled={blocked}>
          Подтвердить
        </Button>
      </Group>

      <LoadingOverlay visible={blocked} />
    </form>
  );
};

export default FormNewItems;
