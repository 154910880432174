import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { TABLE_MENUS } from '@/constants/menusPlugin/tableHeaders';

import { selectFetchingGetMenus, selectMenus } from '@/store/slices/menuPlugin/menus';

import { useInfinityLoad } from '@/ui/pages/MenusContainer/components/MenusContainerBody/utils/useInfinityLoad';

import PageBody from '@/ui/templates/Page/components/PageBody/PageBody';
import PageContainer from '@/ui/templates/Page/components/PageContainer/PageContainer';

import TableExt from '@/ui/organisms/TableExt/TableExt';

import MenusTableButtons from '../MenusTableButtons/MenusTableButtons';

const MenusContainerBody: FC = () => {
  const { viewport, onScrollPositionChange } = useInfinityLoad();
  const fetchingMenus = useSelector(selectFetchingGetMenus);
  const menus = useSelector(selectMenus);

  const isEmpty = !menus || menus.length === 0;

  return (
    <PageBody sx={{ overflow: 'hidden' }}>
      <PageContainer
        isEmpty={isEmpty}
        loading={isEmpty && fetchingMenus}
        loaderText={'У вас пока нет меню'}
      >
        <TableExt
          config={TABLE_MENUS}
          rows={menus}
          onScrollPositionChange={({ x, y }) => onScrollPositionChange({ x, y })}
          scrollViewportRef={viewport}
          buttons={MenusTableButtons}
          sortableKeys={['name']}
        />
      </PageContainer>
    </PageBody>
  );
};

export default MenusContainerBody;
