import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Center,
  CopyButton,
  Image,
  ScrollArea,
  Skeleton,
  UnstyledButton,
} from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { IconFile, IconFolder } from '@tabler/icons-react';

import { isFile } from '@/utils/isFile/isFile';

import { selectSelectedId } from '@/store/slices/mediaPlugin/media';
import {
  selectCurrentFile,
  selectFetchingGetFileInfo,
} from '@/store/slices/mediaPlugin/mediaFiles';
import {
  selectCurrentFolder,
  selectFetchingGetFolderInfo,
} from '@/store/slices/mediaPlugin/mediaFolders';

import { fileDateFormat } from '../../utils/fileDateFormat';
import { isImage } from '../../utils/isImage';
import { isVideo } from '../../utils/isVideo';

import MediaSummaryItem from './components/MediaSummaryItem/MediaSummaryItem';
import VideoPreview from './components/VideoPreview/VideoPreview';

const useStyles = createStyles((theme) => ({
  mediaSummary: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: theme.radius.md,
    background: theme.colors.gray[1],
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  mediaImage: {
    width: '100%',
    color: theme.colors.gray[5],
  },
  mediaBody: {
    padding: '28px 12px 28px 12px',
  },
  mediaLink: {
    textDecoration: 'none',
    color: theme.colors.blue,
    transition: 'color .25s ease-in-out',
    '&:hover': {
      color: theme.colors.blue[7],
    },
  },
  imageBlock: {
    minHeight: '160px',
    display: 'flex',
    alignItems: 'center',
  },
}));
const MediaSummary: FC = () => {
  const { classes } = useStyles();
  const currentFolder = useSelector(selectCurrentFolder);
  const fetchingFolderInfo = useSelector(selectFetchingGetFolderInfo);
  const currentFile = useSelector(selectCurrentFile);
  const fetchingFileInfo = useSelector(selectFetchingGetFileInfo);
  const selectedItemId = useSelector(selectSelectedId);

  const currentItem = useMemo(
    () => [currentFile, currentFolder].find((item) => item?.id === selectedItemId),
    [currentFile, currentFolder, selectedItemId]
  );
  const isCurrentItemFile = currentItem && isFile(currentItem);

  return (
    <ScrollArea.Autosize
      h="100%"
      pos="relative"
      sx={{ borderRadius: '6px' }}
      styles={() => ({
        scrollbar: {
          '&[data-orientation="horizontal"] .mantine-ScrollArea-thumb': {
            opacity: 0,
          },
        },
      })}
    >
      <Box className={classes.mediaSummary}>
        <Box id="imageBlock" className={classes.imageBlock}>
          <Box className={classes.mediaImage}>
            {!currentItem && (
              <Center>
                <Skeleton height={100} width={100} />
              </Center>
            )}

            {!isCurrentItemFile && currentItem && !fetchingFolderInfo && !fetchingFileInfo && (
              <Center>
                <IconFolder size={100} />
              </Center>
            )}
            {currentFile &&
              !isVideo(currentFile.url) &&
              !isImage(currentFile.contentType) &&
              !fetchingFileInfo &&
              !fetchingFolderInfo && (
                <Center>
                  <IconFile size={100} />
                </Center>
              )}
            {currentFile &&
              isImage(currentFile.contentType) &&
              !fetchingFileInfo &&
              !fetchingFolderInfo && (
                <Image
                  src={currentFile.url}
                  alt={currentFile.alt || currentFile.name}
                  fallbackSrc={'/images/icons/photo.svg'}
                />
              )}
            {currentFile &&
              !fetchingFileInfo &&
              !fetchingFolderInfo &&
              isVideo(currentFile.url) && <VideoPreview url={currentFile.url} />}
          </Box>
        </Box>

        <Box className={classes.mediaBody}>
          {currentItem && !isCurrentItemFile && !fetchingFolderInfo && (
            <>
              <MediaSummaryItem name="Название" value={currentItem.name} mb={24} />
              <MediaSummaryItem name="Тип" value={'Папка'} mb={24} />
              <MediaSummaryItem name="Размер" value={currentItem.size} mb={24} />
            </>
          )}

          {currentItem && isCurrentItemFile && !fetchingFileInfo && (
            <>
              <MediaSummaryItem name="Название" value={currentItem.name} mb={24} />
              <MediaSummaryItem name="Тип" value={currentItem.ext} mb={24} />
              <MediaSummaryItem name="Размер" value={currentItem.size} mb={24} />
              <MediaSummaryItem
                name="ID"
                value={
                  <CopyButton value={currentItem.id}>
                    {({ copied, copy }) => (
                      <UnstyledButton className={classes.mediaLink} onClick={copy}>
                        {copied ? 'Скопировано' : 'Скопировать ID'}
                      </UnstyledButton>
                    )}
                  </CopyButton>
                }
                mb={24}
              />
              <MediaSummaryItem
                name="URL"
                value={
                  <a className={classes.mediaLink} href={currentItem.url} target="_blank" download>
                    Скачать файл
                  </a>
                }
                mb={24}
              />
              <MediaSummaryItem
                name="Последнее редактирование"
                value={fileDateFormat(currentItem.updatedAt)}
              />
            </>
          )}

          {!currentItem && (
            <>
              <Skeleton height={24} mb={24} />
              <Skeleton height={24} mb={24} />
              <Skeleton height={24} mb={24} />
              <Skeleton height={24} mb={24} />
              <Skeleton height={24} />
            </>
          )}
        </Box>
      </Box>
    </ScrollArea.Autosize>
  );
};

export default MediaSummary;
