import { FC } from 'react';
import loadable from '@loadable/component';
import { IconCircle, IconLoader } from '@tabler/icons-react';

const AsyncIcon = loadable(() => import(`@tabler/icons-react`), {
  resolveComponent: (components: any, props: Record<string, string>) => components[props.name],
  fallback: <IconLoader />,
});

interface DynamicIconProps {
  name: string;
}

/**
 * Renders a dynamic icon component based on the given name.
 *
 * @param {DynamicIconProps} props - The props object containing the name of the icon.
 * @return {JSX.Element} The rendered dynamic icon component.
 */
const DynamicIcon: FC<DynamicIconProps> = ({ name }) => {
  const iconNameRegex = /^Icon[A-Za-z0-9]+$/;

  const isValidIconName = iconNameRegex.test(name);
  const IconComponent = isValidIconName ? AsyncIcon : IconCircle;

  return <IconComponent name={name} />;
};

export default DynamicIcon;
