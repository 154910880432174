import { FC, useEffect, useState } from 'react';
import { ActionIcon, Box, ColorInput, ColorInputProps, Group, Input } from '@mantine/core';
import { useCounter } from '@mantine/hooks';
import { IconMinus, IconPlus } from '@tabler/icons-react';
import debounce from 'lodash/debounce';

interface ColorsInputProps extends Omit<ColorInputProps, 'value' | 'onChange'> {
  id: string;
  onChange: (colors: string[]) => void;
  value?: string[];
}

const ColorsInput: FC<ColorsInputProps> = ({ id, onChange, error, ...props }) => {
  const [count, handlersCount] = useCounter(0);
  const [currentId, setCurrentId] = useState<string>('');
  const [inputState, setInputState] = useState<
    { id: string; el: (key: React.Key) => React.JSX.Element }[]
  >([]);

  const isHexColor = (colorString: string) => {
    const pattern = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
    return pattern.test(colorString);
  };

  const handlerChangeInput = () => {
    const inputs = document.getElementById(id)?.getElementsByTagName('input');
    let values: string[] = [];

    if (inputs) {
      const arr = Array.from(inputs);

      arr.forEach((item) => {
        if (item.value && item.value.length > 0) {
          if (isHexColor(item.value)) values.push(item.value);
        }
      });
    }

    if (onChange) onChange(values);
  };

  const getColorInput = (identificator: string, color?: string) => {
    return {
      id: identificator,
      el: (key: React.Key) => (
        <Group key={key} wrap="nowrap" align="flex-end" mt={12}>
          <ColorInput
            placeholder={props.placeholder}
            w={'100%'}
            defaultValue={color}
            onChange={debounce(handlerChangeInput, 300)}
          />
          <ActionIcon
            variant="outline"
            size={36}
            color="gray.5"
            onClick={() => setCurrentId(identificator)}
          >
            <IconMinus />
          </ActionIcon>
        </Group>
      ),
    };
  };

  const addInput = (index: number, value?: string) => {
    setInputState((prev) => [...prev, getColorInput(`colorInput_${id}_${index}`, value)]);
    handlersCount.increment();
  };

  // Удаление элемента
  useEffect(() => {
    if (currentId && currentId.length > 0) {
      setInputState([...inputState.filter((item) => item.id !== currentId)]);
    }
  }, [currentId]);

  useEffect(() => {
    handlerChangeInput();
  }, [inputState]);

  // Добавляем цвета пришедшие с бека в разметку
  useEffect(() => {
    if (props.value && props.value.length > 1) {
      props.value.slice(1).map((el, i) => addInput(i, el));
    }
  }, []);

  return (
    <Box mb={props.mb} id={id}>
      <Group wrap="nowrap" align="flex-end">
        <ColorInput
          {...props}
          w={'100%'}
          defaultValue={props.value ? props.value[0] : undefined}
          value={undefined}
          onChange={debounce(handlerChangeInput, 300)}
          mb={0}
        />
        <ActionIcon variant="outline" size={36} color="gray.5" onClick={() => addInput(count)}>
          <IconPlus />
        </ActionIcon>
      </Group>

      {inputState.map((input) => input.el(input.id))}

      <Input.Error mt={5}>{error}</Input.Error>
    </Box>
  );
};

export default ColorsInput;
