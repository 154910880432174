import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ActionIcon, Group } from '@mantine/core';
import { IconAlignRight, IconFileDescription, IconPencil, IconTrash } from '@tabler/icons-react';

import { SortOrderEnum, TConfiguration } from '@/types';

import { useQueryParams } from '@/hooks/useQueryParams';

import { AppDispatch } from '@/store';
import {
  setEditConfigurationModalOpen,
  setOpenDelConfigurationModal,
} from '@/store/slices/configurationPlugin/configuration';
import {
  fetchGetConfigurationOptionsAction,
  setFillConfigurationOptionsModalOpen,
} from '@/store/slices/configurationPlugin/configurationOptions';
import { setCurrentConfiguration } from '@/store/slices/configurationPlugin/configurations';
import { selectCurrentService, setServiceChanged } from '@/store/slices/service/service';

const ConfigurationsTableButtons: FC<TConfiguration> = ({ ...props }) => {
  const dispatch: AppDispatch = useDispatch();
  const service = useSelector(selectCurrentService);
  const { currentLimit } = useQueryParams();

  const fillHandler = () => {
    dispatch(setCurrentConfiguration({ ...props }));
    dispatch(setFillConfigurationOptionsModalOpen(true));

    if (service) {
      dispatch(
        fetchGetConfigurationOptionsAction({
          id: props.id,
          params: {
            order: SortOrderEnum.ASC,
            sort: 'sort',
            limit: 100 || currentLimit,
            offset: 0,
          },
        })
      );
    }
  };

  const editHandler = () => {
    dispatch(setCurrentConfiguration({ ...props }));
    dispatch(setEditConfigurationModalOpen(true));
  };

  const delHandler = () => {
    dispatch(setCurrentConfiguration({ ...props }));
    dispatch(setOpenDelConfigurationModal(true));
  };

  return (
    <Group justify={'right'} wrap="nowrap">
      <ActionIcon
        variant="subtle"
        color="gray"
        title="Опции"
        component={Link}
        to={`${props.id}`}
        onClick={() => dispatch(setServiceChanged(false))}
      >
        <IconAlignRight size={20} color="gray" />
      </ActionIcon>

      <ActionIcon
        variant="subtle"
        color="gray"
        name="Заполнить"
        title="Заполнить"
        onClick={() => fillHandler()}
      >
        <IconFileDescription size={20} color="gray" />
      </ActionIcon>

      <ActionIcon
        variant="subtle"
        color="gray"
        name="Изменить"
        title="Изменить"
        onClick={() => editHandler()}
      >
        <IconPencil size={20} color="gray" />
      </ActionIcon>

      <ActionIcon
        variant="subtle"
        color="gray"
        name="Удалить"
        title="Удалить"
        onClick={() => delHandler()}
      >
        <IconTrash size={20} color="gray" />
      </ActionIcon>
    </Group>
  );
};

export default ConfigurationsTableButtons;
