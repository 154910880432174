import { Box, Checkbox, ColorInput, NumberInput, Textarea, TextInput, Title } from '@mantine/core';
import { DatePickerInput, DateTimePicker, TimeInput } from '@mantine/dates';
import { createStyles } from '@mantine/emotion';
import { UseFormReturnType } from '@mantine/form';
import { IconCalendar, IconClock, IconMail, IconSearch } from '@tabler/icons-react';

import {
  FormFieldTypeEnum,
  TFormField,
  TValidationField,
} from '@/types/modelsPlugin/modelsPlugin_v2';

import ColorsInput from '@/ui/organisms/ColorsInput/ColorsInput';
import EditorJSComponent from '@/ui/organisms/EditorJSComponent/EditorJSComponent';
import FileInput from '@/ui/organisms/FileInput/FileInput';
import FloatInput from '@/ui/organisms/FloatInput/FloatInput';
import SearchableDnD from '@/ui/organisms/SearchableDnD/SearchableDnD';
import SearchableMultiSelect from '@/ui/organisms/SearchableMultiSelect/SearchableMultiSelect';
import SearchableSelectV2 from '@/ui/organisms/SearchableSelectV2/SearchableSelectV2';
import TextEditor from '@/ui/organisms/TextEditor/TextEditor';

import PhoneInput from '@/ui/molecules/PhoneInput/PhoneInput';
import RatingInput from '@/ui/molecules/RatingInput/RatingInput';

import { addTransliteration } from './addTransliteration';
import { getValidationProp } from './getValidationProp';
import { isRequired } from './isRequired';
import { handleKeyDown, handlePaste } from './numberInputsListeners';

const useStyles = createStyles({
  hidden: {
    display: 'none',
  },
});

export const GetFieldsView = (
  form: UseFormReturnType<
    {
      fields: TFormField[];
    },
    (values: { fields: TFormField[] }) => {
      fields: TFormField[];
    }
  >,
  validation: Record<string, TValidationField> | undefined,
  type: 'new' | 'edit'
) => {
  const { classes, cx } = useStyles();
  const fieldsView = form.values.fields.map((item, index) => {
    return (
      <Box key={item.code}>
        {item.block && (
          <Title order={4} mb={12}>
            {form.values.fields[index - 1].block !== item.block ? item.block : null}
          </Title>
        )}

        {item.type === FormFieldTypeEnum.CHECKBOX && (
          <Checkbox
            id={`fields.${index}.value`}
            mb={24}
            className={cx({ [classes.hidden]: item.hidden })}
            label={item.name}
            hidden={item.hidden}
            disabled={item.disabled}
            required={isRequired(validation, item.code)}
            {...form.getInputProps(`fields.${index}.checked`, { type: 'checkbox' })}
          />
        )}

        {item.type === FormFieldTypeEnum.INTINPUT && (
          <NumberInput
            id={`fields.${index}.value`}
            mb={24}
            className={cx({ [classes.hidden]: item.hidden })}
            label={item.name}
            placeholder={item.name}
            hidden={item.hidden}
            disabled={item.disabled}
            required={isRequired(validation, item.code)}
            {...form.getInputProps(`fields.${index}.value`)}
          />
        )}

        {item.type === FormFieldTypeEnum.UINTINPUT && (
          <NumberInput
            id={`fields.${index}.value`}
            mb={24}
            className={cx({ [classes.hidden]: item.hidden })}
            label={item.name}
            placeholder={item.name}
            hidden={item.hidden}
            disabled={item.disabled}
            min={0}
            required={isRequired(validation, item.code)}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            {...form.getInputProps(`fields.${index}.value`)}
          />
        )}

        {item.type === FormFieldTypeEnum.FLOATINPUT && (
          <FloatInput
            id={`fields.${index}.value`}
            mb={24}
            className={cx({ [classes.hidden]: item.hidden })}
            label={item.name}
            placeholder={item.name}
            hidden={item.hidden}
            disabled={item.disabled}
            required={isRequired(validation, item.code)}
            {...form.getInputProps(`fields.${index}.value`)}
          />
        )}

        {item.type === FormFieldTypeEnum.RATING && (
          <RatingInput
            id={`fields.${index}.value`}
            mb={24}
            className={cx({ [classes.hidden]: item.hidden })}
            label={item.name}
            ratingProps={{ ...form.getInputProps(`fields.${index}.value`) }}
          />
        )}

        {item.type === FormFieldTypeEnum.SELECT && (
          <>
            {item.sortable && item.multiple ? (
              <SearchableDnD
                id={`fields.${index}.value`}
                mb={24}
                leftSection={<IconSearch size="1.1rem" />}
                label={item.name}
                placeholder={item.name}
                hidden={item.hidden}
                disabled={item.disabled}
                field={item}
                max={getValidationProp(item.code, 'max', validation) as number}
                required={isRequired(validation, item.code)}
                {...form.getInputProps(`fields.${index}.value`)}
              />
            ) : item.multiple ? (
              <SearchableMultiSelect
                id={`fields.${index}.value`}
                mb={24}
                leftSection={<IconSearch size="1.1rem" />}
                label={item.name}
                placeholder={item.name}
                hidden={item.hidden}
                disabled={item.disabled}
                field={item}
                required={isRequired(validation, item.code)}
                {...form.getInputProps(`fields.${index}.value`)}
              />
            ) : (
              <SearchableSelectV2
                id={`fields.${index}.value`}
                mb={24}
                leftSection={<IconSearch size="1.1rem" />}
                label={item.name}
                placeholder={item.name}
                hidden={item.hidden}
                disabled={item.disabled}
                field={item}
                required={isRequired(validation, item.code)}
                {...form.getInputProps(`fields.${index}.value`)}
              />
            )}
          </>
        )}

        {item.type === FormFieldTypeEnum.KLADRSELECT && (
          <SearchableMultiSelect
            id={`fields.${index}.value`}
            mb={24}
            leftSection={<IconSearch size="1.1rem" />}
            label={item.name}
            placeholder={item.name}
            hidden={item.hidden}
            disabled={item.disabled}
            field={item}
            required={isRequired(validation, item.code)}
            {...form.getInputProps(`fields.${index}.value`)}
          />
        )}

        {item.type === FormFieldTypeEnum.DATEPICKERINPUT && (
          <DatePickerInput
            id={`fields.${index}.value`}
            mb={24}
            locale="ru"
            clearable
            className={cx({ [classes.hidden]: item.hidden })}
            leftSection={<IconCalendar size="1.1rem" />}
            label={item.name}
            placeholder={item.name}
            hidden={item.hidden}
            disabled={item.disabled}
            required={isRequired(validation, item.code)}
            {...form.getInputProps(`fields.${index}.value`)}
          />
        )}

        {item.type === FormFieldTypeEnum.DATETIMEPICKER && (
          <DateTimePicker
            id={`fields.${index}.value`}
            mb={24}
            locale="ru"
            clearable
            className={cx({ [classes.hidden]: item.hidden })}
            leftSection={<IconCalendar size="1.1rem" />}
            label={item.name}
            placeholder={item.name}
            hidden={item.hidden}
            disabled={item.disabled}
            valueFormat="DD.MM.YYYY HH:mm"
            popoverProps={{ withinPortal: true }}
            required={isRequired(validation, item.code)}
            {...form.getInputProps(`fields.${index}.value`)}
          />
        )}

        {item.type === FormFieldTypeEnum.TEXTAREA && (
          <Textarea
            id={`fields.${index}.value`}
            mb={24}
            autosize
            minRows={4}
            className={cx({ [classes.hidden]: item.hidden })}
            label={item.name}
            placeholder={item.name}
            hidden={item.hidden}
            disabled={item.disabled}
            required={isRequired(validation, item.code)}
            {...form.getInputProps(`fields.${index}.value`)}
          />
        )}

        {item.type === FormFieldTypeEnum.TEXTINPUT && (
          <TextInput
            id={`fields.${index}.value`}
            mb={24}
            className={cx({ [classes.hidden]: item.hidden })}
            label={item.name}
            placeholder={item.name}
            hidden={item.hidden}
            disabled={item.disabled}
            required={isRequired(validation, item.code)}
            onInput={addTransliteration(index, form, type, item.extra)}
            {...form.getInputProps(`fields.${index}.value`)}
          />
        )}

        {item.type === FormFieldTypeEnum.WYSIWYG && (
          <TextEditor
            id={`fields.${index}.value`}
            mih={220}
            mb={24}
            label={item.name}
            error={form.getInputProps(`fields.${index}.value`).error}
            required={isRequired(validation, item.code)}
            content={item.value as string}
            onChange={(value) => form.setFieldValue(`fields.${index}.value`, value)}
          />
        )}

        {item.type === FormFieldTypeEnum.EDITORJS && (
          <EditorJSComponent
            id={`fields.${index}.value`}
            mb={24}
            label={item.name}
            name={item.name}
            required={isRequired(validation, item.code)}
            error={form.errors[`fields.${index}.value`]}
            useForm={form}
            formField={item}
            fieldName={`fields.${index}.value`}
          />
        )}

        {item.type === FormFieldTypeEnum.MEDIA && (
          <FileInput
            id={`fields.${index}.value`}
            mb={24}
            label={item.name}
            required={isRequired(validation, item.code)}
            multiple={item.multiple}
            folderId={item.extra?.folderId}
            {...form.getInputProps(`fields.${index}.value`)}
          />
        )}

        {item.type === FormFieldTypeEnum.MULTIMEDIA && (
          <FileInput
            id={`fields.${index}.value`}
            mb={24}
            label={item.name}
            required={isRequired(validation, item.code)}
            multiple={item.multiple}
            folderId={item.extra?.folderId}
            multimedia
            {...form.getInputProps(`fields.${index}.value`)}
          />
        )}

        {item.type === FormFieldTypeEnum.PHONEINPUT && (
          <PhoneInput
            className={cx({ [classes.hidden]: item.hidden })}
            label={item.name}
            error={form.errors[`fields.${index}.value`]}
            id={`fields.${index}.value`}
            placeholder={item.name}
            hidden={item.hidden}
            disabled={item.disabled}
            required={isRequired(validation, item.code)}
            {...form.getInputProps(`fields.${index}.value`)}
          />
        )}

        {item.type === FormFieldTypeEnum.EMAILINPUT && (
          <TextInput
            id={`fields.${index}.value`}
            mb={24}
            className={cx({ [classes.hidden]: item.hidden })}
            label={item.name}
            placeholder={item.name}
            hidden={item.hidden}
            disabled={item.disabled}
            type="email"
            leftSection={<IconMail size="1.1rem" />}
            required={isRequired(validation, item.code)}
            {...form.getInputProps(`fields.${index}.value`)}
          />
        )}

        {item.type === FormFieldTypeEnum.COLORSINPUT && (
          <>
            {item.multiple ? (
              <ColorsInput
                id={`fields.${index}.value`}
                mb={24}
                className={cx({ [classes.hidden]: item.hidden })}
                label={item.name}
                placeholder={item.name}
                hidden={item.hidden}
                disabled={item.disabled}
                required={isRequired(validation, item.code)}
                {...form.getInputProps(`fields.${index}.value`)}
              />
            ) : (
              <ColorInput
                id={`fields.${index}.value`}
                mb={24}
                className={cx({ [classes.hidden]: item.hidden })}
                label={item.name}
                placeholder={item.name}
                hidden={item.hidden}
                disabled={item.disabled}
                required={isRequired(validation, item.code)}
                {...form.getInputProps(`fields.${index}.value`)}
              />
            )}
          </>
        )}

        {item.type === FormFieldTypeEnum.TIMEPICKERINPUT && (
          <TimeInput
            id={`fields.${index}.value`}
            mb={24}
            withSeconds
            leftSection={<IconClock size="1rem" stroke={1.5} />}
            className={cx({ [classes.hidden]: item.hidden })}
            label={item.name}
            placeholder={item.name}
            hidden={item.hidden}
            disabled={item.disabled}
            required={isRequired(validation, item.code)}
            {...form.getInputProps(`fields.${index}.value`)}
          />
        )}
      </Box>
    );
  });

  return fieldsView;
};
