import { FC } from 'react';
interface VideoPreviewProps {
  url: string;
}
const VideoPreview: FC<VideoPreviewProps> = ({ url }) => {
  const extension = url.split('.').slice(-1).toString();

  return (
    <video src={url} controls style={{ height: '100%', width: '100%' }}>
      <source src={url} type={`video/${extension}`} />
    </video>
  );
};

export default VideoPreview;
