import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  bordersTop: {
    border: `1px solid ${theme.colors.gray[3]}`,
    borderWidth: '1px 1px 0 1px',
    borderRadius: '4px 4px 0 0',
  },
  bordersBottom: {
    border: `1px solid ${theme.colors.gray[3]}`,
    borderWidth: '0 1px 1px 1px',
    borderRadius: '0 0 4px 4px',
  },
}));
