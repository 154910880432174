import { UseFormReturnType } from '@mantine/form';

import { InputUtilsEnum, TExtraField, TFormField } from '@/types/modelsPlugin/modelsPlugin_v2';

import { transliteration } from '@/utils/transliteration/transliteration';

import { getFieldIndex } from './getFieldIndex';

export const addTransliteration = (
  currentInputIndex: number,
  formObj: UseFormReturnType<{
    fields: TFormField[];
  }>,
  type: 'new' | 'edit',
  extra?: TExtraField
): React.FormEventHandler<HTMLInputElement> | undefined => {
  if (!extra || !extra.utils) return undefined;
  const { utils } = extra;

  for (let index = 0; index < utils.length; index++) {
    switch (utils[index].code) {
      case InputUtilsEnum.SLUGIFY:
        return type !== 'edit'
          ? (el: React.FormEvent<HTMLInputElement>) => {
              const currentInputValue = formObj.getInputProps(
                `fields.${currentInputIndex}.value`
              ).value;

              const targetInputValue = formObj.getInputProps(
                `fields.${getFieldIndex(formObj, utils[index].field)}.value`
              ).value;

              const synced =
                transliteration(currentInputValue, { onlyLower: true }) === targetInputValue;

              if (!synced) {
                return;
              }

              formObj
                .getInputProps(`fields.${getFieldIndex(formObj, utils[index].field)}.value`)
                .onChange(transliteration(el.currentTarget.value, { onlyLower: true }));
            }
          : undefined;
      default:
        break;
    }
  }

  return undefined;
};
