/* eslint-disable @typescript-eslint/no-unused-vars */
import * as YupLocale from 'yup';

import { yupCustomLocale } from '@/constants/common/yupCustomLocale';

declare module 'yup' {
  interface ArraySchema<
    TIn extends any[] | null | undefined,
    TContext,
    TDefault = undefined,
    TFlags extends YupLocale.Flags = '',
  > {
    unique(message: string): this;
  }
}

YupLocale.setLocale(yupCustomLocale);

export default YupLocale;
