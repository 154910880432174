import { FC } from 'react';
import { Button, Group, Modal, ModalProps, Text, Title } from '@mantine/core';

interface ModalConfirmProps extends ModalProps {
  title: string;
  text: string;
  confirmHandler: () => void;
  blocked?: boolean;
}

const ModalConfirm: FC<ModalConfirmProps> = ({
  title,
  text,
  confirmHandler,
  blocked,
  ...props
}) => {
  return (
    <Modal centered size={'xs'} {...props}>
      <Title order={3} mb={20} ta={'center'}>
        {title}
      </Title>
      <Text size="xs" mb={30} ta={'center'}>
        {text}
      </Text>

      <Group justify={'center'} grow>
        <Button onClick={confirmHandler} loading={blocked}>
          Да
        </Button>
        <Button variant={'outline'} color="red" onClick={props.onClose} disabled={blocked}>
          Нет
        </Button>
      </Group>
    </Modal>
  );
};

export default ModalConfirm;
