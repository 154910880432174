import { TModelTableElement, TObject } from '@/types/modelsPlugin/modelsPlugin_v2';

import { getDataFormat } from './getDataFormat';

export const generateElements = (data: TObject[] | null, modelViews: TModelTableElement[]) => {
  const elements: Record<string, string>[] = [];

  if (data) {
    data.map((el) => {
      const element: Record<string, string> = {};

      for (const key in el) {
        if (key === 'region') {
          if (el[key]) {
            element['region.name'] = (el[key] as { name: string; id: string }).name;
          } else {
            element['region.name'] = '';
          }
        } else {
          const modelConfig = modelViews.filter((model) => model.code === key);
          const isTime = modelConfig.length > 0 ? modelConfig[0].isTime : false;

          element[key] = getDataFormat(el[key], isTime);
        }
      }

      elements.push(element);
    });
  }

  return elements;
};
