export enum ModelExportStatusEnum {
  PENDING = 'pending',
  ERROR = 'error',
  SUCCEED = 'succeed',
}

export enum ViewTypeEnum {
  STRING = 'string',
  TEXT = 'text',
  HTML = 'html',
  INT = 'int',
  NUMERIC = 'numeric',
  FLOAT = 'float',
  CHECKBOX = 'checkbox',
  DATE = 'date',
  DATETIME = 'datetime',
  TIME = 'time',
  MEDIA = 'media',
  MODEL = 'model',
  MODELSCOLLECTION = 'modelsCollection',
  KEYVALUES = 'keyValues',
  SLICEOFSTRINGS = 'sliceOfStrings',
  SLICEOFUUID = 'sliceOfUuid',
  EMAIL = 'email',
  PHONE = 'phone',
}
