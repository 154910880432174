import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Center, Grid, PasswordInput, TextInput, Title } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useDocumentTitle } from '@mantine/hooks';

import { IAuthParams } from '@/types/common/auth';

import { AUTH_VALIDATION } from '@/constants/common/authValidation';
import { TITLE_AUTH } from '@/constants/common/titles';

import { AppDispatch } from '@/store';
import { fetchAuth, fetchingGetConfiguration, tokenSelector } from '@/store/slices/auth/auth';

const AuthPage: FC = () => {
  useDocumentTitle(TITLE_AUTH);

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(tokenSelector);
  const fetching = useSelector(fetchingGetConfiguration);

  const data = {
    grant_type: process.env.GRANT_TYPE,
    client_id: process.env.CLIENT_ID,
    client_secret: process.env.CLIENT_SECRET,
  };

  const form = useForm({
    initialValues: {
      username: '',
      password: '',
    },
    validate: yupResolver(AUTH_VALIDATION),
  });

  const handleSubmit = (formData: IAuthParams) => {
    dispatch(fetchAuth({ ...data, ...formData }));
  };

  useEffect(() => {
    if (token) {
      navigate('/');
    }
  }, [token]);

  return (
    <Grid gutter={0}>
      <Grid.Col span={6} bg={'center / cover no-repeat url(../images/auth.png)'}></Grid.Col>

      <Grid.Col span={6}>
        <Center mih={'100vh'}>
          <form style={{ width: '300px' }} onSubmit={form.onSubmit(handleSubmit)}>
            <Title order={1} mb={32}>
              Войти в систему
            </Title>

            <TextInput placeholder="Логин" mb={12} {...form.getInputProps('username')} />

            <PasswordInput placeholder="Пароль" mb={40} {...form.getInputProps('password')} />

            <Button fullWidth type={'submit'} loading={fetching}>
              Войти
            </Button>
          </form>
        </Center>
      </Grid.Col>
    </Grid>
  );
};

export default AuthPage;
