import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { emotionTransform, MantineEmotionProvider } from '@mantine/emotion';
import { Notifications } from '@mantine/notifications';

import { extraAppShell } from './assets/styles/extraAppShell';
import { extraColors } from './assets/styles/extraColors';
import { extraModal } from './assets/styles/extraModal';
import { useAuthToken } from './hooks/useAuthToken';
import { useWSSConnection } from './ui/pages/ReviewsContainer/utils/useWSSConnection';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/tiptap/styles.css';
import '@mantine/notifications/styles.css';

const App: FC = () => {
  useAuthToken();
  useWSSConnection();

  return (
    <MantineProvider
      stylesTransform={emotionTransform}
      theme={{
        components: {
          ...extraAppShell,
          ...extraModal,
        },
        colors: { ...extraColors },
      }}
    >
      <MantineEmotionProvider>
        <Notifications position="top-right" limit={5} />
        <Outlet />
      </MantineEmotionProvider>
    </MantineProvider>
  );
};

export default App;
