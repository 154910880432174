import { TTableConfig } from '@/types';

export const TABLE_MAILS_TEMPLATES: TTableConfig[] = [
  {
    code: 'subject',
    name: 'Тема',
  },
  {
    code: 'sender',
    name: 'Отправитель',
  },
  {
    code: 'recipients',
    name: 'Получатель',
  },
];
