import { FC, useState } from 'react';
import { Checkbox, Flex, Image, Skeleton, Table, Text, ThemeIcon } from '@mantine/core';
import { IconPalette } from '@tabler/icons-react';

import { TTableConfig } from '@/types';

import { ItemsPreview } from '../components/ItemsPreview/ItemsPreview';

import { getTableTypeCol } from './getTableTypeCol';
import { includeRow } from './includeRow';
import { splitWord } from './splitWord';

type ElementToWidth = {
  [key: string]: number;
};

export const GetTableRows = (
  config: TTableConfig[],
  elements: Record<string, any>[] | null,
  Buttons?: FC<any>,
  selectable?: boolean,
  selection?: Record<string, string>[],
  changeHandler?: (item: Record<string, string>) => void,
  sortableKeys?: string[],
  onRowDoubleClick?: (id: string) => void
) => {
  const [mediaLoading, setMediaLoading] = useState(true);
  if (!elements) return null;
  const elementToWidth: ElementToWidth = { date: 150, title: 226 };

  return elements.map((element, i) => (
    <Table.Tr
      key={element.id || `${element.code}${i}`}
      onDoubleClick={() => onRowDoubleClick?.(element.id || element.code)}
      style={{ cursor: 'pointer' }}
    >
      {selectable && selection && changeHandler && (
        <Table.Td>
          <Checkbox
            checked={includeRow(selection, element)}
            onChange={() => changeHandler(element)}
          />
        </Table.Td>
      )}

      {config.map((el) =>
        Object.keys(element).map((key) => {
          if (el.code === key) {
            if (el.code === 'type') {
              return (
                <Table.Td key={`${element.id}${el.code}`}>{getTableTypeCol(element)}</Table.Td>
              );
            } else if (el.code === 'photo' || el.code === 'video') {
              return (
                <Table.Td
                  key={`${element.id}${el.code}`}
                  style={{
                    paddingLeft: sortableKeys?.includes(key) ? 40 : undefined,
                    maxWidth: el.maxWidth !== undefined ? el.maxWidth : 256,
                    minWidth: el.minWidth !== undefined ? el.minWidth : 'auto',
                  }}
                >
                  {element[key] !== null && (
                    <Flex justify={el.align}>
                      <Skeleton visible={mediaLoading}>
                        <Image
                          onLoad={() => setMediaLoading(false)}
                          onError={() => setMediaLoading(false)}
                          display="inline"
                          src={element[key]}
                          fallbackSrc={`${el.code === 'photo' ? '/images/icons/photo.svg' : '/images/icons/movie.svg'}`}
                          width={30}
                          height={30}
                          fit="contain"
                        />
                      </Skeleton>
                    </Flex>
                  )}
                </Table.Td>
              );
            } else if (el.code === 'link') {
              return (
                <Table.Td
                  key={`${element.id}${el.code}`}
                  style={{
                    paddingLeft: sortableKeys?.includes(key) ? 40 : undefined,
                    maxWidth: el.maxWidth !== undefined ? el.maxWidth : 256,
                    minWidth: el.minWidth !== undefined ? el.minWidth : 'auto',
                  }}
                >
                  <Flex justify={el.align}>
                    <a href={element[key]} target="_blank" title={element[key]}>
                      Ссылка
                    </a>
                  </Flex>
                </Table.Td>
              );
            } else if (el.code === 'colors') {
              return (
                <Table.Td key={`${element.id}${el.code}`}>
                  {element[key] !== 'null' && element[key].length > 0 && (
                    <Flex align={'center'}>
                      {element[key]
                        .split(',')
                        .slice(0, 4)
                        .map((item: string) => (
                          <ThemeIcon
                            key={item}
                            color={item}
                            size={'sm'}
                            variant="filled"
                            mr={3}
                            radius={0}
                            sx={(theme) => ({ borderColor: theme.colors.gray[3] })}
                          >
                            <IconPalette size={'1rem'} color={item} />
                          </ThemeIcon>
                        ))}
                      {element[key].split(',').length > 4 && (
                        <ItemsPreview items={element[key].split(',')} type="colors" />
                      )}
                    </Flex>
                  )}
                </Table.Td>
              );
            } else if (el.code === 'recipients') {
              return (
                <Table.Td key={`${element.id}${el.code}`}>
                  {element[key] !== 'null' && (
                    <Flex align={'center'} gap={'10px'}>
                      {element[key].slice(0, 1).map((item: string) => (
                        <Text lineClamp={1} key={item}>
                          {element[key] !== 'null' ? item : ''}
                        </Text>
                      ))}
                      {element[key].length > 1 && (
                        <ItemsPreview items={element[key]} type="strings" />
                      )}
                    </Flex>
                  )}
                </Table.Td>
              );
            } else if (el.code === 'kladrIds') {
              return (
                <Table.Td key={`${element.id}${el.code}`}>
                  {element[key] !== 'null' && (
                    <Flex align={'center'} gap={'10px'}>
                      {element[key]
                        .split(',')
                        .slice(0, 1)
                        .map((item: string) => (
                          <Text lineClamp={1} key={item}>
                            {element[key] !== 'null' ? item : ''}
                          </Text>
                        ))}
                      {element[key].split(',').length > 1 && (
                        <ItemsPreview items={element[key].split(',')} type="strings" />
                      )}
                    </Flex>
                  )}
                </Table.Td>
              );
            } else if (el.code === 'contactEmail') {
              return (
                <Table.Td
                  key={`${element.id}${el.code}`}
                  style={{
                    paddingLeft: sortableKeys?.includes(key) ? 40 : undefined,
                    maxWidth: el.maxWidth !== undefined ? el.maxWidth : 256,
                    minWidth: el.minWidth !== undefined ? el.minWidth : 'auto',
                  }}
                >
                  <Flex justify={el.align}>
                    <Text lineClamp={1}>
                      {element[key] !== 'null' ? splitWord(element?.[key], 35) : ''}
                    </Text>
                  </Flex>
                </Table.Td>
              );
            } else {
              if (el.trimming === false) {
                return (
                  <Table.Td
                    key={`${element.id}${el.code}`}
                    style={{
                      paddingLeft: sortableKeys?.includes(key) ? 40 : undefined,
                      maxWidth: el.maxWidth !== undefined ? el.maxWidth : 256,
                      minWidth: el.minWidth !== undefined ? el.minWidth : 'auto',
                    }}
                  >
                    <Flex justify={el.align} sx={{ whiteSpace: 'nowrap' }}>
                      {element[key]}
                    </Flex>
                  </Table.Td>
                );
              }
              return (
                <Table.Td
                  key={`${element.id}${el.code}`}
                  style={{
                    paddingLeft: sortableKeys?.includes(key) ? 40 : undefined,
                    maxWidth: el.maxWidth !== undefined ? el.maxWidth : 256,
                    minWidth:
                      el.minWidth !== undefined ? el.minWidth : elementToWidth[el.code] || 'auto',
                  }}
                >
                  <Flex justify={el.align}>
                    <Text lineClamp={1}>{element[key] !== 'null' ? element[key] : ''}</Text>
                  </Flex>
                </Table.Td>
              );
            }
          }
          return null;
        })
      )}
      {Buttons && (
        <Table.Td>
          <Buttons {...element} />
        </Table.Td>
      )}
    </Table.Tr>
  ));
};
