import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Group, Text } from '@mantine/core';

import { AppDispatch } from '@/store';
import {
  selectDelModelElementModal,
  setOpenDelModelElementModal,
} from '@/store/slices/modelsPlugin/model';

import ModalConfirm from '../../molecules/ModalConfirm/ModalConfirm';

interface SelectedCounterProps {
  count: number | null;
  buttonText?: string;
  callback?: () => void;
  loading?: boolean;
}

const SelectedCounter: FC<SelectedCounterProps> = ({ count, loading, buttonText, callback }) => {
  const dispatch = useDispatch<AppDispatch>();
  const isModalOpen = useSelector(selectDelModelElementModal);

  const handleСonfirm = () => {
    if (callback) callback();
  };

  const handleCloseModal = () => {
    dispatch(setOpenDelModelElementModal(false));
  };

  const handleOpenModal = () => {
    dispatch(setOpenDelModelElementModal(true));
  };

  return (
    <Group gap="xs">
      <Text c="dimmed">Выбрано:</Text>
      <Text>{count ?? 0}</Text>

      {buttonText && (
        <Button variant="outline" color="red" size="xs" onClick={handleOpenModal}>
          {buttonText}
        </Button>
      )}

      <ModalConfirm
        title="Вы уверены?"
        text="Восстановить данные после удаления не получится"
        blocked={loading}
        opened={isModalOpen}
        onClose={handleCloseModal}
        confirmHandler={handleСonfirm}
      />
    </Group>
  );
};

export default SelectedCounter;
