// ROOT REDUCER EXAMPLE

// 1. Import your reducers from entities

// import cartReducer from './entities/cart/reducers';

import { combineReducers } from 'redux';

import authReducer from '@/store/slices/auth/auth';
import configurationReducer from '@/store/slices/configurationPlugin/configuration';
import configurationOptionReducer from '@/store/slices/configurationPlugin/configurationOption';
import configurationOptionsReducer from '@/store/slices/configurationPlugin/configurationOptions';
import configurationsReducer from '@/store/slices/configurationPlugin/configurations';
import mailsTemplatesReducer from '@/store/slices/mailsTemplatesPlugin/mailsTemplates';
import mediaReducer from '@/store/slices/mediaPlugin/media';
import mediaFilesReducer from '@/store/slices/mediaPlugin/mediaFiles';
import mediaFoldersReducer from '@/store/slices/mediaPlugin/mediaFolders';
import menuReducer from '@/store/slices/menuPlugin/menu';
import menuItems from '@/store/slices/menuPlugin/menuItems';
import menusReducer from '@/store/slices/menuPlugin/menus';
import modelReducer from '@/store/slices/modelsPlugin/model';
import modelsReducer from '@/store/slices/modelsPlugin/models';
import reviewsReducer from '@/store/slices/reviewsPlugin/reviews';
import serviceReducer from '@/store/slices/service/service';

// 2. Define reducers into common object
const rootReducer = combineReducers({
  configurations: configurationsReducer,
  configuration: configurationReducer,
  configurationOptions: configurationOptionsReducer,
  configurationOption: configurationOptionReducer,
  mailsTemplates: mailsTemplatesReducer,
  media: mediaReducer,
  mediaFiles: mediaFilesReducer,
  mediaFolders: mediaFoldersReducer,
  models: modelsReducer,
  model: modelReducer,
  service: serviceReducer,
  menu: menuReducer,
  menus: menusReducer,
  menuItems: menuItems,
  auth: authReducer,
  reviews: reviewsReducer,
});

export default rootReducer;
export type IRootReducer = ReturnType<typeof rootReducer>;
