import { Table, Text } from '@mantine/core';

import { SortOrderEnum, TTableConfig } from '@/types';

import TableHeadSortable from '../components/TableHeadSortable/TableHeadSortable';

export const getTableHeaders = (
  config: TTableConfig[],
  buttons?: boolean,
  sortableKeys?: string[],
  onSort?: (code: string) => void,
  reversed?: SortOrderEnum,
  sortBy?: string | null
) => {
  const headers = (
    <>
      {config.map((el, index) => {
        if (sortableKeys && sortableKeys.includes(el.code)) {
          return (
            <TableHeadSortable
              key={`${el.name}${index}`}
              name={el.name}
              onSort={() => onSort && onSort(el.code)}
              order={reversed}
              sorted={sortBy === el.code}
            />
          );
        }

        return (
          <Table.Th key={`${el.name}${index}`}>
            <Text fw={700} sx={{ whiteSpace: 'nowrap' }} size="sm">
              {el.name}
            </Text>
          </Table.Th>
        );
      })}

      {buttons && <Table.Th></Table.Th>}
    </>
  );

  return headers;
};
