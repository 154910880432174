import { cloneDeep } from 'lodash';

// TODO эта функция костыль от нее нужно избавиться
// когда бекенд переделает отзывы по аналогии с моделями
export const transformFiltersToURL = (filters: any) => {
  const result: {
    rating?: number[];
    status?: string[];
    editingDate?: { from?: Date; to?: Date } | (Date | undefined)[];
  } = cloneDeep(filters);

  if (result.editingDate) {
    const convD = [];
    if ((result.editingDate as { from?: Date; to?: Date }).from) {
      convD.push((result.editingDate as { from?: Date; to?: Date }).from);
    }

    if ((result.editingDate as { from?: Date; to?: Date }).to) {
      convD.push((result.editingDate as { from?: Date; to?: Date }).to);
    }

    result.editingDate = convD;
  }

  return result;
};

export const transformURLToFilters = (filters: any) => {
  const result = cloneDeep(filters);

  if (result.editingDate) {
    result.editingDate = {
      from: (result.editingDate[0] as Date) || undefined,
      to: (result.editingDate[1] as Date) || undefined,
    };
  }

  if (result.rating) {
    result.rating = result.rating.map((el: string) => Number(el));
  }

  return result;
};
