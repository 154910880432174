import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Modal, Text } from '@mantine/core';

import { useQueryParams } from '@/hooks/useQueryParams';

import { AppDispatch } from '@/store';
import {
  fetchDelConfigurationOptionAction,
  selectConfigurationOption,
  selectDelConfigurationOptionModal,
  selectEditConfigurationOptionModal,
  selectFetchingDelConfigurationOption,
  setDelConfigurationOptionModalOpened,
  setEditConfigurationOptionModalOpened,
} from '@/store/slices/configurationPlugin/configurationOption';
import { fetchGetConfigurationOptionsAction } from '@/store/slices/configurationPlugin/configurationOptions';
import { selectCurrentService } from '@/store/slices/service/service';

import ModalConfirm from '@/ui/molecules/ModalConfirm/ModalConfirm';

import ConfigurationForm from '../ConfigurationForm/ConfigurationForm';

const ConfigurationTableModals: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const params = useParams();
  const configurationOption = useSelector(selectConfigurationOption);
  const delConfigurationOptionModal = useSelector(selectDelConfigurationOptionModal);
  const editConfigurationOptionModal = useSelector(selectEditConfigurationOptionModal);
  const service = useSelector(selectCurrentService);
  const { currentOrder, currentSort, currentLimit, currentOffset } = useQueryParams();
  const isDeleting = useSelector(selectFetchingDelConfigurationOption);
  const delConfirmHandler = async () => {
    const { confId } = params;
    const optId = configurationOption?.id;

    if (confId && optId && service) {
      await dispatch(fetchDelConfigurationOptionAction({ confId, optId }));
      await dispatch(
        fetchGetConfigurationOptionsAction({
          id: confId,
          params: {
            offset: currentOffset,
            limit: 100 || currentLimit,
            sort: currentSort,
            order: currentOrder,
          },
        })
      );
    }
  };

  return (
    <>
      <ModalConfirm
        title="Вы уверены?"
        text="Восстановить данные после удаления не получится"
        opened={delConfigurationOptionModal}
        onClose={() => dispatch(setDelConfigurationOptionModalOpened(false))}
        confirmHandler={() => delConfirmHandler()}
        blocked={isDeleting}
      />

      <Modal
        centered
        size={'lg'}
        opened={editConfigurationOptionModal}
        onClose={() => dispatch(setEditConfigurationOptionModalOpened(false))}
        title={
          <Box>
            <Text fz={22} fw={700}>
              Изменение настройки
            </Text>
            <Text
              sx={(theme) => ({ fontWeight: 700, color: theme.colors.gray[6] })}
            >{`ID ${configurationOption?.id}`}</Text>
          </Box>
        }
      >
        <ConfigurationForm type="edit" element={configurationOption} />
      </Modal>
    </>
  );
};

export default ConfigurationTableModals;
