import React, { FC } from 'react';

import MenusModalAddDomain from '../MenusModalAddDomain/MenusModalAddDomain';
import MenusModalDelete from '../MenusModalDelete/MenusModalDelete';
import MenusModalEditMenu from '../MenusModalEditMenu/MenusModalEditMenu';
import MenusModalNew from '../MenusModalNew/MenusModalNew';

const MenusTableModals: FC = () => {
  return (
    <>
      <MenusModalNew />
      <MenusModalEditMenu />
      <MenusModalDelete />
      <MenusModalAddDomain />
    </>
  );
};

export default MenusTableModals;
