import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { Button, Flex, Group } from '@mantine/core';
import { isEqual } from 'lodash';
import qs from 'qs';

import { TObject } from '@/types/modelsPlugin/modelsPlugin_v2';

import { useItemsIsEmptyMemo } from '@/hooks/useItemsIsEmptyMemo';
import { useQueryParams } from '@/hooks/useQueryParams';
import { useURLPagination } from '@/hooks/useUrlPagination';

import { AppDispatch } from '@/store';
import {
  fetchDelModelElementsAction,
  fetchGetExportModel,
  fetchGetModelAction,
  fetchGetModelElementsAction,
  fetchPostExportModel,
  selectFetchingDelModelElements,
  selectFetchingGetModelElements,
  selectModelElements,
  selectModelElementsSelected,
  selectModelElementsTotal,
  selectModelName,
  selectModelViewsFilter,
  selectModelViewsList,
  setModelElementsSelected,
  setOpenNewModelElementModal,
} from '@/store/slices/modelsPlugin/model';

import PageBody from '@/ui/templates/Page/components/PageBody/PageBody';
import PageContainer from '@/ui/templates/Page/components/PageContainer/PageContainer';
import PageFooter from '@/ui/templates/Page/components/PageFooter/PageFooter';
import PageHeader from '@/ui/templates/Page/components/PageHeader/PageHeader';
import PagePagination from '@/ui/templates/Page/components/PagePagination/PagePagination';
import Page from '@/ui/templates/Page/Page';

import SelectedCounter from '@/ui/organisms/SelectedCounter/SelectedCounter';
import ShowElements from '@/ui/organisms/ShowElements/ShowElements';
import TableExt from '@/ui/organisms/TableExt/TableExt';

import { generateElements } from './utils/generateElements';
import { getSortableKeys } from './utils/getSortableKeys';
import { ModelContext } from './utils/modelContext';

import ModelFiltersBuilder from './components/ModelFiltersBuilder/ModelFiltersBuilder';
import { removeModelFromFilter } from './components/ModelFiltersBuilder/utils/removeModelFromFilter';
import ModelTableButtons from './components/ModelTableButtons/ModelTableButtons';
import ModelTableModals from './components/ModelTableModals/ModelTableModals';

const ModelContainer: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { modelCode } = useParams<{ modelCode: string }>();
  const [modelExportLoading, setModelExportLoading] = useState<boolean>(false);
  const modelName = useSelector(selectModelName);
  const modelViewsList = useSelector(selectModelViewsList);
  const modelViewsFilter = useSelector(selectModelViewsFilter);
  const modelElements = useSelector(selectModelElements);
  const modelElementsTotal = useSelector(selectModelElementsTotal);
  const fetchingDeleteElements = useSelector(selectFetchingDelModelElements);
  const modelElementsSelected = useSelector(selectModelElementsSelected);
  const modelElementsFetching = useSelector(selectFetchingGetModelElements);
  const { currentPage, setPage, pagesCount, currentOffset, currentLimit, setLimit } =
    useURLPagination(modelElementsTotal);
  const { currentFilter, currentOrder, currentSort } = useQueryParams();

  const modelElementsIsEmpty = useItemsIsEmptyMemo(modelElements);

  const params = {
    ...useMemo(
      () => ({
        limit: currentLimit,
        offset: currentOffset,
      }),
      [currentLimit, currentOffset]
    ),
    order: currentOrder,
    sort: currentSort,
  };

  const breadcrumbs = [
    {
      name: 'Модели',
      url: '/models-v2',
    },
    {
      name: `Элементы модели${modelName ? `: ${modelName}` : ''}`,
    },
  ];

  const reloadHandler = () => {
    if (!modelCode) return;

    dispatch(
      fetchGetModelElementsAction({
        modelCode,
        params,
        data: { filter: currentFilter as TObject },
      })
    );
  };

  const showSelectedCounter = () => !!(modelElementsSelected && modelElementsSelected.length > 0);

  const delElementsHandler = async () => {
    if (modelCode && modelElementsSelected) {
      await dispatch(
        fetchDelModelElementsAction({
          modelCode,
          data: { pKeys: modelElementsSelected.map((el) => el.id) },
        })
      );

      const newFilters = currentFilter;

      modelElementsSelected.forEach((el) => {
        removeModelFromFilter(el, newFilters);
      });

      const newParams = {
        ...params,
        ...newFilters,
      };
      const URLParams = qs.stringify(newParams, { indices: false });

      const paramsChanged = !isEqual(newParams, params);
      if (paramsChanged) {
        setSearchParams(URLParams);
        return;
      }
      reloadHandler();
    }
  };

  const newModalElementHandler = () => {
    dispatch(setOpenNewModelElementModal(true));
  };

  const handleExportModel = (code?: string) => {
    if (code) {
      dispatch(fetchPostExportModel({ modelCode: code })).then(() => {
        setModelExportLoading(true);

        setTimeout(() => {
          dispatch(fetchGetExportModel({ modelCode: code })).finally(() => {
            setModelExportLoading(false);
          });
        }, 2000);
      });
    }
  };

  const getRightButton = (code?: string) => {
    switch (code) {
      case 'subscriptions':
      case 'partnership-requests':
      case 'category-requests':
        return (
          <Button onClick={() => handleExportModel(code)} loading={modelExportLoading}>
            Сохранить файл
          </Button>
        );
      default:
        return <Button onClick={newModalElementHandler}>Новый элемент</Button>;
    }
  };

  // Первая загрузка модели и ее данных
  useEffect(() => {
    if (modelCode) {
      dispatch(fetchGetModelAction(modelCode));
    }
  }, []);

  // Обновление данных при изменении параметров URL
  useEffect(() => {
    reloadHandler();
  }, [searchParams]);

  return (
    <Page>
      <ModelContext.Provider value={reloadHandler}>
        <PageHeader
          title={`Элементы модели${modelName ? `: ${modelName}` : ''}`}
          backLink="/models-v2"
          breadcrumbs={breadcrumbs}
          rightButton={getRightButton(modelCode)}
        />

        <PageBody>
          <Flex align="flex-start" justify="space-between" mb={24} gap={15}>
            <Flex align="stretch" w={'100%'}>
              {modelViewsFilter && modelViewsFilter.formFields && (
                <ModelFiltersBuilder fields={modelViewsFilter.formFields} />
              )}
            </Flex>
            <ShowElements
              pt={modelViewsFilter && modelViewsFilter.formFields ? 23 : 0}
              defaultValue={params.limit}
              changeCallback={setLimit}
            />
          </Flex>

          <PageContainer
            isEmpty={modelElementsIsEmpty}
            loading={modelElementsFetching}
            loaderText={'У модели пока нет элементов'}
          >
            {modelViewsList && (
              <TableExt
                config={modelViewsList}
                buttons={ModelTableButtons}
                rows={generateElements(modelElements, modelViewsList)}
                selectCallback={(values) => dispatch(setModelElementsSelected(values))}
                sortableKeys={getSortableKeys(modelViewsList)}
                selectable
              />
            )}
          </PageContainer>

          <PageFooter>
            <Group justify="apart" grow>
              {showSelectedCounter() && (
                <SelectedCounter
                  count={modelElementsSelected && modelElementsSelected.length}
                  buttonText="Удалить"
                  loading={fetchingDeleteElements}
                  callback={delElementsHandler}
                />
              )}

              <PagePagination total={pagesCount} value={currentPage} onChange={setPage} />
            </Group>
          </PageFooter>
        </PageBody>
        <ModelTableModals />
      </ModelContext.Provider>
    </Page>
  );
};

export default ModelContainer;
