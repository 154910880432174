import { FC } from 'react';
import { ActionIcon, Flex, Popover, ScrollArea, Text, ThemeIcon } from '@mantine/core';
import { IconEye, IconPalette } from '@tabler/icons-react';

interface ItemsPreviewProps {
  items: string[];
  type: 'colors' | 'strings' | 'mails';
}

export const ItemsPreview: FC<ItemsPreviewProps> = ({ items, type }) => {
  return (
    <Popover
      position="top"
      withArrow
      shadow="md"
      styles={{
        dropdown: { maxWidth: '400px' },
      }}
    >
      <Popover.Target>
        <ActionIcon variant="subtle" color="gray">
          <IconEye />
        </ActionIcon>
      </Popover.Target>

      <Popover.Dropdown>
        {type === 'strings' && (
          <ScrollArea.Autosize offsetScrollbars mah={200} scrollbarSize={4}>
            <Flex direction={'column'}>
              {items.map((item) => (
                <Text key={item}>{item}</Text>
              ))}
            </Flex>
          </ScrollArea.Autosize>
        )}
        {type === 'colors' && (
          <>
            {items.map((item) => (
              <ThemeIcon
                key={item}
                color={item}
                size={'sm'}
                variant="filled"
                mr={3}
                radius={0}
                sx={(theme) => ({ borderColor: theme.colors.gray[3] })}
              >
                <IconPalette size={'1rem'} color={item} />
              </ThemeIcon>
            ))}
          </>
        )}
        {type === 'mails' && (
          <ScrollArea.Autosize offsetScrollbars mah={200} scrollbarSize={4}>
            <Flex direction={'column'}>
              {items.map((item) => (
                <Text>{item}</Text>
              ))}
            </Flex>
          </ScrollArea.Autosize>
        )}
      </Popover.Dropdown>
    </Popover>
  );
};
