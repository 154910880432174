import { FC } from 'react';
import InputMask from 'react-input-mask';
import { Input, InputProps } from '@mantine/core';
import { IconPhone } from '@tabler/icons-react';

interface PhoneInputProps extends InputProps {
  label: string;
  placeholder?: string;
  hidden?: boolean;
}

const PhoneInput: FC<PhoneInputProps> = ({
  label,
  placeholder,
  hidden,
  error,
  className,
  ...props
}) => {
  return (
    <Input.Wrapper mb={24} className={className} label={label} error={error}>
      <Input
        component={InputMask}
        mask={'+7 999 999-99-99'}
        autoComplete="off"
        leftSection={<IconPhone size="1.1rem" />}
        error={error}
        placeholder={placeholder}
        hidden={hidden}
        {...props}
      />
    </Input.Wrapper>
  );
};

export default PhoneInput;
