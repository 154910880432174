import { TValidationField } from '@/types/modelsPlugin/modelsPlugin_v2';

import { validationTexts } from '@/constants/common/validationTexts';

export const getErrMessages = (
  props?: Record<string, TValidationField>
): Record<string, Record<string, string>> => {
  const errMessages: Record<string, Record<string, string>> = {};

  if (props) {
    for (const key in props) {
      errMessages[key] = {};

      if (props[key].required) {
        errMessages[key].required = `${validationTexts.REQUIRED}: ${props[key].title}`;
      }

      // TODO этот код необходимо переработать после смены ключа errMessages
      if (props[key].errMessages) {
        errMessages[key] = { ...errMessages[key], ...props[key].errMessages };
      }

      if (props[key].max) {
        errMessages[key].max = `Значение поля: не должно превышать ${props[key].max} символов`;
      }
      if (props[key].min) {
        errMessages[key].min = `Значение поля: не должно быть менее ${props[key].min} символов`;
      }
    }
  }

  return errMessages;
};
