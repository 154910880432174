import React, { FC, ReactNode } from 'react';
import { Box, Divider, Flex, Modal, ModalProps, ScrollArea, Text } from '@mantine/core';

interface BaseModalProps extends ModalProps {
  subtitle?: string;
  wide?: boolean;
  footer?: ReactNode;
}

const BaseModal: FC<BaseModalProps> = ({ title, footer, wide, subtitle, children, ...props }) => {
  return (
    <Modal.Root centered size={wide ? 765 : 'lg'} {...props}>
      <Modal.Overlay />
      <Modal.Content sx={{ overflow: 'hidden' }}>
        <Modal.Header>
          <Modal.Title>
            <Box>
              <Text fw={700} fz="xl">
                {title}
              </Text>

              {subtitle && (
                <Text fw={700} sx={(theme) => ({ color: theme.colors.gray[6] })}>
                  {subtitle}
                </Text>
              )}
            </Box>
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>

        <ScrollArea.Autosize mx="auto" w="calc(100% - 12px)" mah={footer ? '70vh' : '78vh'}>
          <Modal.Body>{children}</Modal.Body>
        </ScrollArea.Autosize>

        {footer && (
          <Flex justify="center" direction="column" w="100%" p={8} mih={60}>
            <Divider my="sm" />
            {footer}
          </Flex>
        )}
      </Modal.Content>
    </Modal.Root>
  );
};

export default BaseModal;
