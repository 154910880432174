import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Text } from '@mantine/core';

import { AppDispatch } from '@/store';
import { selectAddDomainModal, setAddDomainModalOpen } from '@/store/slices/menuPlugin/menus';

import MenusFormAddDomain from '../MenusFormAddDomain/MenusFormAddDomain';

const MenusModalAddDomain: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const addDomainModal = useSelector(selectAddDomainModal);

  return (
    <Modal
      centered
      size={'lg'}
      opened={addDomainModal}
      onClose={() => dispatch(setAddDomainModalOpen(false))}
      title={
        <Text fw={700} fz={22}>
          Добавить домен
        </Text>
      }
    >
      <MenusFormAddDomain />
    </Modal>
  );
};

export default MenusModalAddDomain;
