import cloneDeep from 'lodash/cloneDeep';

import { TValidationField } from '@/types/modelsPlugin/modelsPlugin_v2';

export const getSchema = (validation?: Record<string, TValidationField>) => {
  const props = cloneDeep(validation);
  const schema = {
    type: 'object',
    properties: {
      ...props,
    },
  };

  // TODO это нужно убрать после смены ключа errMessages
  if (schema.properties.code) delete schema.properties.code.errMessages;

  return schema;
};
