import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Anchor, AnchorProps } from '@mantine/core';

import { BreadcrumbsItem } from '@/types';

import BreadcrumbsExtItemIcon from '../BreadcrumbsExtItemIcon/BreadcrumbsExtItemIcon';

interface BreadcrumbsExtItemProps extends AnchorProps {
  item: BreadcrumbsItem;
}

const BreadcrumbsExtItem: FC<BreadcrumbsExtItemProps> = ({ item, ...props }) => {
  return (
    <>
      {item.url && (
        <Anchor c={'dimmed'} {...props} component={Link} to={item.url}>
          <BreadcrumbsExtItemIcon {...item} />
        </Anchor>
      )}
      {!item.url && (
        <Anchor c={'dimmed'} {...props} sx={{ pointerEvents: 'none' }}>
          <BreadcrumbsExtItemIcon {...item} />
        </Anchor>
      )}
    </>
  );
};

export default BreadcrumbsExtItem;
