import { UseFormReturnType } from '@mantine/form';

import { TFormField } from '@/types/modelsPlugin/modelsPlugin_v2';

export const getFieldIndex = (
  formObj: UseFormReturnType<{
    fields: TFormField[];
  }>,
  code: string
): number | null => {
  const fields = formObj.values.fields;
  let index = null;

  fields.forEach((el, i) => {
    if (el.code === code) index = i;
  });

  return index;
};
