import { FC, useEffect, useState } from 'react';
import {
  ActionIcon,
  Box,
  Button,
  Group,
  Loader,
  Popover,
  ScrollArea,
  Text,
  TextInput,
  TextInputProps,
} from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { IconX } from '@tabler/icons-react';

import { TFormField, TSelectData } from '@/types/modelsPlugin/modelsPlugin_v2';

import { fetching } from '@/ui/organisms/SearchableSelect/utils/fetching';
import { getSelectData } from '@/ui/organisms/SearchableSelect/utils/getSelectData';

interface SearchComponentProps extends TextInputProps {
  field: TFormField;
  selectedData: TSelectData[];
  addDataToSearch?: TSelectData[];
  cb?: (TSelectData: TSelectData) => void;
}

const SearchComponent: FC<SearchComponentProps> = ({
  field,
  selectedData,
  addDataToSearch,
  cb,
  ...props
}) => {
  const { extra } = field;
  const [opened, setOpened] = useState(false);
  const [searchFetching, setSearchFetching] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchData, setSearchData] = useState<TSelectData[]>([]);
  const searchRef = useClickOutside(() => setOpened(false));

  const uniqueData = (search: TSelectData[], selected: TSelectData[]) => {
    let tempSearchData = search;

    selected.forEach((item) => {
      tempSearchData = tempSearchData.filter((el) => el.value !== item.value);
    });

    return tempSearchData;
  };

  const handleSearch = async (value: string) => {
    if (value.length >= 3 && extra?.request) {
      setSearchFetching(true);

      await fetching(extra.request, value, {}, (res) => {
        const resData = getSelectData(res.items, {
          valueID: extra.identifier,
          labelID: extra.display && extra.display[1],
          codeID: extra.display && extra.display[0],
        });

        setSearchData(uniqueData(resData, selectedData));
      }).finally(() => setSearchFetching(false));
    }
  };

  const handleOpen = () => {
    setOpened(true);
  };

  const handleInputClear = () => {
    setSearchValue('');
    setSearchData([]);
  };

  const handleButtonClick = (item: TSelectData) => {
    setSearchData(uniqueData(searchData, [item]));
    setOpened(false);
    handleInputClear();

    if (cb) cb(item);
  };

  useEffect(() => {
    if (searchValue) handleSearch(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (addDataToSearch && addDataToSearch?.length > 0) {
      setSearchData([...searchData, ...addDataToSearch]);
    }
  }, [addDataToSearch]);

  return (
    <Box ref={searchRef}>
      <Popover opened={opened} width="target" position="bottom" shadow="md">
        <Popover.Target>
          <TextInput
            onInput={(ev) => setSearchValue(ev.currentTarget.value)}
            {...props}
            leftSection={!searchFetching ? props.leftSection : <Loader size="sm" />}
            value={searchValue}
            onFocus={handleOpen}
            onChange={undefined}
            rightSection={
              searchValue && (
                <ActionIcon variant="transparent" color="gray" onClick={handleInputClear}>
                  <IconX size={17} />
                </ActionIcon>
              )
            }
          />
        </Popover.Target>
        <Popover.Dropdown p={0}>
          {searchData && searchData.length === 0 && (
            <Text c="gray" size={'sm'} ta="center" p={12}>
              Ничего не найдено
            </Text>
          )}
          {searchData && searchData.length > 0 && (
            <ScrollArea w="100%" h={searchData.length > 5 ? 250 : 'min-content'} mah={250}>
              {searchData.map((item) => (
                <Button
                  key={item.value}
                  unstyled
                  w={'100%'}
                  onClick={() => handleButtonClick(item)}
                  sx={(theme) => ({
                    cursor: 'pointer',
                    border: 0,
                    padding: 12,
                    backgroundColor: 'transparent',
                    borderBottom: `1px solid ${theme.colors.gray[2]}`,
                  })}
                >
                  <Group gap={5} wrap="nowrap" w="min-content">
                    <Text size={'sm'} c="gray">
                      {item.code}
                    </Text>
                    <Text size={'sm'} truncate>
                      {item.label}
                    </Text>
                  </Group>
                </Button>
              ))}
            </ScrollArea>
          )}
        </Popover.Dropdown>
      </Popover>
    </Box>
  );
};

export default SearchComponent;
