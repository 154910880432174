export const isImage = (str: string): boolean => {
  const exts = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp'];

  if (str.split('/').length > 1) {
    if (str.split('/')[0] === 'image') return true;
  } else {
    const uppercaseExts = exts.map((ext) => ext.toUpperCase());
    if (uppercaseExts.indexOf(str.toUpperCase()) != -1) return true;
  }

  return false;
};
