import { TTableConfig } from '@/types';

export const TABLE_CONFIGURATIONS: TTableConfig[] = [
  {
    code: 'code',
    name: 'Код',
  },
  {
    code: 'name',
    name: 'Название',
    minWidth: 226,
  },
];

export const TABLE_CONFIGURATION: TTableConfig[] = [
  {
    code: 'code',
    name: 'Код',
  },
  {
    code: 'name',
    name: 'Название',
    minWidth: 226,
  },
  {
    code: 'type',
    name: 'Тип',
  },
];
