import { FC, useState } from 'react';
import { Box, CloseButton, Flex, Menu, ScrollArea, Text } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { IconSettings } from '@tabler/icons-react';

import { IService } from '@/types/common/services';

import { useServices } from '@/hooks/useServices';

import DynamicIcon from '@/ui/molecules/DynamicIcon/DynamicIcon';

import LayoutButton from '../LayoutButton/LayoutButton';

const useStyles = createStyles((theme) => ({
  item: {
    transition: 'color 0.25s ease',
    ['svg']: {
      color: theme.colors['jungle-mist'][4],
    },

    ['&:hover']: {
      color: theme.colors['science-blue'][9],
      ['svg']: {
        color: theme.colors['science-blue'][9],
      },
    },
  },
}));

interface LayoutSelectProps {
  collapse?: boolean;
}

const LayoutSelect: FC<LayoutSelectProps> = ({ collapse }) => {
  const { classes } = useStyles();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { services, currentService, setCurrentService } = useServices();

  const isCurrentItem = (item: IService) => item.code === currentService?.code;

  const currentServiceName = currentService ? `Сервисы: ${currentService.name}` : '';

  return (
    <Menu opened={showMenu} onChange={setShowMenu} position="top-start">
      <Menu.Target>
        <Box>
          <LayoutButton
            collapse={collapse}
            tooltip={currentServiceName}
            label={currentServiceName}
            leftSection={<IconSettings size={24} />}
          />
        </Box>
      </Menu.Target>
      <Menu.Dropdown miw={260}>
        <Flex justify="space-between">
          <Menu.Label>Сервисы</Menu.Label>
          <CloseButton onClick={() => setShowMenu(false)} aria-label="Закрыть" title="Закрыть" />
        </Flex>
        <ScrollArea h={390} scrollbarSize={5}>
          {Object.values(services).map((item: IService) => (
            <Menu.Item
              key={item.code}
              disabled={isCurrentItem(item)}
              onClick={() => setCurrentService(item.code)}
              className={classes.item}
              leftSection={<DynamicIcon name={item.icon} />}
            >
              <Text>{item.name}</Text>
            </Menu.Item>
          ))}
        </ScrollArea>
      </Menu.Dropdown>
    </Menu>
  );
};

export default LayoutSelect;
