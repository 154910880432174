import { FC, PropsWithChildren } from 'react';
import { BoxProps, Flex } from '@mantine/core';

import PageLoader from '../PageLoader/PageLoader';

interface PageContainerProps extends BoxProps {
  loading: boolean;
  isEmpty: boolean;
  loaderText?: string;
}

const PageContainer: FC<PropsWithChildren & PageContainerProps> = ({
  children,
  loading,
  isEmpty,
  loaderText = 'Пусто',
  ...props
}) => {
  return (
    <Flex direction={'column'} gap={0} mih={100} flex={'1 0 0'} {...props}>
      {loading || isEmpty ? <PageLoader loading={loading} text={loaderText} /> : children}
    </Flex>
  );
};

export default PageContainer;
