import { ReviewStatus } from '@/types';

export const REVIEW_STATUS_LABELS: { [key in ReviewStatus]: string } = {
  [ReviewStatus.WAIT]: 'На модерации',
  [ReviewStatus.ACTIVE]: 'Активен',
  [ReviewStatus.INACTIVE]: 'Неактивен',
};

export const REVIEW_STATUS_ITEMS = [
  {
    value: ReviewStatus.ACTIVE,
    label: REVIEW_STATUS_LABELS[ReviewStatus.ACTIVE],
  },
  {
    value: ReviewStatus.INACTIVE,
    label: REVIEW_STATUS_LABELS[ReviewStatus.INACTIVE],
  },
  {
    value: ReviewStatus.WAIT,
    label: REVIEW_STATUS_LABELS[ReviewStatus.WAIT],
  },
];

export const REVIEWS_SEARCH_PLACEHOLDER = 'Начните вводить Артикул, ID покупателя или ID отзыва';
export const REVIEWS_USERS_FILES_DIR_ID = '305a4670-f566-4d42-ba94-075fab4ec17b';
export const REVIEWS_IMPORT_FOLDER_ID = 'b120e5c7-c0e9-4d90-82a2-24ca31d3b1c0';
export const REVIEWS_STOP_LIST_FOLDER_ID = '9dced318-8b15-4b0d-aea0-1a55419ed7bd';

export const REVIEWS_IMPORT_TEMPLATE_URL = `../media/${REVIEWS_IMPORT_FOLDER_ID}`;
export const REVIEWS_STOP_LIST_FOLDER_URL = `../media/${REVIEWS_STOP_LIST_FOLDER_ID}`;
