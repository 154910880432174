import { Menu, TAddMenusReq, TElementsRes, TPutMenuReq, TQueryParams } from '@/types';

import { URLS } from '@/api/urls';

import api from '../instance';

export const apiGetMenu = (params: TQueryParams) =>
  api.get<TElementsRes<Menu>>(URLS.menus.getMenu, { params });

export const apiPutMenu = (req: TPutMenuReq) =>
  api.put(URLS.menus.putMenusById(req.id), { ...req.data });

export const apiAddMenus = (req: TAddMenusReq) =>
  api.post<{ id: string }>(URLS.menus.addMenus, { ...req });

export const apiDelMenu = (id: string) => api.delete(URLS.menus.delMenuById(id));
