export const downloadFile = (url: string, name: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  link.setAttribute('target', '_blank');

  document.body.appendChild(link);

  link.click();

  link.parentNode?.removeChild(link);
};
