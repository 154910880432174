import { FC } from 'react';
import { Box, Text } from '@mantine/core';

interface LoaderOverlayChildrenProps {
  loadingText?: string;
}

export const LoaderOverlayChildren: FC<LoaderOverlayChildrenProps> = ({ loadingText }) => {
  return (
    <Box>
      <img
        src="/images/loader.gif"
        style={{
          display: 'block',
          minWidth: 0,
          minHeight: 0,
          maxHeight: '80%',
          maxWidth: '80%',
          margin: 'auto',
        }}
      />

      {loadingText && (
        <Text ta="center" c="dimmed">
          {loadingText}
        </Text>
      )}
    </Box>
  );
};
