import { TValidationField } from '@/types/modelsPlugin/modelsPlugin_v2';

export const getValidationProp = (
  code: string,
  prop: keyof TValidationField,
  validation?: Record<string, TValidationField>
) => {
  return validation && validation[code] && validation[code][prop]
    ? validation[code][prop]
    : undefined;
};
