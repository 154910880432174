import { ReviewStatus } from '@/types';

import notify from '@/utils/notify';
import YupLocale from '@/utils/YupLocale';

import { validationTexts } from './validationTexts';

YupLocale.addMethod(YupLocale.array, 'unique', function unique(message, mapper = (a: string) => a) {
  return this.test('unique', message, function (list) {
    return list?.length === new Set(list?.map(mapper)).size;
  });
});

export const uniqSchema = (fieldName: string) =>
  YupLocale.array().of(YupLocale.string()).unique(validationTexts.UNIQUE(fieldName));

const STRING_REGEXP = /^\S*$|^([a-zA-Zа-яА-Я0-9Ёё\s,._!?;:"'`-]+)$/gm;
const STRING_MENU_REGEXP =
  /^([a-zA-Zа-яА-Я0-9,._!?;:"'`\S-]+)([a-zA-Zа-яА-Я0-9\s\S,._!?;:"'`-]+)$/gm;
export const STRING_PHONE_REGEXP = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/gm;
export const PHONE_LENGTH_REGEXP =
  /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/gm;
export const STRING_EMAIL_REGEXP = /^[a-zA-Z0-9_!#$%&’*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$/gm;
export const STRING_LINK_REGEXP =
  /^((\/)|(http(s)?:\/\/[\w.-]+\.[\w.-]+))+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/gm;
export const CODE_REGEXP = /^\s*$|^[a-z0-9]+(?:-[a-z0-9]+)*$/gm;
const EXTENDED_CODE_REGEXP = /^([a-z0-9-/])*$/gm;
const EXCLUDE_SYMBOLS = /^([^\/]([a-z0-9-/])*[a-z0-9]{2})?$/gm;
export const POSITION_REGEXP = /^[1-3]{1}$/gm;
export const STRING_RELATIVE_OR_ABSOLUTE_LINK = /^(\/)|((?:http(s)?:\/\/))/gm;
export const EMAIL_REGEXP =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const URL_START_WITH_HTTP_REGEXP =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%_+.~#?&\/=]*$/;
export const EXCLUDE_FIRST_SLASH_URL_REGEXP = /(^[a-z0-9]+([a-z0-9-/])*[a-z0-9]$)|(^$)/gm;
export const EXTERNAL_ID = ['e', '.', ',', '+', '-', 'E'];
export const EXTERNAL_ID_REGEXP = /[e.,+-E]/g;

const TLD_REGEXP = /\.[a-zA-Z]/;
const ENGLISH_STRING_REGEXP = /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;

const VALIDATION_NUMBER = YupLocale.number()
  .typeError(validationTexts.REQUIRED)
  .required(validationTexts.REQUIRED);

export const VALIDATION_STRING = YupLocale.string()
  .trim()
  .matches(STRING_REGEXP, validationTexts.STRING)
  .min(3, validationTexts.MIN_LENGTH(3))
  .max(50, validationTexts.MAX_LENGTH(50));

export const VALIDATION_NAME = YupLocale.string()
  .trim()
  .min(3, validationTexts.MIN_LENGTH(3))
  .max(50, validationTexts.MAX_LENGTH(50));

const VALIDATION_MENU_STRING = YupLocale.string()
  .required()
  .matches(STRING_MENU_REGEXP, validationTexts.STRING)
  .matches(STRING_MENU_REGEXP, validationTexts.STRING_NOT_ONLY_WHITESPACE_REGEXP)
  .min(3, validationTexts.MIN_LENGTH(3))
  .max(50, validationTexts.MAX_LENGTH(50));

const VALIDATION_CODE = YupLocale.string()
  .matches(CODE_REGEXP, validationTexts.CODE)
  .min(3, validationTexts.MIN_LENGTH(3))
  .max(50, validationTexts.MAX_LENGTH(50));

const VALIDATION_CODE_EDIT = YupLocale.string()
  .min(3, validationTexts.MIN_LENGTH(3))
  .max(50, validationTexts.MAX_LENGTH(50));

const VALIDATION_URL = YupLocale.string()
  .matches(EXCLUDE_SYMBOLS, validationTexts.NOT_SLASH_FIRST)
  .matches(EXTENDED_CODE_REGEXP, validationTexts.URL);

const VALIDATION_URL_WITH_HTTP = YupLocale.string()
  .matches(STRING_RELATIVE_OR_ABSOLUTE_LINK, validationTexts.URL_WITH_HTTP)
  .matches(ENGLISH_STRING_REGEXP, validationTexts.NO_CYRILLIC)
  .matches(TLD_REGEXP, validationTexts.CORRECT_TLD);

const VALIDATION_URL_WITHOUT_FIRST_SLASH = YupLocale.string()
  .matches(EXCLUDE_FIRST_SLASH_URL_REGEXP, validationTexts.NOT_SLASH_FIRST)
  .matches(EXTENDED_CODE_REGEXP, validationTexts.URL);

const NON_EMPTY_STRING = YupLocale.string().required().min(1, validationTexts.REQUIRED);

export const VALIDATION_PHONE = YupLocale.string().matches(
  STRING_PHONE_REGEXP,
  validationTexts.ONLY_NUMBER
);

// Forms
export const NEW_CONFIGURATION_FORM = YupLocale.object().shape({
  name: VALIDATION_NAME,
  code: VALIDATION_CODE,
});

export const EDIT_CONFIGURATION_FORM = YupLocale.object().shape({
  name: VALIDATION_NAME,
  code: VALIDATION_CODE_EDIT,
});

export const OPTION_CONFIGURATION_FORM = YupLocale.object().shape({
  name: VALIDATION_NAME,
  code: VALIDATION_CODE,
  sort: VALIDATION_NUMBER,
});

export const NEW_OPTION_MODEL_FORM = YupLocale.object().shape({
  name: VALIDATION_NAME,
  code: VALIDATION_CODE,
  url: VALIDATION_URL,
});

export const NEW_ITEM_MENU_FORM = YupLocale.object().shape({
  name: VALIDATION_MENU_STRING,
  url: VALIDATION_URL_WITHOUT_FIRST_SLASH,
  additionalFields: YupLocale.array()
    .of(
      YupLocale.object()
        .shape({
          code: YupLocale.string()
            .trim()
            .test('test length', validationTexts.MIN_LENGTH(3), (item) => {
              const value = item?.toString() || '';
              return !value.length || value.length > 2;
            })
            .matches(STRING_REGEXP, validationTexts.STRING)

            .max(50, validationTexts.MAX_LENGTH(50)),
          value: YupLocale.string()
            .trim()
            .matches(STRING_REGEXP, validationTexts.STRING)
            .max(50, validationTexts.MAX_LENGTH(50)),
        })
        .test('test value', 'Поле обязательно', (item) => {
          return !((item.code && !item.value) || (item?.value && !item.code));
        })
    )
    .test('test unique values', 'Поля должны быть уникальны', (items) => {
      const codes = items?.map((item) => item.code) || [];

      const duplicates = codes.filter((item, index) => codes.indexOf(item) !== index);
      const hasDuplictes = Boolean(duplicates.length);

      if (!hasDuplictes) {
        return true;
      }

      notify({
        type: 'error',
        message: 'Ошибка создания нового пункта: Названия параметров не должны повторяться!',
      });
      return false;
    }),
});

export const ADD_FOLDER_FORM = YupLocale.object().shape({
  name: VALIDATION_NAME,
});

export const NEW_MENU_FORM = YupLocale.object().shape({
  name: VALIDATION_MENU_STRING,
  code: VALIDATION_CODE,
});

export const PHONE_NUMBER = YupLocale.object().shape({
  value: VALIDATION_PHONE,
});

export const REVIEW_STATUS = YupLocale.string().oneOf(
  [ReviewStatus.ACTIVE, ReviewStatus.INACTIVE],
  validationTexts.REVIEW_STATUS_ERROR
);

export const REVIEW_FORM = YupLocale.object().shape({
  createdDate: NON_EMPTY_STRING,
  editingDate: NON_EMPTY_STRING,
  productId: NON_EMPTY_STRING,
  rating: VALIDATION_NUMBER,
  status: REVIEW_STATUS,
  inactivationReasonId: YupLocale.string().test('inactivation-test', (value, context) => {
    const {
      createError,
      parent: { status },
    } = context;

    if (status === ReviewStatus.INACTIVE && !value) {
      return createError({
        message: validationTexts.REQUIRED,
      });
    }

    return true;
  }),
  userName: NON_EMPTY_STRING,
  response: YupLocale.string().test('response-test', (value, context) => {
    const {
      createError,
      parent: { responseDate },
    } = context;

    if (responseDate && !value) {
      return createError({
        message: validationTexts.REQUIRED,
      });
    }

    return true;
  }),
});

export const EMAIL_FORM = YupLocale.object().shape({
  sender: YupLocale.string()
    .trim()
    .matches(EMAIL_REGEXP, validationTexts.EMAIL_ERROR)
    .required(validationTexts.REQUIRED),
  recipients: YupLocale.string()
    .trim()
    .required(validationTexts.REQUIRED)
    .test('email', (value, ctx) => {
      const firstInvalidEmail = value
        .split(',')
        .map((email) => email.trim())
        .find((v) => !YupLocale.string().matches(EMAIL_REGEXP).isValidSync(v));

      return !firstInvalidEmail
        ? true
        : ctx.createError({
            message: `"${firstInvalidEmail}" - некорректный email.`,
          });
    }),
  subject: YupLocale.string().trim().required(validationTexts.REQUIRED),
  template: YupLocale.string()
    .trim()
    .required(validationTexts.REQUIRED)
    .test('html', (value, ctx) => {
      const cutTags = (str: string) => {
        const regex = /( |<([^>]+)>)/gi;

        return str.replace(regex, '');
      };

      return cutTags(value).length > 0
        ? true
        : ctx.createError({ message: validationTexts.REQUIRED });
    }),
});

export const ADD_DOMAIN = YupLocale.object().shape({
  domain: VALIDATION_URL_WITH_HTTP,
});

export const REVIEW_ANALYSIS_FORM = YupLocale.object().shape({
  fields: YupLocale.array().min(1, validationTexts.REQUIRED),
});

export const REVIEW_MODERATORS_FORM = YupLocale.object().shape({
  moderatorsIds: YupLocale.array().min(1, validationTexts.REQUIRED),
});

export const REVIEW_IMPORT_FORM = YupLocale.object().shape({
  mediaId: NON_EMPTY_STRING,
});

export const REVIEW_IMPORT_ARCHIVE_FORM = YupLocale.object().shape({
  file: YupLocale.mixed()
    .required(validationTexts.REQUIRED)
    .test('type', validationTexts.REVIEW_FILE_ERROR, (file: Record<string, string>) => {
      return (
        file &&
        (file.type === 'zip' ||
          file.type === 'application/octet-stream' ||
          file.type === 'application/zip' ||
          file.type === 'application/x-zip' ||
          file.type === 'application/x-zip-compressed')
      );
    }),
});
