import qs from 'qs';

import { HTTPMethodEnum, TElementsRes } from '@/types';
import { TRequest } from '@/types/modelsPlugin/modelsPlugin_v2';

import { OFFSET, SEARCH_LIMIT } from '@/constants/common/common';

import { api } from '@/api';

const urlIsAbsolute = (uri: string): boolean => uri.toLowerCase().includes('http');

// TODO эту утилиту нужно перенести в общие
export const fetching = async (
  request: TRequest,
  value?: string | string[],
  config?: Record<string, unknown>,
  callback?: (value: TElementsRes<Record<string, string>>) => void,
  isSearch: boolean = true,
  limit: number | string = SEARCH_LIMIT
): Promise<any> => {
  const { service, uri, meth, body } = request;
  let URL = null;
  let response = null;

  if (urlIsAbsolute(uri)) {
    switch (meth) {
      case HTTPMethodEnum.POST:
        URL = `${uri}`;
        response = await api.post(URL, { ...body, query: value });

        if (response && response.status === 200) {
          if (callback) {
            callback(response.data);
          } else {
            return response;
          }
        }

        break;
      default:
        break;
    }
  } else {
    switch (meth) {
      case HTTPMethodEnum.POST:
        URL = `${uri}?${qs.stringify(
          {
            limit,
            offset: OFFSET,
            query: isSearch ? value : '',
          },
          { indices: false }
        )}`;

        let reqConfig = {};

        if (service) {
          reqConfig = {
            headers: {
              'Service-Code': service,
            },
          };
        }

        response = await api.post(URL, { ...body, ...config }, { ...reqConfig });

        if (response && response.status === 200) {
          if (callback) {
            callback(response.data);
          } else {
            return response;
          }
        }
        break;
      case HTTPMethodEnum.GET:
        URL = `${uri}?${qs.stringify(
          {
            limit,
            offset: OFFSET,
            query: value,
          },
          { indices: false }
        )}`;

        response = await api.get(URL);

        if (response && response.status === 200) {
          if (callback) {
            callback(response.data);
          } else {
            return response;
          }
        }
        break;
      default:
        break;
    }
  }
};
