import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ActionIcon, Group } from '@mantine/core';
import { IconAlignRight } from '@tabler/icons-react';

import { TModel } from '@/types';

const ModelsTableButtons: FC<TModel> = ({ ...props }) => {
  return (
    <Group justify={'right'}>
      <ActionIcon
        variant="subtle"
        color="gray"
        title="Элементы модели"
        component={Link}
        to={`${props.code}`}
      >
        <IconAlignRight size={20} color="gray" />
      </ActionIcon>
    </Group>
  );
};

export default ModelsTableButtons;
