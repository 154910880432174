import { FC } from 'react';
import { Flex, Text } from '@mantine/core';

import { BreadcrumbsItem } from '@/types';

interface BreadcrumbsExtItemIconProps extends BreadcrumbsItem {}

const BreadcrumbsExtItemIcon: FC<BreadcrumbsExtItemIconProps> = ({ icon, name }) => (
  <Flex align="center" gap={4}>
    {icon}
    <Text fz={14}>{name}</Text>
  </Flex>
);

export default BreadcrumbsExtItemIcon;
