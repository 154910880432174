export const validationTexts = {
  REQUIRED: 'Заполните поле',
  CODE: 'Использовать латиницу в нижнем регистре, цифры и дефис',
  STRING: 'Поле должно содержать только буквы, цифры и строковые символы (, . ! ? ; : " \' ` )',
  STRING_NOT_ONLY_WHITESPACE_REGEXP: 'Нельзя использовать только пробелы',
  ONLY_NUMBER: 'Поле должно содержать только цифры',
  URL: 'Использовать латиницу в нижнем регистре, цифры, дефис и слэш',
  URL_WITH_HTTP: 'Домен должен начинаться с http или https',
  NOT_SLASH_FIRST: 'Слэш в URL может быть только в середине',
  MIN_LENGTH: (value: number) => `Поле должно содержать не менее ${value} символов`,
  MAX_LENGTH: (value: number) => `Поле должно содержать не более ${value} символов`,
  MAX_SIZE: (value: number) => `Нельзя загружать файлы больше ${value}Мб`,
  PHONE_ERROR: 'Неверно введен формат телефона',
  EMAIL_ERROR: 'Неверно введен формат email',
  LINK_ERROR: 'Поле должно содержать ссылку',
  POSITION_ERROR: 'Поле должно содержать только цифры от 1 до 3',
  REVIEW_STATUS_ERROR: 'Измените статус на Активен или Деактивирован',
  POSITIVE_NUMBER: 'Число должно быть положительным',
  INTEGER_NUMBER: 'Число должно быть целым',
  NUMBER_TYPE: 'Поле должно быть числом',
  REVIEW_FILE_ERROR: 'Файл должен быть zip архивом',
  NO_CYRILLIC: 'Поле не должно содержать кириллицу',
  CORRECT_TLD: 'Поле должно содержать правильное окончание ссылки (.com, .ru и т.п.)',
  UNIQUE: (name: string) => `Поле ${name} не должно содержать одинаковых значений`,
};
