import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ActionIcon, Group } from '@mantine/core';
import { IconPencil, IconTrash } from '@tabler/icons-react';

import { PluginCode, TMailsTemplate } from '@/types';

import { PLUGIN_PATHS } from '@/constants/common/plugins';

import { AppDispatch } from '@/store';
import {
  setCurrentMailsTemplate,
  setOpenDelMailsTemplateModal,
} from '@/store/slices/mailsTemplatesPlugin/mailsTemplates';

const MailsTemplatesTableButtons: FC<TMailsTemplate> = ({ ...props }) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const rootUrl = PLUGIN_PATHS[PluginCode.MAIL_TEMPLATES];

  const changeHandler = () => {
    navigate(`${rootUrl}/${props.id}`);
  };

  const deleteHandler = () => {
    dispatch(setOpenDelMailsTemplateModal(true));
    dispatch(setCurrentMailsTemplate(props));
  };

  return (
    <Group justify={'right'} wrap="nowrap">
      <ActionIcon
        variant="subtle"
        color="gray"
        name="Изменить"
        title="Изменить"
        onClick={changeHandler}
      >
        <IconPencil size={20} color="gray" />
      </ActionIcon>

      <ActionIcon
        variant="subtle"
        color="gray"
        name="Удалить"
        title="Удалить"
        onClick={deleteHandler}
      >
        <IconTrash size={20} color="gray" />
      </ActionIcon>
    </Group>
  );
};

export default MailsTemplatesTableButtons;
