import { FC } from 'react';
import { Center, Group, Table, Text, UnstyledButton } from '@mantine/core';
import { IconChevronDown, IconChevronUp, IconSelector } from '@tabler/icons-react';

import { SortOrderEnum } from '@/types';

import { useStyles } from './style';

interface ITableHeadSortable {
  name: string;
  order?: SortOrderEnum;
  sorted?: boolean | null;
  onSort(): void;
}

const TableHeadSortable: FC<ITableHeadSortable> = ({ name, order, sorted, onSort }) => {
  const { classes } = useStyles();
  const Icon = sorted
    ? order === SortOrderEnum.ASC
      ? IconChevronDown
      : IconChevronUp
    : IconSelector;

  return (
    <Table.Th>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group
          justify="left"
          gap={'xs'}
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            gap: '0',
          }}
        >
          <Center className={classes.icon}>
            <Icon size={14} />
          </Center>
          <Text fw={700} size="sm" sx={{ whiteSpace: 'nowrap' }}>
            {name}
          </Text>
        </Group>
      </UnstyledButton>
    </Table.Th>
  );
};

export default TableHeadSortable;
