import { make } from '../../utils/make';
import { iconImage, iconPhotoDownString, iconRefresh } from '../../utils/icons';
import './index.css';

class ImageWithLink {
  constructor({ data, config, api }) {
    this.api = api;
    this.config = {
      uploader: config.uploader || undefined,
    };
    this.data = {
      file: data.file || undefined,
      link: data.link || '',
    };
    this.nodes = {
      root: null,
      imageHolder: null,
      imageHolderInput: null,
      image: null,
      linkInput: null,
    };
  }

  static get toolbox() {
    return {
      title: 'Image with Link',
      icon: iconPhotoDownString,
    };
  }

  get CSS() {
    return {
      root: 'iwl',
      imageHolder: 'iwl__image-holder',
      image: 'iwl__image',
      linkInput: 'iwl__link-input',
      block: 'iwl__block',
      imageHolderIcon: 'iwl__image-holder__icon',
      imageHolderText: 'iwl__image-holder__text',
      loader: 'iwl__loader',
      preview: 'iwl__preview',
    };
  }

  get ICONS() {
    return {
      refresh: iconRefresh(),
    };
  }

  render() {
    this.makeImageHolder();
    this.makeLinkInput();
    this.makeLoader();
    this.makeImagePreview();

    this.nodes.root = make('div', this.CSS.root, {});
    this.nodes.root.appendChild(this.nodes.imageHolder);
    this.nodes.root.appendChild(this.nodes.linkInput);

    return this.nodes.root;
  }

  makeImageHolder() {
    const imageHolder = make('label', [this.CSS.imageHolder, this.CSS.block], {
      for: 'image-file',
    });
    const imageHolderInput = make('input', this.CSS.imageHolderInput, {
      type: 'file',
      name: 'image-file',
      accept: 'image/png, image/jpeg, image/webp, image/gif, image/svg+xml',
      hidden: 'true',
    });
    const imageHolderIcon = make('div', this.CSS.imageHolderIcon, {});
    imageHolderIcon.appendChild(iconImage());
    const imageHolderText = make('span', this.CSS.imageHolderText, {});
    imageHolderText.textContent = 'Добавить изображение';

    imageHolder.appendChild(imageHolderInput);
    imageHolder.appendChild(imageHolderIcon);
    imageHolder.appendChild(imageHolderText);

    if (this.data.file) {
      imageHolderIcon.style.display = 'none';
      imageHolderText.style.display = 'none';
    }

    const image = make('img', this.CSS.image, {});
    image.style.display = 'none';
    imageHolder.appendChild(image);

    this.nodes.imageHolder = imageHolder;
    this.nodes.imageHolderInput = imageHolderInput;
    this.nodes.imageHolderIcon = imageHolderIcon;
    this.nodes.imageHolderText = imageHolderText;
    this.nodes.image = image;

    imageHolderInput.addEventListener('change', (event) => {
      const [file] = event.target.files;
      if (file) {
        this.nodes.imageHolder.appendChild(this.nodes.loader);
        this.nodes.image.style.display = 'none';
        this.nodes.imageHolderIcon.style.display = 'none';
        this.nodes.imageHolderText.style.display = 'none';

        if (this.config.uploader) {
          this.config
            .uploader(file)
            .then((uploadResult) => {
              this.data.file = uploadResult.file;
              this.nodes.image.src = uploadResult.file.url;
              this.nodes.image.style.display = 'block';
              this.nodes.loader.remove();
            })
            .catch((error) => {
              notify({ message: error.response.data.message, type: 'error' });
              this.nodes.loader.remove();
            });
        } else {
          this.nodes.loader.remove();
        }
      }
    });
  }

  makeLinkInput() {
    const linkInput = make('input', this.CSS.linkInput, {
      type: 'text',
      placeholder: 'Введите URL ссылки',
      value: this.data.link,
    });

    linkInput.addEventListener('input', (event) => {
      this.data.link = event.target.value;
    });

    this.nodes.linkInput = linkInput;
  }

  makeLoader() {
    const loader = make('div', this.CSS.loader);
    const loaderIcon = make('div');

    loaderIcon.appendChild(this.ICONS.refresh);
    loader.appendChild(loaderIcon);

    this.nodes.loader = loader;
  }

  makeImagePreview() {
    const preview = make('div', this.CSS.preview, {});
    const previewImg = make('img', this.CSS.image, {
      src: this.data.file && this.data.file.url ? this.data.file.url : '',
      style: 'display: block;',
    });

    preview.appendChild(previewImg);

    this.nodes.preview = preview;
    this.nodes.image = previewImg;
    this.nodes.imageHolder.appendChild(this.nodes.preview);
  }

  save() {
    if (this.data.link && this.nodes.image && this.nodes.image.parentNode) {
      const wrappedImage = this.wrapImageWithLink();
      this.nodes.image.parentNode.replaceChild(wrappedImage, this.nodes.image);
    }

    return {
      file: this.data.file,
      link: this.data.link,
    };
  }

  wrapImageWithLink() {
    const link = make('a', null, {
      href: this.data.link,
      target: '_blank',
    });
    link.appendChild(this.nodes.image.cloneNode(true));
    return link;
  }
}

export default ImageWithLink;
