import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Group, Menu } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { AppDispatch } from '@/store';
import { fetchGetFolders } from '@/store/slices/mediaPlugin/media';
import {
  selectCurrentFile,
  setDelFileModalOpened,
  setMoveFileModalOpened,
  setRenameFileModalOpened,
} from '@/store/slices/mediaPlugin/mediaFiles';
import {
  selectCurrentFolder,
  setDelFolderModalOpened,
  setRenameFodlerModalOpened,
} from '@/store/slices/mediaPlugin/mediaFolders';

const ActionButtons: FC = () => {
  const isMobile = useMediaQuery('(max-width: 1490px)');
  const currentFolder = useSelector(selectCurrentFolder);
  const currentFile = useSelector(selectCurrentFile);
  const dispatch: AppDispatch = useDispatch();

  const renameHandler = () => {
    if (currentFolder) dispatch(setRenameFodlerModalOpened(true));
    if (currentFile) dispatch(setRenameFileModalOpened(true));
  };

  const delClickHandler = () => {
    if (currentFolder) dispatch(setDelFolderModalOpened(true));
    if (currentFile) dispatch(setDelFileModalOpened(true));
  };

  const moveClickHandler = async () => {
    await dispatch(fetchGetFolders());
    dispatch(setMoveFileModalOpened(true));
  };

  const buttons = [
    { title: 'Переместить', onClick: moveClickHandler },
    { title: 'Переименовать', onClick: renameHandler },
    { title: 'Удалить', onClick: delClickHandler },
  ];

  return (
    <Group
      sx={{ visibility: currentFolder || currentFile ? 'initial' : 'hidden' }}
      gap={isMobile ? 5 : 'sm'}
      mb={8}
    >
      {!isMobile ? (
        buttons.map((item) => (
          <Button
            miw={isMobile ? 100 : 144}
            variant="white"
            color="dark"
            key={item.title}
            {...item}
          >
            {item.title}
          </Button>
        ))
      ) : (
        <Menu>
          <Menu.Target>
            <Button variant="white" color="dark">
              Действия
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {buttons.map((item) => (
              <Menu.Item miw={isMobile ? 100 : 144} key={item.title} {...item}>
                {item.title}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      )}
    </Group>
  );
};

export default ActionButtons;
