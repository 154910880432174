import { FC, memo, RefObject, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Checkbox, ScrollArea, Table, TableProps } from '@mantine/core';
import isEqual from 'lodash/isEqual';
import qs from 'qs';

import { SortOrderEnum, TTableConfig } from '@/types';
import { TModelTableElement } from '@/types/modelsPlugin/modelsPlugin_v2';

import { useQueryParams } from '@/hooks/useQueryParams';

import { getTableHeaders } from './utils/getTableHeaders';
import { GetTableRows } from './utils/getTableRows';
import { includeRow } from './utils/includeRow';

import { useStyles } from './style';

interface TableExtProps extends TableProps {
  config: TTableConfig[] | TModelTableElement[];
  scrollheight?: number;
  rows: Record<string, any>[] | null;
  buttons?: FC<any>;
  selectable?: boolean;
  selectCallback?: (values: Record<string, string>[]) => void;
  sortableKeys?: string[];
  onScrollPositionChange?: (value: { x: number; y: number }) => void;
  scrollViewportRef?: RefObject<HTMLDivElement>;
}

const TableExt: FC<TableExtProps> = memo(
  ({
    config,
    rows,
    scrollheight,
    buttons,
    selectable,
    selectCallback,
    sortableKeys,
    scrollViewportRef,
    onScrollPositionChange,
    ...props
  }) => {
    const { classes, cx } = useStyles();
    const [scrolled, setScrolled] = useState(false);
    const { currentFilter, currentLimit, currentOffset, currentSort, currentOrder, currentQuery } =
      useQueryParams();
    const [selection, setSelection] = useState<Record<string, string>[]>([]);
    const [sortBy, setSortBy] = useState<string | null>(currentSort || null);
    const [reversed, setReversed] = useState<boolean>(currentOrder === SortOrderEnum.DESC);
    const [, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const currentPath = pathname;
    const clickablePaths = ['/configurations', '/models-v2', '/menus'];

    useEffect(() => {
      if (selectCallback) selectCallback(selection);
    }, [selection]);

    const toggleAll = () =>
      setSelection((current) => (rows ? (current.length === rows.length ? [] : rows) : []));

    const toggleRow = (item: Record<string, string>) =>
      setSelection((current) => {
        return includeRow(current, item)
          ? current.filter((el) => !isEqual(el, item))
          : [...current, item];
      });

    const setSorting = (field: string) => {
      const revers = field === sortBy ? !reversed : false;

      setReversed(revers);
      setSortBy(field);

      const newParams = {
        ...currentFilter,
        limit: currentLimit,
        offset: currentOffset,
        sort: field,
        order: revers ? SortOrderEnum.DESC : SortOrderEnum.ASC,
        query: currentQuery,
      };
      const URLParams = qs.stringify(newParams, { indices: false });

      setSearchParams(URLParams);
    };

    return (
      <ScrollArea
        classNames={{
          root: classes.root,
        }}
        viewportRef={scrollViewportRef}
        h={scrollheight ? scrollheight - 10 : '100%'}
        onScrollPositionChange={({ y, x }) => {
          setScrolled(y !== 0);
          if (onScrollPositionChange) onScrollPositionChange({ x, y });
        }}
        viewportProps={{ style: { position: 'relative', zIndex: 0 } }}
        type="hover"
      >
        <Table highlightOnHover verticalSpacing="xs" fz="md" {...props}>
          <Table.Thead
            sx={(theme) => ({ backgroundColor: theme.colors.gray[2] })}
            className={cx(classes.header, { [classes.scrolled]: scrolled })}
          >
            <Table.Tr>
              {selectable && rows && (
                <Table.Th w={40}>
                  <Checkbox
                    onChange={toggleAll}
                    checked={selection.length === rows.length}
                    indeterminate={selection.length > 0 && selection.length !== rows.length}
                  />
                </Table.Th>
              )}
              {getTableHeaders(
                config,
                buttons ? true : false,
                sortableKeys,
                setSorting,
                currentOrder,
                sortBy
              )}
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            {GetTableRows(
              config,
              rows,
              buttons,
              selectable,
              selection,
              toggleRow,
              sortableKeys,
              (code) => {
                if (clickablePaths.includes(currentPath)) navigate(`${currentPath}/${code}`);
              }
            )}
          </Table.Tbody>
        </Table>
      </ScrollArea>
    );
  }
);

export default TableExt;
