import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { LIMIT, OFFSET } from '@/constants/common/common';

import { getFilterFromURL } from '@/utils/getFilterFromURL/getFilterFromURL';
import { getLimitFromURL } from '@/utils/getLimitFromURL/getLimitFromURL';
import { getOffsetFromURL } from '@/utils/getOffsetFromURL/getOffsetFromURL';
import { getOrderFromURL } from '@/utils/getOrderFromURL/getOrderFromURL';
import { getQueryFromURL } from '@/utils/getQueryFromURL/getQueryFromURL';
import { getSortFromURL } from '@/utils/getSortFromURL/getSortFromURL';

export const useQueryParams = () => {
  const [searchParams] = useSearchParams();

  const currentFilter = useMemo(() => getFilterFromURL(searchParams), [searchParams]);
  const currentOrder = useMemo(() => getOrderFromURL(searchParams), [searchParams]);
  const currentSort = useMemo(() => getSortFromURL(searchParams), [searchParams]);
  const currentLimit = useMemo(() => getLimitFromURL(searchParams) || LIMIT, [searchParams]);
  const currentOffset = useMemo(() => getOffsetFromURL(searchParams) || OFFSET, [searchParams]);
  const currentQuery = useMemo(() => getQueryFromURL(searchParams), [searchParams]);

  return { currentFilter, currentOrder, currentSort, currentLimit, currentOffset, currentQuery };
};
