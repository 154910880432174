import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ScrollArea, SimpleGrid } from '@mantine/core';

import { IFileType, IFolderType, PluginCode, ResourceType, TCancellablePromise } from '@/types';

import { PLUGIN_PATHS } from '@/constants/common/plugins';

import { AppDispatch } from '@/store';
import { selectSelectedId, setMedia, setSelectedId } from '@/store/slices/mediaPlugin/media';
import { fetchGetFileInfo, setCurrentFile } from '@/store/slices/mediaPlugin/mediaFiles';
import { fetchGetFolderInfo, setCurrentFolder } from '@/store/slices/mediaPlugin/mediaFolders';

import MediaItem from '../MediaItem/MediaItem';

interface IMediaGroup {
  viewportRef: React.RefObject<HTMLDivElement>;
  onScrollPositionChange: React.Dispatch<React.SetStateAction<{ x: number; y: number }>>;
  items: (IFolderType | IFileType)[];
}

const MediaGroup: FC<IMediaGroup> = ({ viewportRef, onScrollPositionChange, items }) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [fileFetchPromise, setFileFetchPromise] = useState<TCancellablePromise | null>(null);
  const [folderFetchPromise, setFolderFetchPromise] = useState<TCancellablePromise | null>(null);
  const selectedItemId = useSelector(selectSelectedId);

  const clickOutsideItemHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const element = e.target as HTMLDivElement;
    const classes = element.classList;

    let outside = false;

    classes.forEach((cl) => {
      if (cl.indexOf('ScrollArea') !== -1) outside = true;
      if (cl.indexOf('SimpleGrid') !== -1) outside = true;
    });

    if (outside) {
      dispatch(setSelectedId(null));
      dispatch(setCurrentFolder(null));
      dispatch(setCurrentFile(null));
    }
  };

  const cancelRequests = () => {
    if (fileFetchPromise) fileFetchPromise.abort();
    if (folderFetchPromise) folderFetchPromise.abort();
  };

  const handleSelectItem = (id: string, type: ResourceType) => {
    if (id === selectedItemId) return;
    cancelRequests();
    dispatch(setSelectedId(id));
    dispatch(setCurrentFolder(null));
    dispatch(setCurrentFile(null));

    if (type === ResourceType.FILE) setFileFetchPromise(dispatch(fetchGetFileInfo({ id })));
    if (type === ResourceType.FOLDER) setFolderFetchPromise(dispatch(fetchGetFolderInfo({ id })));
  };

  const handleSubmitItem = (id: string, type: ResourceType) => {
    if (type === ResourceType.FOLDER) {
      cancelRequests();
      dispatch(setCurrentFolder(null));
      dispatch(setSelectedId(null));
      dispatch(setMedia(null));
      dispatch(setCurrentFile(null));

      navigate(`${PLUGIN_PATHS[PluginCode.MEDIA]}/${id}`);
    }
  };

  const getResource = (item: IFolderType | IFileType) => {
    return item.resourceType === ResourceType.FOLDER ? item.folderFields : item.fileFields;
  };

  return (
    <ScrollArea.Autosize
      h="100%"
      onClick={(e) => clickOutsideItemHandler(e)}
      offsetScrollbars
      viewportRef={viewportRef}
      onScrollPositionChange={onScrollPositionChange}
      styles={() => ({
        scrollbar: {
          '&[data-orientation="horizontal"] .mantine-ScrollArea-thumb': {
            opacity: 0,
          },
        },
      })}
    >
      <SimpleGrid cols={3} spacing="md" p="md">
        {items.map((item) => (
          <MediaItem
            key={getResource(item).id}
            type={item.resourceType}
            item={getResource(item)}
            onSelect={handleSelectItem}
            onSubmit={handleSubmitItem}
            selected={getResource(item).id === selectedItemId}
          />
        ))}
      </SimpleGrid>
    </ScrollArea.Autosize>
  );
};

export default React.memo(MediaGroup);
