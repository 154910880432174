import { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Grid,
  Group,
  Overlay,
  ScrollArea,
  Text,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { IconChevronLeft, IconRefresh } from '@tabler/icons-react';

import { IBreadcrumb, IFileType, IFolderType, Media, MediaId, TMediaRes } from '@/types';
import { ResourceType, ServiceCode } from '@/types/common/enums';

import { LoaderOverlay } from '@/ui/molecules/LoaderOverlay/LoaderOverlay';

import { getAllFolders, getFolder } from '../../utils/mediaRequests';
import FileInputMediaElement from '../FileInputMediaElement/FileInputMediaElement';

const LIBRARY_EXPLORER_DEFAULT_TITLE = 'Выгрузка из медиабиблиотеки';

interface FileInputLibraryExplorerProps {
  serviceCode: ServiceCode;
  onFileSelected: (value: Media<boolean>[]) => any;
  rootFolderId?: string;
  multiple?: boolean;
  maxFiles?: number;
  multimedia?: boolean;
  permissions?: string[];
}

const FileInputLibraryExplorer: FC<FileInputLibraryExplorerProps> = ({
  rootFolderId = null,
  serviceCode,
  onFileSelected,
  multiple = false,
  maxFiles = 100,
  multimedia = false,
  permissions = [],
}) => {
  const [allFolders, setFolders] = useState<IFolderType[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentFolder, setCurrentFolder] = useState(rootFolderId);
  const [, setFolderName] = useState<string>(LIBRARY_EXPLORER_DEFAULT_TITLE);
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);
  const [contents, setContents] = useState<(IFileType | IFolderType)[]>([]);
  const [loading, setLoading] = useState(false);
  const [preselect, setPreselect] = useState<Media<typeof multimedia>[]>([]);
  const [atBottom, setAtBottom] = useState(false);
  const { colors } = useMantineTheme();
  const listRef = useRef<HTMLDivElement>(null);

  const filteringPermissions = (folderData: TMediaRes, extensions: string[]) => {
    const newData: TMediaRes = { breadcrumbs: [], items: [], total: 0 };
    if (extensions.length > 0) {
      newData.breadcrumbs = folderData.breadcrumbs;
      newData.items = folderData.items.filter((item) => {
        if (item.resourceType === ResourceType.FILE) {
          if (extensions.includes(`.${item.fileFields.ext.toLowerCase()}`)) {
            return item;
          } else {
            return false;
          }
        } else {
          return item;
        }
      });
      newData.total = newData.items.length;

      return newData;
    } else {
      return folderData;
    }
  };

  const fetchContents = async (offset = 0) => {
    setLoading(true);

    const folderData = await getFolder(serviceCode, currentFolder, offset);

    if (folderData) {
      const data = filteringPermissions(folderData, permissions);

      setBreadcrumbs(data.breadcrumbs);
      setTotalItems(data.total);
      setContents((currentContents) => {
        return [...currentContents, ...data.items];
      });

      if (data.breadcrumbs.length) {
        setFolderName(data.breadcrumbs.slice(-1)[0].name);
      } else {
        setFolderName(LIBRARY_EXPLORER_DEFAULT_TITLE);
      }
    }

    setLoading(false);
  };

  const fetchFoldersStructure = async () => {
    setLoading(true);

    const folders = await getAllFolders(serviceCode);

    if (folders && folders.length) {
      setFolders(
        folders.map((folder) => ({ resourceType: ResourceType.FOLDER, folderFields: folder }))
      );
    }

    setLoading(false);
  };

  const handleSelect = (media: Media<typeof multimedia>, type: ResourceType) => {
    if (type === ResourceType.FOLDER) {
      if (multimedia) {
        setCurrentFolder((media as Media<true>).id);
      } else {
        setCurrentFolder(media as Media<false>);
      }
    }

    if (type === ResourceType.FILE) onFileSelected([media]);
  };

  const handlePreselect = (media: Media<typeof multimedia>) => {
    if (preselect.includes(media)) {
      setPreselect(preselect.filter((item) => item !== media));
      return;
    }

    if (maxFiles > preselect.length) {
      if (multiple) {
        setPreselect([...preselect, media]);
      } else {
        setPreselect([media]);
      }
    }
  };

  const handleBack = () => {
    if (breadcrumbs.length < 2) setCurrentFolder(null);
    else setCurrentFolder(breadcrumbs.slice(-2)[0].folderId);
  };

  const isSelect = (id: MediaId) => {
    if (multimedia) {
      return preselect.filter((item) => (item as Media<true>).id === id).length !== 0;
    } else {
      return preselect.includes(id);
    }
  };

  const currentFolderTree = useMemo(
    () => allFolders.filter((folder) => folder.folderFields.parentFolderId === currentFolder),
    [currentFolder, allFolders]
  );

  const displayItems = useMemo(() => {
    const files = contents.filter((item) => item.resourceType === ResourceType.FILE);

    return [...currentFolderTree, ...files];
  }, [contents, currentFolderTree]);

  const scrollHandler = (e: { x: number; y: number }) => {
    if (!listRef.current) return;

    const contentsHeight = listRef.current.children[0].clientHeight;
    const viewportHeight = listRef.current.clientHeight;

    if (contentsHeight - e.y < viewportHeight) setAtBottom(true);
    else setAtBottom(false);
  };

  useEffect(() => {
    setContents([]);
    setPreselect([]);
    fetchContents(0);
  }, [currentFolder]);

  useEffect(() => {
    if (!atBottom || loading || totalItems <= contents.length) return;

    fetchContents(contents.length);
  }, [atBottom]);

  useEffect(() => {
    fetchFoldersStructure();
  }, []);

  return (
    <Flex pos="relative" h="60vh" justify="space-between" direction="column" gap={20}>
      {breadcrumbs.length > 0 && (
        <UnstyledButton onClick={handleBack}>
          <IconChevronLeft color={colors['science-blue'][9]} />
        </UnstyledButton>
      )}
      <ScrollArea
        mah="85%"
        h="100%"
        onScrollPositionChange={scrollHandler}
        viewportRef={listRef}
        pos="relative"
        offsetScrollbars
        scrollbars="y"
      >
        <Grid gutter={10} h="100%" w="100%">
          {displayItems.map((item) => (
            <Grid.Col
              span={4}
              key={
                item.resourceType === ResourceType.FILE ? item.fileFields.id : item.folderFields.id
              }
            >
              {item.resourceType === ResourceType.FILE ? (
                <FileInputMediaElement
                  resourceType={item.resourceType}
                  id={item.fileFields.id}
                  name={item.fileFields.name}
                  service={serviceCode}
                  onSelect={handleSelect}
                  onPreselect={handlePreselect}
                  selected={isSelect(item.fileFields.id)}
                  bg={colors.gray[1]}
                  selectable
                  multimedia={multimedia}
                />
              ) : (
                <FileInputMediaElement
                  resourceType={item.resourceType}
                  id={item.folderFields.id}
                  name={item.folderFields.name}
                  service={serviceCode}
                  onSelect={handleSelect}
                  bg={colors.gray[1]}
                  selectable
                  multimedia={multimedia}
                />
              )}
            </Grid.Col>
          ))}
          {!displayItems.length && (
            <Overlay
              sx={{
                display: 'flex',
                backgroundColor: 'transparent',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text c="dimmed">Эта папка пуста</Text>
            </Overlay>
          )}
        </Grid>
      </ScrollArea>

      <Flex
        mih={50}
        gap="sm"
        sx={{
          borderTop: `1px solid ${colors.gray[2]}`,
        }}
        align="flex-end"
      >
        {preselect.length > 0 && (
          <Group align="center">
            <Box>
              <Text display="inline" c={colors.gray[5]}>
                Выбрано:{' '}
              </Text>
              <Text display="inline">{preselect.length}</Text>
            </Box>
            <Button
              variant="subtle"
              onClick={() => setPreselect([])}
              rightSection={<IconRefresh />}
            >
              Сбросить
            </Button>
          </Group>
        )}
        <Button
          disabled={!preselect.length}
          onClick={() => onFileSelected(preselect)}
          ml="auto"
          px="xl"
        >
          Загрузить
        </Button>
      </Flex>

      <LoaderOverlay visible={loading} />
    </Flex>
  );
};

export default FileInputLibraryExplorer;
