import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useBlockingDispatch } from '@/hooks/useBlockingDispatch';

import {
  fetchDelMenuItemAction,
  fetchGetMenuItemsAction,
  fetchGetMenuItemsChildrenAction,
  selectCurrentPath,
  selectDelMenuItemsModal,
  selectEditMenuItemModal,
  selectMenuItem,
  selectParentId,
  selectTreeData,
  setDelMenuItemModalOpened,
  setEditMenuItemModalOpened,
  setFullMenuItem,
} from '@/store/slices/menuPlugin/menuItems';
import { selectCurrentService } from '@/store/slices/service/service';

import BaseModal from '@/ui/molecules/BaseModal/BaseModal';
import ModalConfirm from '@/ui/molecules/ModalConfirm/ModalConfirm';

import FormNewItems from '../FormNewItems/FormNewItems';

const MenuDropdownModals = () => {
  const { dispatch, blocked } = useBlockingDispatch();
  const params = useParams();
  const menuItem = useSelector(selectMenuItem);
  const editMenuItemModal = useSelector(selectEditMenuItemModal);
  const delMenuItemModal = useSelector(selectDelMenuItemsModal);
  const service = useSelector(selectCurrentService);
  const parentId = useSelector(selectParentId);
  const treeData = useSelector(selectTreeData);
  const currentPath = useSelector(selectCurrentPath);

  const delConfirmHandler = async () => {
    const { menuId } = params;
    const menuItemId = menuItem?.id;

    if (menuId && menuItemId && service) {
      await dispatch(fetchDelMenuItemAction({ menuId, menuItemId }));

      if (parentId) {
        await dispatch(
          fetchGetMenuItemsChildrenAction({
            id: menuId,
            oldTreeData: treeData,
            path: currentPath,
            params: { parentMenuItemId: parentId },
            itemId: parentId,
          })
        );
      } else await dispatch(fetchGetMenuItemsAction({ id: menuId }));
    }
  };

  return (
    <>
      <ModalConfirm
        title="Вы уверены?"
        text="Восстановить данные после удаления не получится"
        opened={delMenuItemModal}
        onClose={() => dispatch(setDelMenuItemModalOpened(false))}
        confirmHandler={() => delConfirmHandler()}
        blocked={blocked}
        closeOnClickOutside={!blocked}
        closeOnEscape={!blocked}
      />

      <BaseModal
        opened={editMenuItemModal}
        onClose={() => {
          dispatch(setEditMenuItemModalOpened(false));
          dispatch(setFullMenuItem({}));
        }}
        closeOnClickOutside={!blocked}
        closeOnEscape={!blocked}
        title="Изменение пункта меню"
        subtitle={`ID ${menuItem?.id}`}
      >
        <FormNewItems type="edit" element={menuItem} />
      </BaseModal>
    </>
  );
};

export default MenuDropdownModals;
