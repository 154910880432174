import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { IconDoorExit } from '@tabler/icons-react';

import { AppDispatch } from '@/store';
import { logout } from '@/store/slices/auth/auth';

import LayoutButton from '../LayoutButton/LayoutButton';
import LayoutSelect from '../LayoutSelect/LayoutSelect';

interface LayoutFooterProps {
  collapse: boolean;
}

const LayoutFooter: FC<LayoutFooterProps> = ({ collapse }) => {
  const dispatch: AppDispatch = useDispatch();

  const handleLogOut = () => {
    dispatch(logout(null));
  };

  return (
    <>
      <LayoutSelect collapse={collapse} />

      <LayoutButton
        onClick={handleLogOut}
        collapse={collapse}
        label="Выйти"
        leftSection={<IconDoorExit size={24} />}
      />
    </>
  );
};

export default LayoutFooter;
