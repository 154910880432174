import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, u) => ({
  root: {
    paddingBottom: '12px',
    paddingRight: '12px',
  },

  header: {
    position: 'sticky',
    top: 0,
    [u.dark]: {
      backgroundColor: theme.colors.dark[7],
    },
    [u.light]: {
      backgroundColor: theme.colors.gray[2],
    },
    transition: 'box-shadow 150ms ease',
    zIndex: 1,

    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `1px solid ${theme.colors.gray[2]}`,
      [u.dark]: {
        borderBottomColor: theme.colors.dark[3],
      },
      [u.light]: {
        borderBottomColor: theme.colors.gray[2],
      },
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },
}));
