import { FC } from 'react';
import { Burger, Group, Text, UnstyledButton, useMantineTheme } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { useHover } from '@mantine/hooks';

const useStyles = createStyles((theme) => ({
  root: {
    width: '100%',
    fill: '#bfcedb',
    overflow: 'hidden',
    borderRight: `4px solid white`,
  },

  label: {
    color: '#121212',
    paddingBottom: 2,
    transition: 'color 0.25s ease-in-out',

    '&:hover': {
      color: `${theme.colors['science-blue'][9]}`,
    },
  },
}));

interface LayoutBurgerProps {
  collapse: boolean;
  onClick?: () => void;
}

const LayoutBurger: FC<LayoutBurgerProps> = ({ collapse, onClick }) => {
  const title = collapse ? 'Свернуть' : 'Развернуть';
  const { colors } = useMantineTheme();
  const { hovered, ref } = useHover();
  const { classes } = useStyles();

  return (
    <div ref={ref}>
      <Group gap={0} wrap="nowrap" pl={22} sx={{ alignItems: 'center' }} className={classes.root}>
        <Burger
          color={hovered ? colors['science-blue'][9] : '#bfcedb'}
          opened={collapse}
          title={title}
          size={'sm'}
          onClick={onClick}
        />
        {collapse && (
          <UnstyledButton pl={17} title={title} onClick={onClick} className={classes.label}>
            <Text lineClamp={1} truncate>
              {title}
            </Text>
          </UnstyledButton>
        )}
      </Group>
    </div>
  );
};

export default LayoutBurger;
