import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Modal, Text } from '@mantine/core';

import { useQueryParams } from '@/hooks/useQueryParams';
import { useURLPagination } from '@/hooks/useUrlPagination';

import { AppDispatch } from '@/store';
import {
  fetchDelConfigurationAction,
  selectDelConfigurationModal,
  selectEditConfigurationModal,
  selectFetchingDelConfiguration,
  setEditConfigurationModalOpen,
  setOpenDelConfigurationModal,
} from '@/store/slices/configurationPlugin/configuration';
import {
  selectFillConfigurationOptionsModal,
  setFillConfigurationOptionsModalOpen,
} from '@/store/slices/configurationPlugin/configurationOptions';
import {
  fetchConfigurationsAction,
  selectConfigurationsTotal,
  selectCurrentConfiguration,
} from '@/store/slices/configurationPlugin/configurations';
import { selectCurrentService } from '@/store/slices/service/service';

import ModalConfirm from '@/ui/molecules/ModalConfirm/ModalConfirm';

import ConfigurationsForm from '../ConfigurationsForm/ConfigurationsForm';
import ConfigurationsModal from '../ConfigurationsModal/ConfigurationsModal';

interface ConfigurationsFormProps {
  onReload?: any;
}

const ConfigurationsTableModals: FC<ConfigurationsFormProps> = ({ onReload }) => {
  const dispatch: AppDispatch = useDispatch();
  const currentConfiguration = useSelector(selectCurrentConfiguration);
  const configurationsTotal = useSelector(selectConfigurationsTotal);
  const configurationsModalFill = useSelector(selectFillConfigurationOptionsModal);
  const configurationsModalDel = useSelector(selectDelConfigurationModal);
  const configurationsModalEdit = useSelector(selectEditConfigurationModal);
  const service = useSelector(selectCurrentService);
  const { currentOrder, currentSort } = useQueryParams();
  const { currentLimit, currentOffset } = useURLPagination(configurationsTotal || 0);
  const isDeleting = useSelector(selectFetchingDelConfiguration);

  const delConfirmHandler = async () => {
    if (currentConfiguration && service) {
      await dispatch(fetchDelConfigurationAction({ id: currentConfiguration.id }));
    }
    if (service) {
      await dispatch(
        fetchConfigurationsAction({
          params: {
            offset: currentOffset,
            limit: currentLimit,
            sort: currentSort,
            order: currentOrder,
          },
        })
      );
    }

    onReload();
  };

  return (
    <>
      <ModalConfirm
        title="Вы уверены?"
        text="Восстановить данные после удаления не получится"
        opened={configurationsModalDel}
        onClose={() => dispatch(setOpenDelConfigurationModal(false))}
        confirmHandler={delConfirmHandler}
        blocked={isDeleting}
      />

      <Modal
        centered
        size={'lg'}
        opened={configurationsModalEdit}
        onClose={() => dispatch(setEditConfigurationModalOpen(false))}
        title={
          <Box>
            <Text fz={22} fw={700}>
              Изменения конфигурации
            </Text>
            <Text
              sx={(theme) => ({ fontWeight: 700, color: theme.colors.gray[6] })}
            >{`ID ${currentConfiguration?.id}`}</Text>
          </Box>
        }
      >
        <ConfigurationsForm type="edit" element={currentConfiguration} onReload={onReload} />
      </Modal>

      <ConfigurationsModal
        title="Настройка конфигурации"
        confId={currentConfiguration?.id as string}
        opened={configurationsModalFill}
        onClose={() => dispatch(setFillConfigurationOptionsModalOpen(false))}
      />
    </>
  );
};

export default ConfigurationsTableModals;
