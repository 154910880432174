import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Modal, Text } from '@mantine/core';

import { AppDispatch } from '@/store';
import { selectEditMenuModal, setEditMenuModalOpen } from '@/store/slices/menuPlugin/menu';
import { selectCurrentMenu } from '@/store/slices/menuPlugin/menus';

import MenusFormNew from '../MenusFormNew/MenusFormNew';

const MenusModalEditMenu: FC = () => {
  const menusModalEdit = useSelector(selectEditMenuModal);
  const dispatch: AppDispatch = useDispatch();
  const currentMenu = useSelector(selectCurrentMenu);

  return (
    <Modal
      centered
      size="lg"
      opened={menusModalEdit}
      onClose={() => dispatch(setEditMenuModalOpen(false))}
      title={
        <Box>
          <Text fz={22} fw={700}>
            Изменение меню
          </Text>
          <Text
            sx={(theme) => ({ fontWeight: 700, color: theme.colors.gray[6] })}
          >{`ID ${currentMenu?.id}`}</Text>
        </Box>
      }
    >
      <MenusFormNew type="edit" element={currentMenu} />
    </Modal>
  );
};

export default MenusModalEditMenu;
