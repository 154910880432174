import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ActionIcon, Group } from '@mantine/core';
import { IconEye, IconPencil, IconTrash } from '@tabler/icons-react';

import { AppDispatch } from '@/store';
import {
  fetchGetModelElementAction,
  fetchGetModelElementPreviewAction,
  selectFetchingGetModelElement,
  selectFetchingModelElement,
  setCurrentModelElement,
  setOpenDelModelElementModal,
  setOpenEditModelElementModal,
} from '@/store/slices/modelsPlugin/model';
import { selectCurrentService } from '@/store/slices/service/service';

const ModelTableButtons: FC = ({ ...props }) => {
  const dispatch: AppDispatch = useDispatch();
  const urlParams = useParams();
  const modelCode = urlParams.modelCode;
  const service = useSelector(selectCurrentService);
  const fetchingModelElement = useSelector(selectFetchingGetModelElement);
  const fetchingPreview = useSelector(selectFetchingModelElement);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(false);

  const changeHandler = async () => {
    setLoadingEdit(true);
    const { id: modelElementId } = props as Record<string, string>;

    if (modelCode && service) {
      await dispatch(
        fetchGetModelElementAction({
          modelCode,
          modelElementId,
        })
      ).finally(() => setLoadingEdit(false));
      dispatch(setCurrentModelElement({ ...props }));
      dispatch(setOpenEditModelElementModal(true));
    }
  };

  const deleteHandler = () => {
    dispatch(setCurrentModelElement({ ...props }));
    dispatch(setOpenDelModelElementModal(true));
  };

  const previewHandler = async () => {
    setLoadingPreview(true);
    const { id: modelElementId } = props as Record<string, string>;

    if (modelCode && service) {
      await dispatch(
        fetchGetModelElementPreviewAction({
          modelCode,
          modelElementId,
        })
      ).finally(() => setLoadingPreview(false));
    }
  };

  const hasPreviewButton = (code: string): boolean => {
    switch (code) {
      case 'promo':
      case 'content-pages':
      case 'ref-brend':
        return true;
      default:
        return false;
    }
  };

  return (
    <Group justify={'right'} wrap="nowrap">
      {modelCode && hasPreviewButton(modelCode) && (
        <ActionIcon
          variant="subtle"
          color="gray"
          loading={loadingPreview}
          name="Предварительный просмотр"
          title="Предварительный просмотр"
          onClick={previewHandler}
          disabled={fetchingModelElement || fetchingPreview}
        >
          <IconEye size={20} color="gray" />
        </ActionIcon>
      )}
      <ActionIcon
        variant="subtle"
        color="gray"
        loading={loadingEdit}
        name="Изменить"
        title="Изменить"
        disabled={fetchingModelElement || fetchingPreview}
        onClick={changeHandler}
      >
        <IconPencil size={20} color="gray" />
      </ActionIcon>

      <ActionIcon
        variant="subtle"
        color="gray"
        name="Удалить"
        disabled={fetchingModelElement || fetchingPreview}
        title="Удалить"
        onClick={deleteHandler}
      >
        <IconTrash size={20} color="gray" />
      </ActionIcon>
    </Group>
  );
};

export default ModelTableButtons;
