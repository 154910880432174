import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Group } from '@mantine/core';

import { TABLE_MODELS } from '@/constants/modelsPlugin/tableHeaders';

import { useItemsIsEmptyMemo } from '@/hooks/useItemsIsEmptyMemo';
import { useServices } from '@/hooks/useServices';
import { useURLPagination } from '@/hooks/useUrlPagination';

import { AppDispatch } from '@/store';
import {
  fetchGetModelsAction,
  selectFetchingGetModels,
  selectModels,
  selectModelsTotal,
  setModels,
} from '@/store/slices/modelsPlugin/models';

import PageBody from '@/ui/templates/Page/components/PageBody/PageBody';
import PageContainer from '@/ui/templates/Page/components/PageContainer/PageContainer';
import PageFooter from '@/ui/templates/Page/components/PageFooter/PageFooter';
import PageHeader from '@/ui/templates/Page/components/PageHeader/PageHeader';
import PagePagination from '@/ui/templates/Page/components/PagePagination/PagePagination';
import Page from '@/ui/templates/Page/Page';

import ShowElements from '@/ui/organisms/ShowElements/ShowElements';
import TableExt from '@/ui/organisms/TableExt/TableExt';

import ModelsTableButtons from '../ModelsContainer/components/ModelsTableButtons/ModelsTableButtons';

const ModelsContainer: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const models = useSelector(selectModels);
  const modelsTotal = useSelector(selectModelsTotal);
  const modelsFetching = useSelector(selectFetchingGetModels);
  const { currentService } = useServices();
  const { currentLimit, currentOffset, currentPage, pagesCount, setLimit, setPage } =
    useURLPagination(modelsTotal);

  const modelsIsEmpty = useItemsIsEmptyMemo(models);

  useEffect(() => {
    dispatch(fetchGetModelsAction({ limit: currentLimit, offset: currentOffset }));

    return () => {
      dispatch(setModels(null));
    };
  }, [currentLimit, currentOffset, currentService]);

  return (
    <Page>
      <PageHeader title="Модели" />
      <PageBody>
        <Group mb={24} justify="right" grow>
          <ShowElements defaultValue={currentLimit} changeCallback={setLimit} />
        </Group>

        <PageContainer
          isEmpty={modelsIsEmpty}
          loading={modelsFetching}
          loaderText={'У вас пока нет моделей'}
        >
          <TableExt config={TABLE_MODELS} rows={models} buttons={ModelsTableButtons} />
        </PageContainer>

        <PageFooter>
          <PagePagination total={pagesCount} value={currentPage} onChange={setPage} />
        </PageFooter>
      </PageBody>
    </Page>
  );
};

export default ModelsContainer;
