import { FC } from 'react';
import { Box, Text } from '@mantine/core';

const LayoutVersion: FC = () => {
  return (
    <Box pl={24} mt={10}>
      <Text fz="xs" lineClamp={1} c="dimmed" title="Версия UI">
        {process.env.VERSION}
      </Text>
    </Box>
  );
};

export default LayoutVersion;
