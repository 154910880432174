import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Modal, Text } from '@mantine/core';

import { REVIEWS_IMPORT_TEMPLATE_URL } from '@/constants/reviewsPlugin/reviewsPlugin';

import { AppDispatch } from '@/store';
import {
  fetchReviewsDownloadTempalteAction,
  selectReviewsImportModal,
  setOpenReviewsImportModal,
} from '@/store/slices/reviewsPlugin/reviews';

import ReviewsImportForm from '../ReviewsImportForm/ReviewsImportForm';
import ReviewsModalLink from '../ReviewsModalLink/ReviewsModalLink';

const ReviewsImportModal: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const reviewsImportModal = useSelector(selectReviewsImportModal);

  return (
    <Modal
      centered
      size={'lg'}
      opened={reviewsImportModal}
      onClose={() => dispatch(setOpenReviewsImportModal(false))}
      title={
        <Text fw={700} fz="xl">
          Загрузка отзывов
        </Text>
      }
    >
      <Text fz={14} mb={24}>
        Для загрузки отзывов{' '}
        <ReviewsModalLink onClick={() => dispatch(fetchReviewsDownloadTempalteAction())}>
          скачайте шаблон (csv)
        </ReviewsModalLink>
        , заполните его, загрузите готовый файл (csv)
        <br />{' '}
        <ReviewsModalLink type="link" to={REVIEWS_IMPORT_TEMPLATE_URL}>
          в раздел Медиа, папку “Отзывы”
        </ReviewsModalLink>{' '}
        и прикрепите загруженный файл здесь.
      </Text>

      <Text fz={14} mb={24}>
        Если загружаемые отзывы содержат фото или видео, то загрузите архив под названием
        “review_files.zip“ с новыми фото и видео, нажав на кнопку “Загрузить архив“ на этой
        странице. После загрузки нового архива, старый будет удален, но фото и видео у существующих
        отзывов не удалятся.
      </Text>

      <ReviewsImportForm />
    </Modal>
  );
};

export default ReviewsImportModal;
