import { FC, useContext, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Button, FileButton, Flex, Group } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconCloudDownload, IconFolder } from '@tabler/icons-react';

import { FileSizeEnum } from '@/types';

import { validationTexts } from '@/constants/common/validationTexts';

import { getFileSize } from '@/utils/getFileSize/getFileSize';
import { getFilesSize } from '@/utils/getFilesSize/getFilesSize';
import notify from '@/utils/notify';

import { AppDispatch } from '@/store';
import { setMoveInFolder } from '@/store/slices/mediaPlugin/media';
import {
  fetchAddFiles,
  fetchDelFile,
  selectCurrentFile,
  selectDelFileModal,
  selectMoveFoleModal,
  setDelFileModalOpened,
  setMoveFileModalOpened,
} from '@/store/slices/mediaPlugin/mediaFiles';
import {
  fetchDelFolder,
  selectCurrentFolder,
  selectDelFolderModal,
  setAddFolderModalOpened,
  setDelFolderModalOpened,
} from '@/store/slices/mediaPlugin/mediaFolders';
import { selectCurrentService } from '@/store/slices/service/service';

import ActionButtons from '@/ui/pages/MediaContainer/components/MediaButtons/components/ActionButtons/ActionButtons';

import ModalConfirm from '@/ui/molecules/ModalConfirm/ModalConfirm';

import { MediaContext } from '../../utils/mediaContext';
import ModalAddFolder from '../ModalAddFolder/ModalAddFolder';
import ModalMove from '../ModalMove/ModalMove';
import ModalRenameFile from '../ModalRenameFile/ModalRenameFile';
import ModalRenameFolder from '../ModalRenameFolder/ModalRenameFolder';

const MediaButtons: FC = () => {
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();
  const { onReload } = useContext(MediaContext);
  const resetRef = useRef<() => void>(null);
  const mqMatch = useMediaQuery('(min-width: 1445px)');

  const { folderId } = useParams();
  const currentFolder = useSelector(selectCurrentFolder);
  const currentFile = useSelector(selectCurrentFile);
  const delFolderModal = useSelector(selectDelFolderModal);
  const delFileModal = useSelector(selectDelFileModal);
  const moveFileModal = useSelector(selectMoveFoleModal);
  const service = useSelector(selectCurrentService);

  const clearFile = () => {
    resetRef.current?.();
  };

  const addFilesHandler = async (files: File[]) => {
    const filteredFiles = files.filter((file) => getFileSize(file.size, FileSizeEnum.MB) < 10);
    const firstTenFiles = filteredFiles.slice(0, 10);

    if (getFilesSize(firstTenFiles, FileSizeEnum.MB) < 100 && service) {
      await dispatch(fetchAddFiles({ files: firstTenFiles, folderId: folderId }));
      onReload();
      clearFile();
    } else {
      notify({ message: validationTexts.MAX_SIZE(100), type: 'error' });
    }
  };

  const delConfirmHandler = async () => {
    if (currentFile?.id && service) {
      setIsRemoving(true);
      await dispatch(fetchDelFile({ id: currentFile.id }));
      setIsRemoving(false);
    }
    if (currentFolder?.id && service) {
      setIsRemoving(true);
      await dispatch(fetchDelFolder({ id: currentFolder?.id }));
      setIsRemoving(false);
    }
    onReload();
  };

  return (
    <Box
      sx={(theme) => ({
        background: theme.colors.gray[2],
        borderRadius: 6,
        padding: 16,
      })}
    >
      <Flex justify="space-between" wrap="wrap" mb={-8}>
        <Group gap={mqMatch ? 8 : 5} mb={8}>
          <FileButton resetRef={resetRef} onChange={addFilesHandler} name="files" multiple>
            {(props) => (
              <Button
                title="Загрузить"
                leftSection={mqMatch ? <IconCloudDownload size={20} /> : null}
                miw={168}
                {...props}
              >
                Загрузить
              </Button>
            )}
          </FileButton>
          <Button
            title="Создать папку"
            leftSection={mqMatch ? <IconFolder size={20} /> : null}
            onClick={() => dispatch(setAddFolderModalOpened(true))}
            miw={168}
          >
            Создать папку
          </Button>
        </Group>

        <ActionButtons />
      </Flex>

      <ModalConfirm
        title="Вы уверены?"
        text="Восстановить данные после удаления не получится"
        opened={delFolderModal || delFileModal}
        onClose={() => {
          dispatch(setDelFolderModalOpened(false));
          dispatch(setDelFileModalOpened(false));
        }}
        blocked={isRemoving}
        confirmHandler={delConfirmHandler}
      />

      <ModalAddFolder />
      <ModalRenameFolder />
      <ModalRenameFile />
      <ModalMove
        opened={moveFileModal}
        onClose={() => {
          dispatch(setMoveFileModalOpened(false));
          dispatch(setMoveInFolder(null));
        }}
      />
    </Box>
  );
};

export default MediaButtons;
