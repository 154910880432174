export enum FileSizeEnum {
  KB = 'kb',
  MB = 'mb',
  GB = 'gb',
  TB = 'tb',
}

export enum HTTPMethodEnum {
  CONNECT = 'CONNECT',
  DELETE = 'DELETE',
  GET = 'GET',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  TRACE = 'TRACE',
}

export enum SortOrderEnum {
  ASC = 'asc',
  DESC = 'desc',
}

export enum DataTypeEnum {
  STRING = 'string',
  INT = 'int',
  UINT = 'uint',
  FLOAT = 'float',
  BOOL = 'bool',
  TIME = 'time',
}

export enum FormFieldTypeEnum {
  INPUT = 'input',
  TEXTAREA = 'textarea',
  SELECT = 'select',
  EDITORJS = 'editorJs',
}

export enum TypeEnum {
  STRING = 'string',
  TEXT = 'text',
  CHECKBOX = 'checkbox',
  FILE = 'file',
  IMAGE = 'image',
  DATE = 'date',
  DATETIME = 'datetime',
  KEY_VALUES = 'string',
  INTEGER = 'integer',
}

export enum PluginCode {
  MODELS = 'models',
  MODELS_V2 = 'models-v2',
  MEDIA = 'media',
  MENUS = 'menus',
  CONFIGURATIONS = 'configurations',
  MAIL_TEMPLATES = 'mail-templates',
  REVIEWS = 'reviews',
}

export enum ResourceType {
  FILE = 'file',
  FOLDER = 'folder',
}

export enum ServiceCode {
  GEO = 'geo',
  DEMO = 'demo',
  INTEGRATIONS = 'integrations',
  CATALOG = 'catalog',
  CLIENTS = 'clients',
  GOCLIENTS = 'go-clients',
  CONTENT = 'content',
  REVIEWS = 'reviews',
  SALE = 'sale',
  SELLER = 'seller',
  EXPORT = 'export',
}

export enum FilterViewTypeEnum {
  NUMERIC = 'numeric',
  MULTISELECT = 'multiselect',
  DATE = 'datetime',
  DATE_RANGE = 'daterange',
  CHECKBOX = 'checkbox',
  STRING = 'string',
  INT = 'int',
}
