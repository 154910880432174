import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Center, Flex, Grid, Text, Title } from '@mantine/core';

const NotFound: FC = () => {
  const navigate = useNavigate();

  return (
    <Grid gutter={0}>
      <Grid.Col
        span={6}
        sx={{
          background: 'url(../images/notFoundImage.png) no-repeat center',
          backgroundSize: 'contain',
        }}
      ></Grid.Col>
      <Grid.Col span={6}>
        <Center maw="40vw" mih="100vh">
          <Flex gap={24} direction="column">
            <Title>Что-то, кажется, пошло не так...</Title>
            <Text>
              Страница, которую вы ищете, не существует. Возможно, она устарела, была удалена, или
              был введен неверный адрес в адресной строке.
            </Text>
            <Button onClick={() => navigate('/.')}>Вернуться на главную</Button>
          </Flex>
        </Center>
      </Grid.Col>
    </Grid>
  );
};

export default NotFound;
