import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Button, Group, Modal, Text } from '@mantine/core';
import qs from 'qs';

import { PluginCode } from '@/types';

import { TABLE_CONFIGURATIONS } from '@/constants/configurationsPlugin/tableHeaders';

import { useItemsIsEmptyMemo } from '@/hooks/useItemsIsEmptyMemo';
import { useQueryParams } from '@/hooks/useQueryParams';
import { useServices } from '@/hooks/useServices';
import { useURLPagination } from '@/hooks/useUrlPagination';

import { AppDispatch } from '@/store';
import {
  selectDelConfigurationModal,
  selectEditConfigurationModal,
} from '@/store/slices/configurationPlugin/configuration';
import {
  fetchConfigurationsAction,
  selectConfigurationsItems,
  selectConfigurationsTotal,
  selectFetchingConfigurationsStatus,
  selectModalNewConfiguration,
  setModalNewConfigurationsOpened,
} from '@/store/slices/configurationPlugin/configurations';

import PageBody from '@/ui/templates/Page/components/PageBody/PageBody';
import PageContainer from '@/ui/templates/Page/components/PageContainer/PageContainer';
import PageFooter from '@/ui/templates/Page/components/PageFooter/PageFooter';
import PageHeader from '@/ui/templates/Page/components/PageHeader/PageHeader';
import PagePagination from '@/ui/templates/Page/components/PagePagination/PagePagination';
import Page from '@/ui/templates/Page/Page';

import SearchInput from '@/ui/organisms/SearchInput/SearchInput';
import ShowElements from '@/ui/organisms/ShowElements/ShowElements';
import TableExt from '@/ui/organisms/TableExt/TableExt';

import ConfigurationsForm from './components/ConfigurationsForm/ConfigurationsForm';
import ConfigurationsTableButtons from './components/ConfigurationsTableButtons/ConfigurationsTableButtons';
import ConfigurationsTableModals from './components/ConfigurationsTableModals/ConfigurationsTableModals';

const ConfigurationsContainer: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const configurationsItems = useSelector(selectConfigurationsItems);
  const configurationsTotal = useSelector(selectConfigurationsTotal);
  const configurationsFetch = useSelector(selectFetchingConfigurationsStatus);
  const configurationsModalNew = useSelector(selectModalNewConfiguration);
  const configurationsModalDel = useSelector(selectDelConfigurationModal);
  const configurationsModalEdit = useSelector(selectEditConfigurationModal);
  const { availablePlugins } = useServices();
  const { currentPage, pagesCount, setPage, currentLimit, currentOffset, setLimit } =
    useURLPagination(configurationsTotal);
  const { currentQuery, currentOrder, currentSort } = useQueryParams();
  const isConfigsAvailable = useMemo(
    () => availablePlugins.includes(PluginCode.CONFIGURATIONS),
    [availablePlugins]
  );
  const { currentService } = useServices();

  const configurationsIsEmpty = useItemsIsEmptyMemo(configurationsItems);

  const params = {
    ...useMemo(
      () => ({
        limit: currentLimit,
        offset: currentOffset,
      }),
      [currentLimit, currentOffset]
    ),
    order: currentOrder,
    sort: currentSort,
    query: currentQuery,
  };

  const reloadHandler = () => {
    if (!isConfigsAvailable) return;

    dispatch(
      fetchConfigurationsAction({
        params,
      })
    );
  };

  const searchAction = (query: string) => {
    const newParams = {
      ...params,
      offset: 0,
      query,
    };
    const URLParams = qs.stringify(newParams, { indices: false });

    setSearchParams(URLParams);
  };

  // Обновление данных при изменении параметров URL
  useEffect(() => {
    reloadHandler();
  }, [
    searchParams,
    currentService,
    configurationsModalNew,
    configurationsModalDel,
    configurationsModalEdit,
  ]);

  return (
    <Page>
      <PageHeader
        title="Конфигурации"
        rightButton={
          <Button onClick={() => dispatch(setModalNewConfigurationsOpened(true))}>
            Новая конфигурация
          </Button>
        }
      />

      <PageBody>
        <Group mb={24} justify="space-between" grow>
          <SearchInput searchAction={searchAction} defaultValue={currentQuery} />
          <ShowElements defaultValue={params.limit} changeCallback={setLimit} />
        </Group>

        <PageContainer
          isEmpty={configurationsIsEmpty}
          loading={configurationsFetch}
          loaderText={'У вас нет конфигураций'}
        >
          <TableExt
            config={TABLE_CONFIGURATIONS}
            rows={configurationsItems}
            buttons={ConfigurationsTableButtons}
            sortableKeys={['name']}
          />
        </PageContainer>

        <PageFooter>
          <PagePagination total={pagesCount} value={currentPage} onChange={setPage} />
        </PageFooter>
      </PageBody>

      <Modal
        centered
        size={'lg'}
        opened={configurationsModalNew}
        onClose={() => dispatch(setModalNewConfigurationsOpened(false))}
        title={
          <Text fz={22} fw={700}>
            Новая конфигурация
          </Text>
        }
      >
        <ConfigurationsForm type="new" onReload={reloadHandler} />
      </Modal>

      <ConfigurationsTableModals onReload={reloadHandler} />
    </Page>
  );
};

export default ConfigurationsContainer;
