import qs from 'qs';

import {
  IReview,
  IReviewsListRequest,
  ReviewsAnalysisRequest,
  ReviewsModeratorsRequest,
  TElementsRes,
  TQueryParams,
} from '@/types';
import { TObject } from '@/types/modelsPlugin/modelsPlugin_v2';

import api from '../instance';
import { URLS } from '../urls';

export const apiReviewsList = (params: IReviewsListRequest) =>
  api.post<TElementsRes<IReview>>(
    URLS.reviews.reviewsList,
    { ...params.data },
    { params: { ...params.params } }
  );

export const apiReviewsItem = (data: any) => api.get<IReview>(URLS.reviews.getReviewsById(data.id));

export const apiEditReviewItem = (data: any) =>
  api.patch<TElementsRes<IReview>>(URLS.reviews.getEditReviewItemById(data.id), { ...data.data });

export const apiAddReviewItem = (data: any) =>
  api.post<TElementsRes<IReview>>(URLS.reviews.addReview, { ...data });

export const apiGetInactivationOptions = (params: TQueryParams) =>
  api.post<TElementsRes<TObject>>(
    `${URLS.models.modelElements.getModelElementsList('inactivation-reasons')}?${qs.stringify(
      params
    )}`,
    {}
  );

export const apiGetReviewsAnalysis = (data: ReviewsAnalysisRequest) =>
  api.post(`${URLS.reviews.getReviewsAnalysis}`, data);

export const apiGetReviewsDownloadTemplate = () =>
  api.get<{ url: string }>(`${URLS.reviews.getDownloadTemplate}`);

export const apiImportReviewsFromFile = (id: string) =>
  api.post(URLS.reviews.importReviewsFromFile(id));

export const apiImportReviewsFromArchive = (file: File) =>
  api.post(
    URLS.reviews.importReviewsFromArchive,
    { file },
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );

export const apiImportReviewsStopListFromFile = (id: string) =>
  api.post(URLS.reviews.importReviewsStopListFromFile(id));

export const apiDownloadModeratorsStat = (data: ReviewsModeratorsRequest) =>
  api.post(URLS.reviews.downloadModeratorsStat, data);
