import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Button, Flex } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { createStyles } from '@mantine/emotion';
import { useForm } from '@mantine/form';
import { IconCalendar, IconRefresh, IconSearch } from '@tabler/icons-react';
import isEqual from 'lodash/isEqual';
import qs from 'qs';

import { FormFieldTypeEnum, TFormField } from '@/types/modelsPlugin/modelsPlugin_v2';

import { useQueryParams } from '@/hooks/useQueryParams';

import SearchableFilter from '@/ui/organisms/SearchableFilter/SearchableFilter';

import { DateRangeInput } from '@/ui/molecules/DateRangeInput/DateRangeInput';

import { filterIsChange } from './utils/filterIsChange';
import { getInitialValues } from './utils/getInitialValues';
import { getModelFiltersValues } from './utils/getModelFiltersValues';

interface ModelFiltersBuilderProps {
  fields: TFormField[];
}

const useStyles = createStyles({
  form: {
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 15,
  },
});

const ModelFiltersBuilder: FC<ModelFiltersBuilderProps> = ({ fields }) => {
  const { classes } = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentOrder, currentSort, currentLimit } = useQueryParams();
  const initialValues = getInitialValues(fields, searchParams);
  const [hasActiveFilters, setHasActiveFilters] = useState(false);

  const form = useForm({
    initialValues: {
      fields: initialValues,
    },
  });

  const resetFilters = () => {
    setSearchParams({});

    const initialFieldsValues = getInitialValues(fields, new URLSearchParams());

    form.setValues({
      fields: initialFieldsValues,
    });

    setHasActiveFilters(false);
  };

  useEffect(() => {
    if (filterIsChange(initialValues, form.values.fields)) {
      const currentFiltersValues = getModelFiltersValues(form.values.fields);

      const newParams = {
        offset: 0,
        limit: currentLimit,
        sort: currentSort,
        order: currentOrder,
        ...currentFiltersValues,
      };

      const URLParams = qs.stringify(newParams, { indices: false });

      setSearchParams(URLParams);
      setHasActiveFilters(true);
    }
  }, [form.values]);

  useEffect(() => {
    const prevValues = form.values.fields;
    const nextValues = getInitialValues(fields, searchParams);

    if (!isEqual(prevValues, nextValues)) {
      form.setValues({ fields: nextValues });
    }
  }, [searchParams]);

  useEffect(() => {
    const initialFiltersValues = getModelFiltersValues(form.values.fields);
    const hasFilters = Object.keys(initialFiltersValues).some(
      (key) => initialFiltersValues[key] !== undefined && initialFiltersValues[key] !== ''
    );

    setHasActiveFilters(hasFilters);
  }, [form.values]);

  const fieldsView = form.values.fields.map((item, index) => {
    return (
      <Box key={item.code} w={'calc(19% - 28px)'} miw={255}>
        {item.type === FormFieldTypeEnum.SELECT && (
          <SearchableFilter
            field={item}
            label={item.name}
            placeholder={item.name}
            hidden={item.hidden}
            disabled={item.disabled}
            leftSection={item.extra && item.extra.request && <IconSearch size="1.1rem" />}
            cb={(values) => form.setFieldValue(`fields.${index}.value`, values)}
            {...form.getInputProps(`fields.${index}.value`)}
          />
        )}

        {item.type === FormFieldTypeEnum.DATEPICKERINPUT && (
          <DateRangeInput
            label={item.name}
            placeholder={item.name}
            hidden={item.hidden}
            disabled={item.disabled}
            {...form.getInputProps(`fields.${index}.value`)}
          />
        )}

        {item.type === FormFieldTypeEnum.DATETIMEPICKER && (
          <>
            {item.extra && item.extra.range ? (
              <DateRangeInput
                label={item.name}
                hidden={item.hidden}
                placeholder={item.name}
                disabled={item.disabled}
                valueFormat="DD.MM.YYYY"
                {...form.getInputProps(`fields.${index}.value`)}
              />
            ) : (
              <DateTimePicker
                locale="ru"
                clearable
                leftSection={<IconCalendar size="1.1rem" />}
                label={item.name}
                placeholder={item.name}
                hidden={item.hidden}
                disabled={item.disabled}
                valueFormat="DD.MM.YYYY HH:mm"
                {...form.getInputProps(`fields.${index}.value`)}
              />
            )}
          </>
        )}
      </Box>
    );
  });

  return (
    <form noValidate className={classes.form}>
      <Flex gap={10} align={'flex-end'} justify={'flex-start'} wrap={'wrap'}>
        {fieldsView}

        {hasActiveFilters && (
          <Button rightSection={<IconRefresh />} variant="subtle" onClick={resetFilters}>
            Сбросить
          </Button>
        )}
      </Flex>
    </form>
  );
};

export default React.memo(ModelFiltersBuilder);
