import React, { FC } from 'react';
import { DatePickerInput, DatePickerInputProps, DatePickerType } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';

export const DateRangeInput: FC<DatePickerInputProps<DatePickerType>> = ({ ...props }) => {
  return (
    <DatePickerInput
      clearable
      locale="ru"
      type="range"
      leftSection={<IconCalendar size="1.1rem" />}
      {...props}
    />
  );
};
