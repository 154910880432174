import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FileInput, Group } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { IconUpload } from '@tabler/icons-react';

import { REVIEW_IMPORT_ARCHIVE_FORM } from '@/constants/common/validationSchemas';

import { scrollToError } from '@/utils/scrollToError';

import { AppDispatch } from '@/store';
import {
  fetchImportReviewsFromArchiveAction,
  selectFetchingImportReviewsFromArchive,
  setOpenReviewsImportArchiveModal,
} from '@/store/slices/reviewsPlugin/reviews';

const ReviewsImportArchiveForm: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const fetchingImportReviewsFromArchive = useSelector(selectFetchingImportReviewsFromArchive);

  const form = useForm({
    initialValues: {
      file: null,
    },
    validate: yupResolver(REVIEW_IMPORT_ARCHIVE_FORM),
  });

  const submitHandler = (values: { file: File | null }) => {
    if (values.file) {
      dispatch(fetchImportReviewsFromArchiveAction(values.file));
      dispatch(setOpenReviewsImportArchiveModal(false));
    }
  };

  return (
    <form onSubmit={form.onSubmit(submitHandler, scrollToError)} noValidate>
      <FileInput
        id="reviewsImportArchiveForm"
        mb={24}
        label="Архив отзывов"
        placeholder="Выбрать файл"
        leftSection={<IconUpload size={14} />}
        clearable
        fileInputProps={{
          accept:
            'zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed',
        }}
        withAsterisk
        {...form.getInputProps('file')}
      />

      <Group justify="right">
        <Button type={'submit'} loading={fetchingImportReviewsFromArchive}>
          Загрузить архив
        </Button>
      </Group>
    </form>
  );
};

export default ReviewsImportArchiveForm;
