import React, { FC } from 'react';
import { BoxProps, Breadcrumbs } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';

import { BreadcrumbsItem } from '@/types';

import BreadcrumbsExtItem from './components/BreadcrumbsExtItem/BreadcrumbsExtItem';

interface BreadcrumbsExtProps extends BoxProps {
  items: BreadcrumbsItem[];
}

const BreadcrumbsExt: FC<BreadcrumbsExtProps> = ({ items, ...props }) => {
  return (
    <Breadcrumbs pl={37} mih={22} w="100%" separator={<IconChevronRight size={12} />} {...props}>
      {items.map((item, index) => (
        <BreadcrumbsExtItem item={item} key={`${item.name}${index}`} />
      ))}
    </Breadcrumbs>
  );
};

export default BreadcrumbsExt;
