import { FC } from 'react';

import Page from '@/ui/templates/Page/Page';

import MenusContainerBody from './components/MenusContainerBody/MenusContainerBody';
import MenusContainerHeader from './components/MenusContainerHeader/MenusContainerHeader';
import MenusTableModals from './components/MenusTableModals/MenusTableModals';

const MenusContainer: FC = () => {
  return (
    <Page sx={{ maxHeight: '100vh' }}>
      <MenusContainerHeader />
      <MenusContainerBody />
      <MenusTableModals />
    </Page>
  );
};

export default MenusContainer;
