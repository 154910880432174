import { ActiveFiltersMap } from '@/ui/organisms/FiltersBuilder/types';

// TODO эта функция костыль от нее нужно избавиться
// когда бекенд переделает отзывы по аналогии с моделями
export const transformURLFilters = (filters: any) => {
  const result: any = {};

  for (let key in filters) {
    if (key === 'editingDate') {
      result[key] = {
        code: key,
        initialValue: filters[key].map((el: string) => new Date(el)),
        value: filters[key].map((el: string) => new Date(el)),
      };
    } else if (key === 'rating') {
      result[key] = {
        code: key,
        value: filters[key].map((el: number) => String(el)),
      };
    } else {
      result[key] = {
        code: key,
        value: filters[key],
      };
    }
  }

  return result as ActiveFiltersMap;
};
