import { FC, useEffect, useState } from 'react';
import { Loader, MultiSelect, MultiSelectProps, OptionsFilter } from '@mantine/core';
import debounce from 'lodash/debounce';
import isArray from 'lodash/isArray';
import uniqBy from 'lodash/uniqBy';

import { HTTPMethodEnum } from '@/types';
import {
  FormFieldTypeEnum,
  TAny,
  TFormField,
  TSelectData,
} from '@/types/modelsPlugin/modelsPlugin_v2';

import { notificationText } from '@/constants/common/notificationText';

import { fetching } from '../SearchableSelect/utils/fetching';
import { fetchingKLADR } from '../SearchableSelect/utils/fetchingKLADR';
import { getSelectData } from '../SearchableSelect/utils/getSelectData';
import { getSelectDataKLADR } from '../SearchableSelect/utils/getSelectDataKLADR';
import { getSelectValue } from '../SearchableSelect/utils/getSelectValue';
import { getSelectValueKLADR } from '../SearchableSelect/utils/getSelectValueKLADR';

interface SearchableMultiSelectProps extends MultiSelectProps {
  field: TFormField;
}

const SearchableMultiSelect: FC<SearchableMultiSelectProps> = ({ field, ...props }) => {
  const { extra } = field;
  const [searchFetching, setSearchFetching] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchData, setSearchData] = useState<TSelectData[]>([]);
  const [selectData, setSelectData] = useState<TSelectData[]>([]);
  const isKladrSelect = field.type === FormFieldTypeEnum.KLADRSELECT;

  const handleSearch = debounce((value: string) => {
    if (value.length >= 3 && extra?.request) {
      setSearchFetching(true);

      fetching(extra.request, value, {}, (res) => {
        const data = getSelectData(res.items, {
          valueID: extra.identifier,
          labelID: extra.display && extra.display[0],
          codeID: extra.display && extra.display[1],
        });

        setSearchData(data);
      }).finally(() => setSearchFetching(false));
    }

    // Поиск для кладр
    if (isKladrSelect && value.length >= 3) {
      setSearchFetching(true);

      fetchingKLADR(
        'suggests',
        HTTPMethodEnum.POST,
        value,
        (res) => {
          return setSearchData(uniqBy(getSelectDataKLADR(res), 'value'));
        },
        { count: 20, ...extra?.kladrSelect }
      ).finally(() => setSearchFetching(false));
    }
  }, 500);

  const handleChange = (value: string[] | null) => {
    if (isArray(value)) {
      const tempArr: TSelectData[] = [...selectData, ...searchData];
      const tempSelectData: TSelectData[] = [];
      let tempSearchData: TSelectData[] = [...selectData, ...searchData];

      value.forEach((item) => {
        tempSelectData.push(...tempArr.filter((el) => el.value === item));
        tempSearchData = tempSearchData.filter((el) => el.value !== item);
      });

      tempSearchData = tempSearchData.filter((el, i) => {
        return tempSearchData.indexOf(el) === i;
      });

      setSelectData(tempSelectData);
      setSearchData(tempSearchData);
    }

    if (props.onChange && value) props.onChange(value);
  };

  const getInitData = (f: TFormField, value: TAny) => {
    getSelectValue(f, value).then((res) => {
      let selectedData: TSelectData[] = [];

      if (isArray(value)) {
        selectedData = res;

        setSearchData(res);
        setSelectData(res);
      } else {
        selectedData = res.filter((el) => el.value === value);

        setSearchData(res);
        setSelectData(selectedData);
      }
    });
  };

  const optionsFilter: OptionsFilter = ({ options }) => {
    // TODO убрал фильтрацию, так как возникала проблема
    // с фильтром по полю код
    return options;
  };

  // Установка изначальных значений если они есть
  useEffect(() => {
    if (props.value && props.value.length !== 0 && field.type === FormFieldTypeEnum.SELECT) {
      getInitData(field, props.value);
    }

    if (extra && extra.firstEmptySearch) {
      getInitData(field, '');
    }

    if (props.value && props.value.length > 0 && isKladrSelect) {
      getSelectValueKLADR(field, props.value).then((res) => {
        setSelectData(res);
      });
    }
  }, []);

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue]);

  return (
    <MultiSelect
      nothingFoundMessage={notificationText.SEARCH_NOTHING}
      searchable
      clearable
      hidePickedOptions
      withCheckIcon={false}
      rightSection={searchFetching && <Loader size="sm" />}
      searchValue={searchValue}
      filter={optionsFilter}
      onSearchChange={setSearchValue}
      data={[...selectData, ...searchData]}
      {...props}
      onChange={handleChange}
    />
  );
};

export default SearchableMultiSelect;
