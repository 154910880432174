import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Text } from '@mantine/core';

import { AppDispatch } from '@/store';
import { selectModalNewMenus, setModalNewMenusOpened } from '@/store/slices/menuPlugin/menus';

import MenusFormNew from '../MenusFormNew/MenusFormNew';

const MenusModalNew: FC = () => {
  const menusModalNew = useSelector(selectModalNewMenus);
  const dispatch: AppDispatch = useDispatch();

  return (
    <Modal
      centered
      size={'lg'}
      opened={menusModalNew}
      onClose={() => dispatch(setModalNewMenusOpened(false))}
      title={
        <Text fz={22} fw={700}>
          Добавление меню
        </Text>
      }
    >
      <MenusFormNew type="new" />
    </Modal>
  );
};

export default MenusModalNew;
