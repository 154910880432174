import {
  ChangeEvent,
  Dispatch,
  DragEvent,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ActionIcon,
  AspectRatio,
  Box,
  Center,
  Grid,
  Group,
  Input,
  InputWrapperProps,
  LoadingOverlay,
  Paper,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { IconPhoto, IconTrash } from '@tabler/icons-react';
import { nanoid } from 'nanoid';

import { FileSizeEnum, Media, PluginCode, ResourceType, ServiceCode } from '@/types';

import { getFileSize } from '@/utils/getFileSize/getFileSize';

import { useServices } from '@/hooks/useServices';

import BaseModal from '@/ui/molecules/BaseModal/BaseModal';

import { savefile } from './utils/mediaRequests';

import FileInputLibraryExplorer from './components/FileInputLibraryExplorer/FileInputLibraryExplorer';
import FileInputMediaElement from './components/FileInputMediaElement/FileInputMediaElement';
import FileInputMenuButton from './components/FileInputMenuButton/FileInputMenuButton';

import { useStyles } from './style';

enum FileInputModals {
  EXPLORER,
}

type TLoadingQueue = {
  id: string;
  file: File;
  controller: AbortController;
}[];

type FileInputValue<M extends boolean> =
  | (M extends true ? Media<boolean>[] : Media<boolean>)
  | null;

interface IFileInputProps<M extends boolean = false>
  extends Omit<InputWrapperProps, 'children' | 'onChange'> {
  serviceCode?: ServiceCode;
  value?: (M extends true ? Media<boolean>[] : Media<boolean>) | null;
  onChange?: (value: (M extends true ? Media<boolean>[] : Media<boolean>) | null) => void;
  rootFolderId?: string;
  folderId?: string;
  multiple?: M;
  maxFiles?: number;
  multimedia?: boolean;
  maxSize?: number;
}

const permissions: { [key in ServiceCode]?: string } = {
  reviews: '.jpg, .jpeg, .png, .mp4, .m4v, .webm',
};

export default function FileInput<M extends boolean = false>({
  value,
  rootFolderId,
  folderId,
  serviceCode,
  onChange,
  multiple,
  maxFiles = 100,
  multimedia = false,
  maxSize = 10,
  ...props
}: IFileInputProps<M>) {
  const { classes } = useStyles();
  const { colors } = useMantineTheme();
  const [currentValue, setCurrentValue] = useState<FileInputValue<M>>(value || null);
  const [currentModal, setCurrentModal] = useState<FileInputModals | null>(null);
  const [currentModalService, setCurrentModalService] = useState<ServiceCode | null>(null);
  const [loadingQueue, setLoadingQueue] = useState<TLoadingQueue>([]);
  const [showDropMessage, setShowDropMessage] = useState(false);
  const [filesError, setFilesError] = useState<string | null>(null);
  const paperRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { currentService, getServicesWithPlugin } = useServices();
  const service = useMemo(
    () => serviceCode || currentService?.code || ServiceCode.CONTENT,
    [serviceCode, currentService]
  );
  const servicesWithMedia = getServicesWithPlugin(PluginCode.MEDIA);

  const setSingleValue = setCurrentValue as Dispatch<SetStateAction<FileInputValue<false>>>;
  const setMultipleValues = setCurrentValue as Dispatch<SetStateAction<FileInputValue<true>>>;

  const filesList = useMemo(() => {
    if (currentValue instanceof Array) return currentValue;
    if (currentValue) return [currentValue];

    return [];
  }, [currentValue]);

  const isFilesError = (msg: string) => {
    setFilesError(msg);

    setTimeout(() => {
      setFilesError(null);
    }, 2500);
  };

  const checkingFileSize = (file: File) => {
    const fileSize = getFileSize(file.size, FileSizeEnum.MB);

    if (fileSize > maxSize) {
      isFilesError(`Файл не должен весить больше ${maxSize} MB`);

      if (fileInputRef.current) fileInputRef.current.value = '';

      return false;
    } else {
      return true;
    }
  };

  const handleAddFiles = (newValue: Media<typeof multimedia>[]) => {
    if (multiple) {
      setMultipleValues((values) => (values ? [...values, ...newValue] : newValue));
    } else {
      setSingleValue(newValue[0] || null);
    }

    setCurrentModal(null);
  };

  const handleRemoveFile = (fileId: string) => {
    if (currentValue && multiple) {
      if (multimedia) {
        setMultipleValues(
          (values) => (values as Media<true>[])?.filter((file) => file.id !== fileId) || null
        );
      } else {
        setMultipleValues((values) => values?.filter((id) => id !== fileId) || null);
      }
    } else {
      setCurrentValue(null);
    }
  };

  const handleRemoveQueueItem = (id: string) => {
    const itemToDelete = loadingQueue.find((item) => item.id === id);

    if (itemToDelete) itemToDelete.controller.abort();

    setLoadingQueue((queue) => queue.filter((item) => item.id !== id));
  };

  const handleAddQueueItem = (file: File) => {
    const fileId = nanoid();
    const extension = file.name.split('.').slice(-1)[0] || '';
    const controller = new AbortController();
    const fileToSend = new File([file], `${nanoid()}.${extension}`);

    setLoadingQueue((queue) => [...queue, { id: fileId, file, controller }]);

    if (!multiple) setCurrentValue(null);

    savefile(fileToSend, service, controller, folderId).then((response) => {
      if (response) {
        handleRemoveQueueItem(fileId);
        handleAddFiles(multimedia ? [{ id: response.id, serviceCode: service }] : [response.id]);
      }
    });
  };

  const handleAddfromDevice = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const newFiles = [...e.target.files];

    if (multiple) {
      if (maxFiles - filesList.length >= newFiles.length) {
        newFiles.forEach((file) => {
          if (checkingFileSize(file)) handleAddQueueItem(file);
        });
      } else {
        isFilesError(`Максимальное число файлов ${maxFiles}`);
      }
    } else {
      if (checkingFileSize(newFiles[0])) handleAddQueueItem(newFiles[0]);
    }
  };

  const handleFileDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setShowDropMessage(false);

    if (!e.dataTransfer.files.length) return;

    if (e.dataTransfer.files.length > maxFiles - filesList.length) {
      isFilesError(`Максимальное число файлов ${maxFiles}`);

      return;
    }

    if (multiple) {
      [...e.dataTransfer.files].forEach((file) => {
        if (checkingFileSize(file)) handleAddQueueItem(file);
      });
    } else {
      if (checkingFileSize(e.dataTransfer.files[0])) handleAddQueueItem(e.dataTransfer.files[0]);
    }
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setShowDropMessage(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (paperRef.current?.contains(e.relatedTarget as Node)) return;

    setShowDropMessage(false);
  };

  const handleRemoveAll = () => {
    setCurrentValue(null);
  };

  const handleOpenModal = (code: ServiceCode) => {
    setCurrentModalService(code);
    setCurrentModal(FileInputModals.EXPLORER);
  };

  useEffect(() => {
    if (currentValue === value || !onChange) return;

    onChange(currentValue);
  }, [currentValue]);

  useEffect(() => () => loadingQueue.forEach((item) => item.controller.abort()), []);

  return (
    <Input.Wrapper {...props} label={undefined} error={props.error || filesError}>
      <Group justify="space-between">
        <Input.Label required={props.required}>{props.label}</Input.Label>
        <FileInputMenuButton
          onChooseFromLibrary={handleOpenModal}
          onChooseFromdevice={() => fileInputRef.current?.click()}
          onRemoveAll={handleRemoveAll}
          services={servicesWithMedia}
          service={service}
          multimedia={multimedia}
          disabled={filesList.length >= maxFiles}
        />
      </Group>

      <Paper
        id={props.id}
        mt={10}
        mb={5}
        p="md"
        pos="relative"
        c={colors.gray[7]}
        w={'100%'}
        sx={{
          border: `2px dashed ${
            props.error ? colors.red[6] : showDropMessage ? colors.blue[5] : colors.gray[4]
          }`,
          transition: '0.2s',
        }}
        radius="md"
        onDropCapture={handleFileDrop}
        onDragOverCapture={handleDragOver}
        onDragLeaveCapture={handleDragLeave}
        ref={paperRef}
      >
        <Grid w="100%" mih={268}>
          {filesList.map((file) => (
            <Grid.Col span={4} key={file}>
              <FileInputMediaElement
                resourceType={ResourceType.FILE}
                id={multimedia ? file.id : file}
                service={multimedia ? file.serviceCode : service}
                showInfo={false}
                withBorder={false}
                className={classes.bordersTop}
                aspect={1.05}
              />
              <Box
                py={4}
                px={12}
                className={classes.bordersBottom}
                bg={colors.gray[1]}
                display={'flex'}
              >
                <ActionIcon
                  p={0}
                  onClick={() => handleRemoveFile(multimedia ? file.id : file)}
                  title="Удалить файл"
                  variant="subtle"
                  color="gray"
                  ml={'auto'}
                >
                  <IconTrash size="1rem" color={colors.gray[6]} />
                </ActionIcon>
              </Box>
            </Grid.Col>
          ))}

          {loadingQueue.length > 0 &&
            loadingQueue.map(({ id }) => (
              <Grid.Col span={4} mih={260} key={id}>
                <Paper bg={colors.gray[2]} withBorder pos="relative" h={'100%'}>
                  <AspectRatio ratio={1.05}>
                    <LoadingOverlay visible />
                  </AspectRatio>
                </Paper>
              </Grid.Col>
            ))}

          {filesList.length === 0 && loadingQueue.length === 0 && (
            <Grid.Col mih={268}>
              <Center h={'100%'}>
                <Box ta={'center'}>
                  <IconPhoto size="4rem" color={colors.gray[5]} />
                  <Text c={colors.gray[6]}>Перетащите файлы в эту область</Text>
                </Box>
              </Center>
            </Grid.Col>
          )}
        </Grid>
      </Paper>

      <BaseModal
        opened={currentModal === FileInputModals.EXPLORER}
        onClose={() => {
          setCurrentModal(null);
          setCurrentModalService(null);
        }}
        size="lg"
        centered
        overlayProps={{
          blur: 2,
        }}
        title="Выгрузка из медиабиблиотеки"
      >
        <FileInputLibraryExplorer
          onFileSelected={handleAddFiles}
          serviceCode={currentModalService || service}
          multimedia={multimedia}
          {...{ rootFolderId, multiple }}
          maxFiles={maxFiles - filesList.length}
          permissions={permissions[service]?.split(', ')}
        />
      </BaseModal>

      <input
        type="file"
        hidden
        multiple={multiple}
        ref={fileInputRef}
        onChange={handleAddfromDevice}
        accept={permissions[service]}
      />
    </Input.Wrapper>
  );
}
