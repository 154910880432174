import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Modal, Text } from '@mantine/core';

import { AppDispatch } from '@/store';
import {
  selectReviewsAnalysisModal,
  setOpenReviewsAnalysisModal,
} from '@/store/slices/reviewsPlugin/reviews';

import ReviewsAnalysisForm from '../ReviewsAnalysisForm/ReviewsAnalysisForm';

const ReviewsAnalysisModal: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const reviewsAnalysisModal = useSelector(selectReviewsAnalysisModal);

  return (
    <Modal
      centered
      size={'lg'}
      opened={reviewsAnalysisModal}
      onClose={() => dispatch(setOpenReviewsAnalysisModal(false))}
      title={
        <Text fw={700} fz="xl">
          Отчет "Анализ отзывов"
        </Text>
      }
    >
      <ReviewsAnalysisForm />
    </Modal>
  );
};

export default ReviewsAnalysisModal;
