import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Flex, Group } from '@mantine/core';
import { DatePickerInput, DatesRangeValue } from '@mantine/dates';
import { useForm, yupResolver } from '@mantine/form';
import { IconCalendar, IconSearch } from '@tabler/icons-react';

import { HTTPMethodEnum, ReviewsModeratorsRequest } from '@/types';
import { FormFieldTypeEnum, TFormField } from '@/types/modelsPlugin/modelsPlugin_v2';

import { REVIEW_MODERATORS_FORM } from '@/constants/common/validationSchemas';

import { scrollToError } from '@/utils/scrollToError';

import { AppDispatch } from '@/store';
import {
  fetchDownloadModeratorsStatAction,
  selectFetchingDownloadModeratorsStat,
} from '@/store/slices/reviewsPlugin/reviews';

import SearchableMultiSelect from '@/ui/organisms/SearchableMultiSelect/SearchableMultiSelect';

const ReviewsModeratorsForm: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const fetchingDownloadModeratorsStat = useSelector(selectFetchingDownloadModeratorsStat);

  const form = useForm({
    initialValues: {
      moderatorsIds: [],
      date: [null, null] as DatesRangeValue,
    },
    validate: yupResolver(REVIEW_MODERATORS_FORM),
  });

  const searchField: TFormField = {
    code: 'search',
    name: 'Выберите модераторов',
    type: FormFieldTypeEnum.SELECT,
    multiple: true,
    extra: {
      display: ['name'],
      identifier: 'id',
      request: {
        uri: '/reviews/moderators',
        meth: HTTPMethodEnum.GET,
        body: {
          fields: ['name', 'id'],
        },
        paginated: true,
      },
      firstEmptySearch: true,
    },
  };

  const submitHandler = async (values: { moderatorsIds: string[]; date: DatesRangeValue }) => {
    const data: ReviewsModeratorsRequest = {
      ...values,
      date: {
        from: values.date[0],
        to: values.date[1],
      },
    };

    await dispatch(fetchDownloadModeratorsStatAction(data));
  };

  return (
    <form onSubmit={form.onSubmit(submitHandler, scrollToError)} noValidate>
      <Flex direction={'column'}>
        <SearchableMultiSelect
          id="moderatorsIds"
          mb={24}
          leftSection={<IconSearch size="1.1rem" />}
          label={'Выберите модераторов'}
          placeholder={'Начните вводить ФИО или ID модератора'}
          required={true}
          field={searchField}
          {...form.getInputProps('moderatorsIds')}
        />

        <DatePickerInput
          id="date"
          mb={24}
          locale="ru"
          clearable
          type="range"
          leftSection={<IconCalendar size="1.1rem" />}
          label="Выберите период"
          placeholder="ДД.ММ.ГГ - ДД.ММ.ГГ"
          valueFormat="DD.MM.YY"
          popoverProps={{ withinPortal: true }}
          {...form.getInputProps('date')}
        />

        <Group justify="right">
          <Button type={'submit'} loading={fetchingDownloadModeratorsStat}>
            Скачать отчет
          </Button>
        </Group>
      </Flex>
    </form>
  );
};

export default ReviewsModeratorsForm;
