import { NotificationData, showNotification } from '@mantine/notifications';

interface NotifyProps extends NotificationData {
  type?: 'info' | 'success' | 'warning' | 'error' | 'default' | 'dark';
}

export default function notify({ type = 'default', ...props }: NotifyProps) {
  const config: NotificationData = {
    autoClose: 5000,
    ...props,
  };

  switch (type) {
    case 'info':
      return showNotification({ ...config, color: 'blue' });
    case 'success':
      return showNotification({ ...config, color: 'green' });
    case 'warning':
      return showNotification({ ...config, color: 'blue' });
    case 'error':
      return showNotification({ ...config, color: 'red' });
    case 'dark':
      return showNotification({ ...config, color: 'black' });
    default:
      return showNotification({ ...config });
  }
}
