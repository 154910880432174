import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useLocalStorage } from '@mantine/hooks';

import { useQueryParams } from '@/hooks/useQueryParams';

import { AppDispatch } from '@/store';
import {
  fetchGetMoreMenus,
  fetchMenusAction,
  selectFetchingGetMenus,
  selectMenus,
  selectMenusTotal,
} from '@/store/slices/menuPlugin/menus';

const MEDIA_LIMIT = 50;

export const useInfinityLoad = () => {
  const dispatch: AppDispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const viewport = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState(0);
  const [scrollPosition, onScrollPositionChange] = useState({ x: 0, y: 0 });
  const menus = useSelector(selectMenus);
  const menusTotal = useSelector(selectMenusTotal);
  const menusFetching = useSelector(selectFetchingGetMenus);
  const [service] = useLocalStorage({ key: 'service' });
  const [, setReloadPage] = useState<boolean>(false);
  const { currentOrder, currentSort } = useQueryParams();

  useEffect(() => {
    setOffset(0);

    if (service)
      dispatch(
        fetchMenusAction({
          limit: MEDIA_LIMIT,
          offset: 0,
          order: currentOrder || 'asc',
          sort: currentSort || 'name',
        })
      );
  }, [service, searchParams]);

  useEffect(() => {
    if (menus) setReloadPage(true);
  }, [menus]);

  const getMoreMedia = async () => {
    await dispatch(
      fetchGetMoreMenus({
        limit: MEDIA_LIMIT,
        offset: offset + MEDIA_LIMIT,
        order: currentOrder || 'asc',
        sort: currentSort || 'name',
      })
    ).then(() => {
      setOffset((currentOffset) => currentOffset + MEDIA_LIMIT);
    });
  };

  const handleScroll = async () => {
    const viewportScrollHeight = viewport?.current?.scrollHeight || 0;
    const viewportClientHeight = viewport?.current?.clientHeight || 0;
    const viewportHeight = viewportScrollHeight - viewportClientHeight;
    const scrollPositionHeight = scrollPosition.y;

    const isScrolledToBottom = viewportHeight - scrollPositionHeight < 100;
    const isMenusLessThanPossible = menus.length < menusTotal;

    const shouldFetchMoreMedia = isScrolledToBottom && isMenusLessThanPossible && !menusFetching;

    if (shouldFetchMoreMedia) {
      await getMoreMedia();
    }
  };

  useEffect(() => {
    handleScroll();
  }, [viewport, scrollPosition]);

  return { viewport, onScrollPositionChange };
};
