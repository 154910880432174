import isArray from 'lodash/isArray';
import qs from 'qs';

export const getFilterFromURL = (params: URLSearchParams) => {
  const staticQueryParams = ['limit', 'offset', 'order', 'sort', 'query'];
  const result = qs.parse(params.toString()) as Record<
    string,
    string | (string | number | boolean)[]
  >;

  for (const key in result) {
    if (!(staticQueryParams.indexOf(key) !== -1)) {
      // Если фильтр строка, оборачиваем в массив
      if (typeof result[key] === 'string') {
        if (result[key] === 'Да' || result[key] === 'true') {
          result[key] = [true];
        } else if (result[key] === 'Нет' || result[key] === 'false') {
          result[key] = [false];
        } else {
          result[key] = [result[key] as string];
        }
      }

      // Если фильтр массив, то значения нужно проверить
      // и числа преобразовать в числа, строки оставить
      if (isArray(result[key])) {
        result[key] = (result[key] as string[] | number[] | boolean[]).map((item) => {
          if (item === 'Да') return (item = true);
          if (item === 'Нет') return (item = false);

          if (item === 'true') return (item = true);
          if (item === 'false') return (item = false);

          if (item === true) return (item = true);
          if (item === false) return (item = false);

          // TODO костыль для поля артикула, только там требуется
          // преобразовывать значения в цифры
          if (
            Number(item) &&
            (key === 'externalId' ||
              key === 'position' ||
              key === 'taxSystemCode' ||
              key === 'rating')
          ) {
            return Number(item);
          }

          return item;
        });
      }
    } else {
      delete result[key];
    }
  }

  return result;
};
