import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Text } from '@mantine/core';

import { REVIEWS_STOP_LIST_FOLDER_URL } from '@/constants/reviewsPlugin/reviewsPlugin';

import { AppDispatch } from '@/store';
import {
  selectReviewsStopListModal,
  setOpenReviewsStopListModal,
} from '@/store/slices/reviewsPlugin/reviews';

import ReviewsModalLink from '../ReviewsModalLink/ReviewsModalLink';
import ReviewsStopListForm from '../ReviewsStopListForm/ReviewsStopListForm';

const ReviewsStopListModal: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const reviewsStopListModal = useSelector(selectReviewsStopListModal);

  return (
    <Modal
      centered
      size={'lg'}
      opened={reviewsStopListModal}
      onClose={() => dispatch(setOpenReviewsStopListModal(false))}
      title={
        <Text fw={700} fz="xl">
          Загрузка стоп-слов
        </Text>
      }
    >
      <Text fz={14} mb={24}>
        Для загрузки стоп-слов загрузите файл{' '}
        <ReviewsModalLink type="link" to={REVIEWS_STOP_LIST_FOLDER_URL}>
          в раздел Медиа, папку “Справочник стоп-слов для отзывов”
        </ReviewsModalLink>{' '}
        и прикрепите загруженный файл здесь.
      </Text>
      <Text fz={14} mb={24}>
        Файл должен состоять из 1 столбца “name”, где 1 ячейка содержит 1 стоп-слово. При загрузке
        нового файла, старый файл удаляется, поэтому новый файл должен содержать стоп-слова из
        старого файла.
      </Text>

      <ReviewsStopListForm />
    </Modal>
  );
};

export default ReviewsStopListModal;
