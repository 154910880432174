import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDocumentTitle } from '@mantine/hooks';

import { TITLE_MAILS } from '@/constants/common/titles';

import MailContainer from '@/ui/pages/MailContainer/MailContainer';
import MailsTemplatesContainer from '@/ui/pages/MailsTemplatesContainer/MailsTemplatesContainer';

const Mails: FC = () => {
  useDocumentTitle(TITLE_MAILS);

  return (
    <Routes>
      <Route index element={<MailsTemplatesContainer />} />
      <Route path=":id" element={<MailContainer />} />
    </Routes>
  );
};

export default Mails;
