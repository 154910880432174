import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Group } from '@mantine/core';
import qs from 'qs';

import { PluginCode } from '@/types';

import { PLUGIN_PATHS } from '@/constants/common/plugins';
import { TABLE_MAILS_TEMPLATES } from '@/constants/mailTemplatesPlugin/tableHeaders';

import { useItemsIsEmptyMemo } from '@/hooks/useItemsIsEmptyMemo';
import { useQueryParams } from '@/hooks/useQueryParams';
import { useServices } from '@/hooks/useServices';
import { useURLPagination } from '@/hooks/useUrlPagination';

import { AppDispatch } from '@/store';
import {
  fetchMailsTemplatesAction,
  fetchMultiDelMailsTemplateAction,
  selectFetchingMailsTemplatesStatus,
  selectMailsTempaltesSelected,
  selectMailsTemplatesItems,
  selectMailsTemplatesTotal,
  setMailsTemplatesSelected,
} from '@/store/slices/mailsTemplatesPlugin/mailsTemplates';

import PageBody from '@/ui/templates/Page/components/PageBody/PageBody';
import PageContainer from '@/ui/templates/Page/components/PageContainer/PageContainer';
import PageFooter from '@/ui/templates/Page/components/PageFooter/PageFooter';
import PageHeader from '@/ui/templates/Page/components/PageHeader/PageHeader';
import PagePagination from '@/ui/templates/Page/components/PagePagination/PagePagination';
import Page from '@/ui/templates/Page/Page';

import SearchInput from '@/ui/organisms/SearchInput/SearchInput';
import SelectedCounter from '@/ui/organisms/SelectedCounter/SelectedCounter';
import ShowElements from '@/ui/organisms/ShowElements/ShowElements';
import TableExt from '@/ui/organisms/TableExt/TableExt';

import { MailsTemplatesContext } from './utils/MailsTemplatesContext';

import MailsTemplatesModals from './components/MailsTemplatesModals/MailsTemplatesModals';
import MailsTemplatesTableButtons from './components/MailsTemplatesTableButtons/MailsTemplatesTableButtons';

const MailsTemplatesContainer: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const mailsTemplatesItems = useSelector(selectMailsTemplatesItems);
  const mailsTemplatesTotal = useSelector(selectMailsTemplatesTotal);
  const mailsTemplatesFetch = useSelector(selectFetchingMailsTemplatesStatus);
  const mailsTemplatesSelected = useSelector(selectMailsTempaltesSelected);
  const rootUrl = PLUGIN_PATHS[PluginCode.MAIL_TEMPLATES];
  const { currentLimit, currentOffset, currentPage, pagesCount, setLimit, setPage } =
    useURLPagination(mailsTemplatesTotal || 0);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const { currentOrder, currentSort, currentQuery } = useQueryParams();

  const { currentService } = useServices();

  const mailsTemplatesIsEmpty = useItemsIsEmptyMemo(mailsTemplatesItems);

  const params = {
    ...useMemo(
      () => ({
        limit: currentLimit,
        offset: currentOffset,
      }),
      [currentLimit, currentOffset]
    ),
    order: currentOrder,
    sort: currentSort,
    query: currentQuery,
  };

  const searchAction = (query: string) => {
    const newParams = {
      ...params,
      offset: 0,
      query,
    };
    const URLParams = qs.stringify(newParams, { indices: false });

    setSearchParams(URLParams);
  };

  const reloadHandler = () => {
    dispatch(
      fetchMailsTemplatesAction({
        ...params,
      })
    );
  };

  const multiDelConfirmHandler = async () => {
    setIsDeleting(true);

    if (mailsTemplatesSelected && mailsTemplatesSelected.length > 0) {
      const selected: string[] = [];

      mailsTemplatesSelected?.map((item) => selected.push(item.id));

      await dispatch(fetchMultiDelMailsTemplateAction(selected));

      setIsDeleting(false);
    }

    reloadHandler();
  };

  useEffect(() => {
    reloadHandler();
  }, [searchParams, currentService]);

  return (
    <Page>
      <MailsTemplatesContext.Provider value={reloadHandler}>
        <PageHeader
          title="Почтовые шаблоны"
          rightButton={<Button onClick={() => navigate(`${rootUrl}/new`)}>Новый шаблон</Button>}
        />
        <PageBody>
          <Group mb={24} justify="space-between" grow>
            <SearchInput
              searchAction={searchAction}
              defaultValue={currentQuery}
              placeholder="Начните вводить ID или тему"
            />
            <ShowElements defaultValue={params.limit} changeCallback={setLimit} />
          </Group>

          <PageContainer
            isEmpty={mailsTemplatesIsEmpty}
            loading={mailsTemplatesFetch}
            loaderText={'У вас нет почтовых шаблонов'}
          >
            <TableExt
              config={TABLE_MAILS_TEMPLATES}
              rows={mailsTemplatesItems}
              buttons={MailsTemplatesTableButtons}
              selectCallback={(values) => dispatch(setMailsTemplatesSelected(values))}
              sortableKeys={['subject']}
              selectable
            />
          </PageContainer>

          <PageFooter>
            <Group justify="space-between" grow>
              {mailsTemplatesSelected && mailsTemplatesSelected.length > 0 && (
                <SelectedCounter
                  loading={isDeleting}
                  count={mailsTemplatesSelected.length}
                  buttonText="Удалить"
                  callback={multiDelConfirmHandler}
                />
              )}
              <PagePagination total={pagesCount} value={currentPage} onChange={setPage} />
            </Group>
          </PageFooter>
        </PageBody>

        <MailsTemplatesModals />
      </MailsTemplatesContext.Provider>
    </Page>
  );
};

export default MailsTemplatesContainer;
