import { FC, PropsWithChildren } from 'react';
import { Flex, FlexProps } from '@mantine/core';

const Page: FC<PropsWithChildren & FlexProps> = ({ children, ...props }) => {
  return (
    <Flex direction="column" w="100%" pb={10} pt={10} {...props}>
      {children}
    </Flex>
  );
};

export default Page;
