import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { useBlockingDispatch } from '@/hooks/useBlockingDispatch';
import { useQueryParams } from '@/hooks/useQueryParams';

import {
  fetchDelMenuAction,
  selectDelMenuModal,
  setDelMenuModalOpen,
} from '@/store/slices/menuPlugin/menu';
import { fetchMenusAction, selectCurrentMenu } from '@/store/slices/menuPlugin/menus';

import ModalConfirm from '@/ui/molecules/ModalConfirm/ModalConfirm';

const MenusModalDelete: FC = () => {
  const { dispatch, blocked } = useBlockingDispatch();
  const currentMenu = useSelector(selectCurrentMenu);
  const menuModalDel = useSelector(selectDelMenuModal);
  const { currentOffset, currentOrder, currentSort } = useQueryParams();

  const params = {
    limit: 100,
    offset: currentOffset,
    order: currentOrder,
    sort: currentSort,
  };

  const delConfirmHandler = async () => {
    if (currentMenu) {
      await dispatch(fetchDelMenuAction(currentMenu.id));
    }
    await dispatch(fetchMenusAction(params));
  };

  return (
    <ModalConfirm
      title="Вы уверены?"
      text="Восстановить данные после удаления не получится"
      opened={menuModalDel}
      onClose={() => dispatch(setDelMenuModalOpen(false))}
      confirmHandler={delConfirmHandler}
      blocked={blocked}
      closeOnClickOutside={!blocked}
      closeOnEscape={!blocked}
    />
  );
};

export default MenusModalDelete;
