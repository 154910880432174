import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Modal, Text } from '@mantine/core';

import { AppDispatch } from '@/store';
import {
  selectReviewsModeratorsModal,
  setOpenReviewsModeratorsModal,
} from '@/store/slices/reviewsPlugin/reviews';

import ReviewsModeratorsForm from '../ReviewsModeratorsForm/ReviewsModeratorsForm';

const ReviewsModeratorsModal: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const reviewsModeratorsModal = useSelector(selectReviewsModeratorsModal);

  return (
    <Modal
      centered
      size={'lg'}
      opened={reviewsModeratorsModal}
      onClose={() => dispatch(setOpenReviewsModeratorsModal(false))}
      title={
        <Text fw={700} fz="xl">
          Отчет по модераторам
        </Text>
      }
    >
      <ReviewsModeratorsForm />
    </Modal>
  );
};

export default ReviewsModeratorsModal;
