import { TSelectData } from '@/types/modelsPlugin/modelsPlugin_v2';

export const getSelectData = (
  items: Record<string, unknown>[] | string[],
  config?: {
    valueID?: string;
    labelID?: string;
    codeID?: string;
    filters?: boolean;
  }
): TSelectData[] => {
  const result: TSelectData[] = [];

  if (config && config.valueID && config.labelID) {
    (items as Record<string, unknown>[]).map((item) => {
      const selectItem: TSelectData = {
        value: '',
        label: '',
      };

      if (config.valueID) selectItem.value = item[config.valueID] as string;
      if (config.labelID) selectItem.label = item[config.labelID] as string;
      if (config.codeID) selectItem.code = item[config?.codeID] as string;

      result.push(selectItem);
    });
  }

  if (config && config.filters) {
    if (typeof items[0] !== 'object' || items instanceof String || items instanceof Number) {
      (items as string[]).map((item) => {
        const selectItem: TSelectData = {
          value: '',
          label: '',
        };

        selectItem.value = String(item);
        selectItem.label = item;

        result.push(selectItem);
      });
    } else {
      (items as Record<string, string>[]).map((item) => {
        const selectItem: TSelectData = {
          value: '',
          label: '',
        };

        selectItem.value = String(item.id);
        selectItem.label = item.name;

        result.push(selectItem);
      });
    }
  }

  return result;
};
