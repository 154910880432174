import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalProps, Text } from '@mantine/core';

import { AppDispatch } from '@/store';
import {
  fetchPutConfigurationOptionsAction,
  selectConfigurationOptions,
  selectFetchingGetConfigurationOptions,
} from '@/store/slices/configurationPlugin/configurationOptions';
import { selectCurrentService } from '@/store/slices/service/service';

import FormBuilder from '@/ui/organisms/FormBuilder/FormBuilder';

import BaseModal from '@/ui/molecules/BaseModal/BaseModal';
import { LoaderOverlay } from '@/ui/molecules/LoaderOverlay/LoaderOverlay';

import { getConfigurationOptions } from './utils/getConfigurationOptions';

interface ConfigurationsModalProps extends ModalProps {
  title: string;
  confId: string | null;
}

const ConfigurationsModal: FC<ConfigurationsModalProps> = ({ title, confId, ...props }) => {
  const dispatch: AppDispatch = useDispatch();
  const fetchingOptions = useSelector(selectFetchingGetConfigurationOptions);
  const options = useSelector(selectConfigurationOptions);
  const service = useSelector(selectCurrentService);

  const formSubmit = (values: Record<string, unknown>) => {
    if (confId && service)
      dispatch(
        fetchPutConfigurationOptionsAction({
          id: confId,
          options: getConfigurationOptions(values),
        })
      );
  };

  return (
    <BaseModal {...props} wide title={title} subtitle={`ID ${confId}`}>
      {fetchingOptions ? (
        <LoaderOverlay visible={fetchingOptions} />
      ) : options && options?.length > 0 ? (
        <FormBuilder options={options} onSubmit={formSubmit} />
      ) : (
        <Text ta="center">Опций не найдено</Text>
      )}
    </BaseModal>
  );
};

export default ConfigurationsModal;
