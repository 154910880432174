import { ChangeEvent, FC } from 'react';
import { TextInput, TextInputProps } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import debounce from 'lodash/debounce';

interface SearchInputProps extends TextInputProps {
  searchAction: (query: string) => void;
}

const DEFAULT_PLACEHOLDER = 'Начните вводить ID, название или код';

const SearchInput: FC<SearchInputProps> = ({ searchAction, ...props }) => {
  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    searchAction(inputValue);
  };

  return (
    <TextInput
      leftSection={<IconSearch size="1rem" />}
      onChange={debounce((e) => changeHandler(e), 400)}
      placeholder={DEFAULT_PLACEHOLDER}
      {...props}
    />
  );
};

export default SearchInput;
