// import { SelectItemProps } from '@mantine/core';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InactivationOption, IReview } from '@/types/reviewsPlugin/reviewsPlugin';

import { DEFAULT_DATE_OPTIONS, LIMIT, OFFSET } from '@/constants/common/common';

import { createAxiosThunk } from '@/utils/asyncRequest';
import { downloadFile } from '@/utils/downloadFile';
import notify from '@/utils/notify';
import { setFormatDate } from '@/utils/setFormatDate/setFormatDate';

import {
  apiAddReviewItem,
  apiDownloadModeratorsStat,
  apiEditReviewItem,
  apiGetInactivationOptions,
  apiGetReviewsAnalysis,
  apiGetReviewsDownloadTemplate,
  apiImportReviewsFromArchive,
  apiImportReviewsFromFile,
  apiImportReviewsStopListFromFile,
  apiReviewsItem,
  apiReviewsList,
} from '@/api/reviewsPlugin/reviews';

interface IReviewsState {
  status: {
    fetchingGetReviews: boolean;
    fetchingReviewItem: boolean;
    fetchingReviewsAnalysis: boolean;
    fetchingReviewsDownloadTempalte: boolean;
    fetchingImportReviewsFromFile: boolean;
    fetchingImportReviewsFromArchive: boolean;
    fetchingImportReviewsStopListFromFile: boolean;
    fetchingDownloadModeratorsStat: boolean;
  };
  modals: {
    editReviewsElementModal: boolean;
    addReviewsElementModal: boolean;
    reviewsAnalysisModal: boolean;
    reviewsModeratorsModal: boolean;
    reviewsImportModal: boolean;
    reviewsImportArchiveModal: boolean;
    reviewsStopListModal: boolean;
  };
  total: number;
  limit: string;
  offset: string;
  reviews: IReview[];
  reviewItem: IReview | null;
  reviewId: string | any;
  inactivationOptions: InactivationOption[];
  articleSearch: {
    fetching: boolean;
    // results: SelectItemProps[];
    results: Record<string, string>[];
  };
}

const initialState: IReviewsState = {
  status: {
    fetchingGetReviews: false,
    fetchingReviewItem: false,
    fetchingReviewsAnalysis: false,
    fetchingReviewsDownloadTempalte: false,
    fetchingImportReviewsFromFile: false,
    fetchingImportReviewsFromArchive: false,
    fetchingImportReviewsStopListFromFile: false,
    fetchingDownloadModeratorsStat: false,
  },
  modals: {
    editReviewsElementModal: false,
    addReviewsElementModal: false,
    reviewsAnalysisModal: false,
    reviewsModeratorsModal: false,
    reviewsImportModal: false,
    reviewsImportArchiveModal: false,
    reviewsStopListModal: false,
  },
  total: 0,
  limit: LIMIT.toString(),
  offset: OFFSET.toString(),
  reviews: [],
  reviewItem: null,
  reviewId: null,
  inactivationOptions: [],
  articleSearch: {
    fetching: false,
    results: [],
  },
};

export const fetchReviewsAction = createAxiosThunk('/reviews', apiReviewsList);
export const fetchReviewsItemAction = createAxiosThunk('/reviewsItem', apiReviewsItem);
export const fetchEditReviewItemAction = createAxiosThunk('/editReviewItem', apiEditReviewItem);
export const fetchAddReviewItemAction = createAxiosThunk('/addReviewItem', apiAddReviewItem);
export const fetchInactivationOptions = createAxiosThunk(
  '/inactivationReasonOptions',
  apiGetInactivationOptions
);
export const fetchReviewsAnalysisAction = createAxiosThunk(
  '/reviews/export',
  apiGetReviewsAnalysis
);
export const fetchReviewsDownloadTempalteAction = createAxiosThunk(
  '/reviews/download-template',
  apiGetReviewsDownloadTemplate
);
export const fetchImportReviwsFromFileAction = createAxiosThunk(
  '/reviews/import/mediaId',
  apiImportReviewsFromFile
);
export const fetchImportReviewsFromArchiveAction = createAxiosThunk(
  '/reviews/import/import-medias',
  apiImportReviewsFromArchive
);
export const fetchImportReviewsStopListFromFileAction = createAxiosThunk(
  '/reviews/stop-words/import',
  apiImportReviewsStopListFromFile
);

export const fetchDownloadModeratorsStatAction = createAxiosThunk(
  '/reviews/moderators/',
  apiDownloadModeratorsStat
);

export const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    setOpenEditReviewsElementModal: (state, action: PayloadAction<boolean>) => {
      state.modals.editReviewsElementModal = action.payload;
    },
    setCurrentReviewId: (state, action: PayloadAction<string>) => {
      state.reviewId = action.payload;
    },
    setOpenAddReviewsElementModal: (state, action: PayloadAction<boolean>) => {
      state.modals.addReviewsElementModal = action.payload;
    },
    setOpenReviewsAnalysisModal: (state, action: PayloadAction<boolean>) => {
      state.modals.reviewsAnalysisModal = action.payload;
    },
    setOpenReviewsModeratorsModal: (state, action: PayloadAction<boolean>) => {
      state.modals.reviewsModeratorsModal = action.payload;
    },
    setOpenReviewsImportModal: (state, action: PayloadAction<boolean>) => {
      state.modals.reviewsImportModal = action.payload;
    },
    setOpenReviewsImportArchiveModal: (state, action: PayloadAction<boolean>) => {
      state.modals.reviewsImportArchiveModal = action.payload;
    },
    setOpenReviewsStopListModal: (state, action: PayloadAction<boolean>) => {
      state.modals.reviewsStopListModal = action.payload;
    },
    setFetchingImportReviewsFromFile: (state, action: PayloadAction<boolean>) => {
      state.status.fetchingImportReviewsFromFile = action.payload;
    },
    setFetchingImportReviewsStopListFromFile: (state, action: PayloadAction<boolean>) => {
      state.status.fetchingImportReviewsStopListFromFile = action.payload;
    },
    setFetchingReviewsAnalysis: (state, action: PayloadAction<boolean>) => {
      state.status.fetchingReviewsAnalysis = action.payload;
    },
    setFetchingDownloadModeratorsStat: (state, action: PayloadAction<boolean>) => {
      state.status.fetchingDownloadModeratorsStat = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReviewsAction.pending, (state) => {
        state.status.fetchingGetReviews = true;
      })
      .addCase(fetchReviewsAction.fulfilled, (state, action) => {
        state.reviews = action.payload.items.map((item) => ({
          ...item,
          activationDate: setFormatDate(item.activationDate, DEFAULT_DATE_OPTIONS, 'ru-RU'),
          responseDate: setFormatDate(item.responseDate, DEFAULT_DATE_OPTIONS, 'ru-RU'),
          createdDate: setFormatDate(item.createdDate, DEFAULT_DATE_OPTIONS, 'ru-RU'),
          editingDate: setFormatDate(item.editingDate, DEFAULT_DATE_OPTIONS, 'ru-RU'),
        }));
        state.total = action.payload.total;
        state.status.fetchingGetReviews = false;
      });
    builder
      .addCase(fetchReviewsItemAction.pending, (state) => {
        state.status.fetchingReviewItem = true;
      })
      .addCase(fetchReviewsItemAction.fulfilled, (state, action) => {
        state.reviewItem = action.payload;
        state.status.fetchingReviewItem = false;
      })
      .addCase(fetchReviewsItemAction.rejected, (state) => {
        state.status.fetchingReviewItem = false;
      });
    builder.addCase(fetchEditReviewItemAction.fulfilled, () => {
      notify({ message: 'Отзыв изменен', type: 'success' });
    });
    builder.addCase(fetchAddReviewItemAction.fulfilled, () => {
      notify({ message: 'Отзыв создан', type: 'success' });
    });
    builder.addCase(fetchInactivationOptions.fulfilled, (state, action) => {
      state.inactivationOptions = action.payload.items.map((item) => ({
        value: String(item.id) || '',
        label: String(item.name) || '',
      }));
    });
    builder
      .addCase(fetchReviewsAnalysisAction.pending, (state) => {
        state.status.fetchingReviewsAnalysis = true;
      })
      .addCase(fetchReviewsAnalysisAction.fulfilled, (state) => {
        state.modals.reviewsAnalysisModal = false;
        state.status.fetchingReviewsAnalysis = false;

        notify({ message: 'Запрос на формирование отчета успешно отправлен', type: 'success' });
      })
      .addCase(fetchReviewsAnalysisAction.rejected, (state) => {
        state.status.fetchingReviewsAnalysis = false;
        state.modals.reviewsAnalysisModal = false;

        notify({
          message: 'К сожалению, не удалось сформировать отчет. Попробуйте еще раз.',
          type: 'error',
        });
      });
    builder
      .addCase(fetchReviewsDownloadTempalteAction.pending, (state) => {
        state.status.fetchingReviewsDownloadTempalte = true;
      })
      .addCase(fetchReviewsDownloadTempalteAction.fulfilled, (state, action) => {
        state.status.fetchingReviewsDownloadTempalte = false;

        downloadFile(action.payload.url, 'tempalte.xlsx');
      })
      .addCase(fetchReviewsDownloadTempalteAction.rejected, (state) => {
        state.status.fetchingReviewsDownloadTempalte = false;

        notify({
          message: 'К сожалению, не удалось получить шаблон. Попробуйте еще раз.',
          type: 'error',
        });
      });
    builder
      .addCase(fetchImportReviwsFromFileAction.pending, (state) => {
        state.status.fetchingImportReviewsFromFile = true;
      })
      .addCase(fetchImportReviwsFromFileAction.fulfilled, (state) => {
        state.modals.reviewsImportModal = false;
      })
      .addCase(fetchImportReviwsFromFileAction.rejected, (state) => {
        state.status.fetchingImportReviewsFromFile = false;
      });
    builder
      .addCase(fetchImportReviewsStopListFromFileAction.pending, (state) => {
        state.status.fetchingImportReviewsStopListFromFile = true;
      })
      .addCase(fetchImportReviewsStopListFromFileAction.fulfilled, (state) => {
        state.modals.reviewsStopListModal = false;
      })
      .addCase(fetchImportReviewsStopListFromFileAction.rejected, (state) => {
        state.status.fetchingImportReviewsStopListFromFile = false;
      });
    builder
      .addCase(fetchDownloadModeratorsStatAction.pending, (state) => {
        state.status.fetchingDownloadModeratorsStat = true;
      })
      .addCase(fetchDownloadModeratorsStatAction.fulfilled, (state) => {
        state.modals.reviewsModeratorsModal = false;
        state.status.fetchingDownloadModeratorsStat = false;

        notify({ message: 'Запрос на формирование отчета успешно отправлен', type: 'success' });
      })
      .addCase(fetchDownloadModeratorsStatAction.rejected, (state) => {
        state.status.fetchingDownloadModeratorsStat = false;

        notify({
          message: 'К сожалению, не удалось сформировать отчет. Попробуйте еще раз.',
          type: 'error',
        });
      });
    builder
      .addCase(fetchImportReviewsFromArchiveAction.pending, (state) => {
        state.status.fetchingImportReviewsFromArchive = true;
      })
      .addCase(fetchImportReviewsFromArchiveAction.fulfilled, (state) => {
        state.status.fetchingImportReviewsFromArchive = false;

        notify({ message: 'Загрузка файла завершена', type: 'success' });
      })
      .addCase(fetchImportReviewsFromArchiveAction.rejected, (state) => {
        state.status.fetchingImportReviewsFromArchive = false;
      });
  },
});

// Selectors
type TSelectorState = { reviews: IReviewsState };

// Statuses
export const selectFetchingReviewsAction = (state: TSelectorState) =>
  state.reviews.status.fetchingGetReviews;
export const selectFetchingReview = (state: TSelectorState) =>
  state.reviews.status.fetchingReviewItem;
export const selectFetchingReviewsAnalysis = (state: TSelectorState) =>
  state.reviews.status.fetchingReviewsAnalysis;
export const selectFetchingReviewsDownloadTempalte = (state: TSelectorState) =>
  state.reviews.status.fetchingReviewsDownloadTempalte;
export const selectFetchingImportReviewsFromFile = (state: TSelectorState) =>
  state.reviews.status.fetchingImportReviewsFromFile;
export const selectFetchingImportReviewsStopListFromFile = (state: TSelectorState) =>
  state.reviews.status.fetchingImportReviewsStopListFromFile;
export const selectFetchingDownloadModeratorsStat = (state: TSelectorState) =>
  state.reviews.status.fetchingDownloadModeratorsStat;
export const selectFetchingImportReviewsFromArchive = (state: TSelectorState) =>
  state.reviews.status.fetchingImportReviewsFromArchive;

// modals
export const selectEditReviewsElementModal = (state: TSelectorState) =>
  state.reviews.modals.editReviewsElementModal;
export const selectAddReviewsElementModal = (state: TSelectorState) =>
  state.reviews.modals.addReviewsElementModal;
export const selectReviewsAnalysisModal = (state: TSelectorState) =>
  state.reviews.modals.reviewsAnalysisModal;
export const selectReviewsModeratorsModal = (state: TSelectorState) =>
  state.reviews.modals.reviewsModeratorsModal;
export const selectReviewsImportModal = (state: TSelectorState) =>
  state.reviews.modals.reviewsImportModal;
export const selectReviewsImportArchiveModal = (state: TSelectorState) =>
  state.reviews.modals.reviewsImportArchiveModal;
export const selectReviewsStopListModal = (state: TSelectorState) =>
  state.reviews.modals.reviewsStopListModal;

export const selectReviewsTotal = (state: TSelectorState) => state.reviews.total;
export const selectReviews = (state: TSelectorState) => state.reviews.reviews;
export const selectReviewsItem = (state: TSelectorState) => state.reviews.reviewItem;
export const selectReviewId = (state: TSelectorState) => state.reviews.reviewId;

export const selectInactivationOptions = (state: TSelectorState) =>
  state.reviews.inactivationOptions;

// reducers and actions

export const {
  setOpenEditReviewsElementModal,
  setCurrentReviewId,
  setOpenAddReviewsElementModal,
  setOpenReviewsAnalysisModal,
  setOpenReviewsModeratorsModal,
  setOpenReviewsImportModal,
  setOpenReviewsStopListModal,
  setOpenReviewsImportArchiveModal,
  setFetchingImportReviewsFromFile,
  setFetchingImportReviewsStopListFromFile,
  setFetchingReviewsAnalysis,
  setFetchingDownloadModeratorsStat,
} = reviewsSlice.actions;

export default reviewsSlice.reducer;
