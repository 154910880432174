import { FC } from 'react';
import { LoadingOverlay, LoadingOverlayProps } from '@mantine/core';

import { LoaderOverlayChildren } from './components/LoaderOverlayChildren/LoaderOverlayChildren';

interface LoaderOverlayProps extends LoadingOverlayProps {
  loadingText?: string;
}

export const LoaderOverlay: FC<LoaderOverlayProps> = ({ loadingText, ...props }) => (
  <LoadingOverlay
    overlayProps={{ color: '#fff', opacity: 1 }}
    loaderProps={{
      children: <LoaderOverlayChildren loadingText={loadingText} />,
    }}
    {...props}
  />
);
