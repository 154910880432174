import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Modal, Text } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';

import { SortOrderEnum } from '@/types';

import { TABLE_CONFIGURATION } from '@/constants/configurationsPlugin/tableHeaders';

import { useItemsIsEmptyMemo } from '@/hooks/useItemsIsEmptyMemo';
import { useQueryParams } from '@/hooks/useQueryParams';

import { AppDispatch } from '@/store';
import {
  fetchGetConfigurationAction,
  selectConfiguration,
} from '@/store/slices/configurationPlugin/configuration';
import {
  fetchGetConfigurationOptionsAction,
  selectAddConfigurationOptionsModal,
  selectConfigurationOptions,
  selectFetchingGetConfigurationOptions,
  setAddConfigurationOptionsModalOpened,
} from '@/store/slices/configurationPlugin/configurationOptions';
import { selectServiceChanged } from '@/store/slices/service/service';

import PageBody from '@/ui/templates/Page/components/PageBody/PageBody';
import PageContainer from '@/ui/templates/Page/components/PageContainer/PageContainer';
import PageHeader from '@/ui/templates/Page/components/PageHeader/PageHeader';
import Page from '@/ui/templates/Page/Page';

import TableExt from '@/ui/organisms/TableExt/TableExt';

import ConfigurationForm from './components/ConfigurationForm/ConfigurationForm';
import ConfigurationTableButtons from './components/ConfigurationTableButtons/ConfigurationTableButtons';
import ConfigurationTableModals from './components/ConfigurationTableModals/ConfigurationTableModals';

const ConfigurtationContainer: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { confId } = useParams<{ confId: string }>();
  const [searchParams] = useSearchParams();
  const configuration = useSelector(selectConfiguration);
  const configurationOptions = useSelector(selectConfigurationOptions);
  const configurationOptionsFetch = useSelector(selectFetchingGetConfigurationOptions);
  const configurationOptionsModalNew = useSelector(selectAddConfigurationOptionsModal);
  const serviceChanged = useSelector(selectServiceChanged);
  const { currentOrder, currentSort, currentLimit, currentOffset } = useQueryParams();
  const [service] = useLocalStorage({ key: 'service' });

  const showConfigurationTitle = useMemo(
    () => !configurationOptionsFetch && configuration?.name?.length,
    [configurationOptionsFetch, configuration]
  );

  const configurationOptionsIsEmpty = useItemsIsEmptyMemo(configurationOptions);

  const params = {
    limit: 100 || currentLimit,
    offset: currentOffset,
    order: currentOrder || SortOrderEnum.ASC,
    sort: currentSort || 'sort',
  };

  const reloadHandler = () => {
    if (!confId) return;

    dispatch(
      fetchGetConfigurationOptionsAction({
        id: confId,
        params,
      })
    );
  };

  useEffect(() => {
    if (confId && service && !serviceChanged) dispatch(fetchGetConfigurationAction({ confId }));
  }, [confId, service]);

  // Обновление данных при изменении параметров URL
  useEffect(() => {
    reloadHandler();
  }, [searchParams]);

  const breadcrumbs = [
    {
      name: 'Конфигурации',
      url: '/configurations',
    },
    {
      name: `Настройки конфигурации${showConfigurationTitle ? `: ${configuration?.name}` : ''}`,
    },
  ];

  return (
    <Page>
      <PageHeader
        title={`Настройки конфигурации${showConfigurationTitle ? `: ${configuration?.name}` : ''}`}
        subTitle={
          !configurationOptionsFetch && configuration?.id ? `ID ${configuration?.id}` : undefined
        }
        backLink="/configurations"
        breadcrumbs={breadcrumbs}
        rightButton={
          <Button onClick={() => dispatch(setAddConfigurationOptionsModalOpened(true))}>
            Новая настройка
          </Button>
        }
      />

      {serviceChanged && <Navigate to="/configurations" />}

      <PageBody>
        <PageContainer
          isEmpty={configurationOptionsIsEmpty}
          loading={configurationOptionsFetch}
          loaderText={'Опций не найдено'}
        >
          <TableExt
            config={TABLE_CONFIGURATION}
            rows={configurationOptions}
            buttons={ConfigurationTableButtons}
            sortableKeys={['name']}
          />
        </PageContainer>
      </PageBody>

      <Modal
        centered
        size={'lg'}
        opened={configurationOptionsModalNew}
        onClose={() => dispatch(setAddConfigurationOptionsModalOpened(false))}
        title={
          <Text fz={22} fw={700}>
            Новая настройка
          </Text>
        }
      >
        <ConfigurationForm type="new" />
      </Modal>

      <ConfigurationTableModals />
    </Page>
  );
};

export default ConfigurtationContainer;
