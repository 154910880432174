import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalProps } from '@mantine/core';

import { ReviewStatus } from '@/types';

import { useQueryParams } from '@/hooks/useQueryParams';

import { AppDispatch } from '@/store';
import {
  fetchAddReviewItemAction,
  fetchEditReviewItemAction,
  fetchReviewsAction,
  selectFetchingReview,
  selectReviewId,
  selectReviewsItem,
  setOpenAddReviewsElementModal,
  setOpenEditReviewsElementModal,
} from '@/store/slices/reviewsPlugin/reviews';

import BaseModal from '@/ui/molecules/BaseModal/BaseModal';
import { LoaderOverlay } from '@/ui/molecules/LoaderOverlay/LoaderOverlay';

import ReviewFormBuilder from '../ReviewsFormBuilder/ReviewsFormBuilder';

interface IModelElementModal extends ModalProps {
  title: string;
  type: 'new' | 'edit';
}

const ReviewsElementModal: FC<IModelElementModal> = ({ title, type, ...props }) => {
  const dispatch: AppDispatch = useDispatch();
  const fetchingReview = useSelector(selectFetchingReview);
  const reviewsItem = useSelector(selectReviewsItem);
  const reviewId = useSelector(selectReviewId);
  const { currentLimit, currentOffset, currentQuery, currentOrder, currentSort, currentFilter } =
    useQueryParams();

  const params = {
    limit: currentLimit,
    offset: currentOffset,
    query: currentQuery,
    order: currentOrder,
    sort: currentSort,
  };

  const transformFilters = (filters: any) => {
    if (filters.editingDate) {
      filters.editingDate = {
        from: filters.editingDate[0],
        to: filters.editingDate[1],
      };
    }

    return filters;
  };

  const submitHandler = async (values: any) => {
    if (values.status === ReviewStatus.ACTIVE) {
      values.inactivationReasonId = null;
    }
    if (reviewId && type === 'edit') {
      await dispatch(
        fetchEditReviewItemAction({
          id: reviewId,
          data: {
            ...values,
          },
        })
      );

      await dispatch(
        fetchReviewsAction({ params, data: { filter: transformFilters(currentFilter) } })
      );
      dispatch(setOpenEditReviewsElementModal(false));
    } else if (type === 'new') {
      await dispatch(fetchAddReviewItemAction(values));
      await dispatch(
        fetchReviewsAction({ params, data: { filter: transformFilters(currentFilter) } })
      );
      dispatch(setOpenAddReviewsElementModal(false));
    }
  };

  return (
    <BaseModal {...props} wide title={title}>
      <LoaderOverlay visible={fetchingReview} />

      {reviewsItem && type === 'edit' && (
        <ReviewFormBuilder reviewsItem={reviewsItem} onSubmit={submitHandler} />
      )}

      {type === 'new' && <ReviewFormBuilder onSubmit={submitHandler} />}
    </BaseModal>
  );
};

export default ReviewsElementModal;
