import { FC, ReactNode, useState } from 'react';
import { Box, Button, Group, LoadingOverlay, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { TAddMenusReq } from '@/types/menuPlugin/menuPluginApi';

import { NEW_MENU_FORM } from '@/constants/common/validationSchemas';

import { transliteration } from '@/utils/transliteration/transliteration';

import { useBlockingDispatch } from '@/hooks/useBlockingDispatch';
import { useQueryParams } from '@/hooks/useQueryParams';

import { fetchPutMenuAction } from '@/store/slices/menuPlugin/menu';
import { fetchAddMenusAction, fetchMenusAction } from '@/store/slices/menuPlugin/menus';

interface MenusFormNewProps {
  type: 'new' | 'edit';
  element?: Record<string, ReactNode> | null;
}

const MenusFormNew: FC<MenusFormNewProps> = ({ type, element }) => {
  const { dispatch, blocked } = useBlockingDispatch();
  const confId = element?.id as string;
  const [allowTransliteration, setAllowTransliteration] = useState(true);
  const { currentOffset, currentOrder, currentSort } = useQueryParams();

  const params = {
    limit: 100,
    offset: currentOffset,
    order: currentOrder,
    sort: currentSort,
  };

  const form = useForm({
    validate: yupResolver(NEW_MENU_FORM),
    initialValues: {
      name: (element?.name as string) || '',
      code: (element?.code as string) || '',
    },
  });

  const submitHandler = async (req: TAddMenusReq) => {
    if (type === 'new') {
      await dispatch(fetchAddMenusAction(req));
    }

    if (confId && type === 'edit') {
      await dispatch(fetchPutMenuAction({ id: confId, data: req }));
    }

    await dispatch(fetchMenusAction(params));
  };

  return (
    <form onSubmit={form.onSubmit((values) => submitHandler(values))}>
      <TextInput
        placeholder="Введите название"
        label="Название"
        required
        mb={24}
        onInput={(el) =>
          type === 'new' && allowTransliteration
            ? form.setFieldValue(
                'code',
                transliteration(el.currentTarget.value, { onlyLower: true })
              )
            : {}
        }
        {...form.getInputProps('name')}
      />
      <TextInput
        placeholder="Введите код"
        label="Код"
        required
        mb={24}
        disabled={type === 'edit'}
        onInput={(el) => {
          setAllowTransliteration(false);
          form.setFieldValue('code', el.currentTarget.value);
        }}
        {...form.getInputProps('code')}
      />

      {type === 'new' && (
        <Box
          sx={{
            display: 'flex',
          }}
        ></Box>
      )}

      <Group justify="right">
        <Button type={'submit'} disabled={blocked}>
          Сохранить
        </Button>
      </Group>
      <LoadingOverlay visible={blocked} />
    </form>
  );
};

export default MenusFormNew;
