import React, { FC } from 'react';
import { Button, Flex, Group } from '@mantine/core';
import { useForm } from '@mantine/form';

import { TFormField, TValidationField } from '@/types/modelsPlugin/modelsPlugin_v2';

import { scrollToError } from '@/utils/scrollToError';

import 'dayjs/locale/ru';

import { formValidate } from './utils/formValidate';
import { GetFieldsView } from './utils/GetFieldsView';
import { getInitialValues } from './utils/getInitialValues';
import { getTransformedFormValues } from './utils/getTransformedFormValues';

interface IModelFormBuilder {
  fields: TFormField[];
  onSubmit: (values: Record<string, unknown>) => void;
  validation?: Record<string, TValidationField>;
  type?: 'new' | 'edit';
  loading?: boolean;
}

const ModelFormBuilder: FC<IModelFormBuilder> = ({
  fields,
  onSubmit,
  validation,
  type = 'new',
  loading = false,
}) => {
  const form = useForm({
    initialValues: {
      fields: getInitialValues(fields),
    },
    validate: {
      fields: {
        value: (value, array, path) => formValidate(value, array, path, validation),
      },
    },
  });

  const fieldsView = GetFieldsView(form, validation, type);

  const submitHandler = (values: TFormField[]) => {
    onSubmit(getTransformedFormValues(values));
  };

  return (
    <Flex direction="column" maw="45rem" m="auto">
      <form
        onSubmit={form.onSubmit((values) => submitHandler([...values.fields]), scrollToError)}
        noValidate
      >
        {fieldsView}

        <Group justify="right">
          <Button type={'submit'} loading={loading}>
            Сохранить
          </Button>
        </Group>
      </form>
    </Flex>
  );
};

export default React.memo(ModelFormBuilder);
